import React, { useState } from "react";
import {
    useCreateUserWithEmailAndPassword,
    useSignInWithGoogle,
    useUpdateProfile,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import auth from "../firebase.init";
import Loading from "../components/Shared/Loading";
import { Helmet } from "react-helmet";

const RegisterAsBuyer = () => {
    const [signInWithGoogle, gUser, gLoading, gError] = useSignInWithGoogle(auth);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [createUserWithEmailAndPassword, user, loading, error] = useCreateUserWithEmailAndPassword(auth);
    const [updateProfile, updating, updateError] = useUpdateProfile(auth);
    const navigate = useNavigate();

    let signInError;

    // Function to map Firebase error codes to custom messages
    const getErrorMessage = (error) => {
        if (!error) return "";
        switch (error.code) {
            case "auth/email-already-in-use":
                return "This email is already registered. Please use a different email.";
            case "auth/invalid-email":
                return "Please enter a valid email address.";
            case "auth/weak-password":
                return "Your password is too weak. Please enter a stronger password.";
            case "auth/user-disabled":
                return "This user has been disabled. Please contact support.";
            case "auth/user-not-found":
                return "No user found with this email.";
            case "auth/wrong-password":
                return "Incorrect password. Please try again.";
            default:
                return "An unexpected error occurred. Please try again later.";
        }
    };

    const onSubmit = async (data) => {
        try {
            // Create user with email and password
            const userCredential = await createUserWithEmailAndPassword(data.email, data.password);

            if (userCredential) {
                // Update user profile with display name
                await updateProfile({ displayName: data.name });

                // Send user data to backend
                const response = await fetch('https://server.enjoywiki.com/flip-server/add-user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userName: data.name,
                        UserEmail: data.email,
                        userRole: data.userRole,
                        currentBalance: 0
                    }),
                });
                if (response.ok) {
                    console.log('User data saved successfully');
                    navigate('/update-profile');
                    window.location.reload();
                } else {
                    console.error('Failed to save user data to the database');
                }
            }
        } catch (error) {
            console.error('Error during registration:', error.message);
        }
    };

    if (loading || gLoading || updating) {
        return <Loading />;
    }
    if (error || gError || updateError) {
        signInError = (
            <p className="text-danger text-center">
                {getErrorMessage(error || gError || updateError)}
            </p>
        );
    }

    return (
        <>
            <Helmet>
                <title>Register as a Buyer on FlipBundle - Access Premium Digital Products</title>
                <meta name="description" content="Sign up today as a buyer on FlipBundle and get access to top-quality digital products like Canva templates, social media designs, WordPress themes, and more." />
            </Helmet>


            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(https://server.enjoywiki.com/image-server/uploads/breadcrumb_image_2024_05_17_11_50_01_3653-1732595195179-243691209.webp)"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div
                                className="position-relative z-2 aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                                data-aos-easing="linear"
                            >
                                <h2 className="section-title-light mb-2">Sign In</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Sign In
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-110 bg-offWhite">
                <div className="container">
                    <div className="mb-5">
                        <div className="row justify-content-center">
                            <div class="col-auto">
                                <div class="d-flex align-items-center gap-3">
                                    <Link class="w-form-btn" to="/register-buyer">Buyer</Link>
                                    <Link class="w-form-btn-outline" to="/register-seller">Seller</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bg-white rounded-3 p-3">
                        <div className="row g-4">
                            <div className="col-lg-6 p-3 p-lg-5">
                                <div className="mb-40">
                                    <h2 className="section-title mb-2">Sign up as a buyer</h2>
                                    <p className="section-desc">Welcome to Flip Bundle</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-container">
                                        <div className="row gy-3">
                                            <div className="form-input col-lg-12">
                                                <label htmlFor="fname" className="form-label">
                                                    Full Name
                                                    <span className="text-lime-300">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    className="form-control shadow-none"
                                                    {...register("name", {
                                                        required: {
                                                            value: true,
                                                            message: "Name is Required",
                                                        },
                                                    })}
                                                />
                                                {errors.name && (
                                                    <div className="text-danger">{errors.name.message}</div>
                                                )}


                                            </div>

                                            <div className="form-input col-lg-12">
                                                <label htmlFor="phone" className="form-label">
                                                    Email <span className="text-lime-300">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="email"
                                                    placeholder="demo@email.com"
                                                    className="form-control shadow-none"
                                                    {...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: "Email is Required",
                                                        },
                                                        pattern: {
                                                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                                            message: "Provide a valid Email",
                                                        },
                                                    })}

                                                />
                                                {errors.email && (
                                                    <div className="text-danger">{errors.email.message}</div>
                                                )}

                                            </div>


                                            <div className="form-input col-lg-12">
                                                <label htmlFor="password" className="form-label">
                                                    Password <span className="text-lime-300">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    placeholder="********"
                                                    className="form-control shadow-none"
                                                    {...register("password", {
                                                        required: {
                                                            value: true,
                                                            message: "Password is Required",
                                                        },
                                                        minLength: {
                                                            value: 6,
                                                            message: "Must be 6 characters or longer",
                                                        },
                                                    })}
                                                />
                                                {errors.password && (
                                                    <div className="text-danger">{errors.password.message}</div>
                                                )}

                                            </div>
                                            <input
                                                type='hidden'
                                                value='Buyer'
                                                {...register("userRole")}
                                            />

                                        </div>
                                        {signInError && <div className="text-danger">{signInError}</div>}
                                        <div className="d-grid mt-4">
                                            <button className="w-btn-secondary-lg">Create Account</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="mt-3">
                                    <p className="text-center form-text">
                                        Already have an account ? <Link to="/login">Login</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="login-img">
                                    <img
                                        src="/assets/img/others/login-image.png"
                                        className="img-fluid w-100"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default RegisterAsBuyer;
