import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

const SocialShare = ({ url, title, description }) => {
  // Default values in case props are not provided
  const shareUrl = url || window.location.href;
  const shareTitle = title || 'Check out this amazing blog post!';
  const shareDescription = description || 'I found this interesting article I wanted to share with you.';

  return (
    <div className="blog-tags-wrapper mt-5">
      <div className="row g-3 justify-content-between">
        <div className="col-auto">
          <div className="d-flex align-items-center gap-4">
            <h4 className="text-dark-300 fw-semibold text-18">Share:</h4>
            <div className="d-flex align-items-center gap-3 social-links">
              <FacebookShareButton 
                url={shareUrl}
                quote={shareTitle}
                className="social-share-button"
              >
                <svg
                  width={22}
                  height={22}
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="social-icon"
                >
                  <path
                    d="M22 11C22 4.92486 17.0751 0 11 0C4.92486 0 0 4.92486 0 11C0 16.4903 4.02252 21.0412 9.28125 21.8664V14.1797H6.48828V11H9.28125V8.57656C9.28125 5.81969 10.9235 4.29688 13.4361 4.29688C14.6392 4.29688 15.8984 4.51172 15.8984 4.51172V7.21875H14.5114C13.145 7.21875 12.7188 8.06674 12.7188 8.9375V11H15.7695L15.2818 14.1797H12.7188V21.8664C17.9775 21.0412 22 16.4903 22 11Z"
                    fill="currentColor"
                  />
                </svg>
              </FacebookShareButton>

              <TwitterShareButton
                url={shareUrl}
                title={shareTitle}
                className="social-share-button"
              >
                <svg
                  width={22}
                  height={22}
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="social-icon"
                >
                  <path
                    d="M11 0C4.92508 0 0 4.92508 0 11C0 17.0749 4.92508 22 11 22C17.0749 22 22 17.0749 22 11C22 4.92508 17.0749 0 11 0Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.2038 10.0049L16.9128 4.53107H15.7969L11.7081 9.28389L8.44239 4.53107H4.67578L9.61416 11.7182L4.67578 17.4583H5.79172L10.1096 12.4391L13.5584 17.4583H17.325L12.2035 10.0049H12.2038ZM6.1938 5.37114H7.90781L15.7974 16.6564H14.0834L6.1938 5.37114Z"
                    fill="white"
                  />
                </svg>
              </TwitterShareButton>

              <LinkedinShareButton
                url={shareUrl}
                title={shareTitle}
                summary={shareDescription}
                source={window.location.hostname}
                className="social-share-button"
              >
                <svg
                  width={22}
                  height={22}
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="social-icon"
                >
                  <path
                    d="M11 0C4.9258 0 0 4.9258 0 11C0 17.0742 4.9258 22 11 22C17.0742 22 22 17.0742 22 11C22 4.9258 17.0742 0 11 0ZM7.80353 16.6289H5.12453V8.56908H7.80353V16.6289ZM6.46411 7.46851H6.44666C5.54767 7.46851 4.96625 6.84966 4.96625 6.07622C4.96625 5.28532 5.56546 4.68359 6.4819 4.68359C7.39835 4.68359 7.96231 5.28532 7.97977 6.07622C7.97977 6.84966 7.39835 7.46851 6.46411 7.46851ZM17.4634 16.6289H14.7848V12.3171C14.7848 11.2335 14.3969 10.4944 13.4276 10.4944C12.6875 10.4944 12.2468 10.993 12.0531 11.4742C11.9822 11.6464 11.965 11.8871 11.965 12.1279V16.6289H9.28612C9.28612 16.6289 9.3212 9.32523 9.28612 8.56908H11.965V9.71027C12.321 9.16107 12.9579 8.37991 14.3793 8.37991C16.1418 8.37991 17.4634 9.53185 17.4634 12.0074V16.6289Z"
                    fill="currentColor"
                  />
                </svg>
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .social-share-button {
          cursor: pointer;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: background-color 0.3s ease;
        }

        .social-share-button:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        .social-icon {
          color: #666;
          transition: color 0.3s ease;
        }

        .social-share-button:hover .social-icon {
          color: #22BE0D;
        }
      `}</style>
    </div>
  );
};

export default SocialShare;