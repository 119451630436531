import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';

const RecentlyAdded = () => {
    const [products, setProducts] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to check if data is expired (older than 1 hour)
    const isDataExpired = (timestamp) => {
        if (!timestamp) return true;
        const now = new Date().getTime();
        const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
        return now - timestamp > oneHour;
    };

    // Function to store data with timestamp
    const storeData = (key, data) => {
        const dataWithTimestamp = {
            data: data,
            timestamp: new Date().getTime()
        };
        localStorage.setItem(key, JSON.stringify(dataWithTimestamp));
    };

    // Function to get data and check expiration
    const getStoredData = (key) => {
        try {
            const storedItem = localStorage.getItem(key);
            if (!storedItem) return null;

            const { data, timestamp } = JSON.parse(storedItem);
            if (isDataExpired(timestamp)) {
                localStorage.removeItem(key);
                return null;
            }
            return data;
        } catch (error) {
            console.error(`Error reading ${key} from localStorage:`, error);
            localStorage.removeItem(key);
            return null;
        }
    };

    // Helper functions for reviews and ratings
    const getReviewCount = (productId) => {
        return reviews.filter((review) => review.productId === productId).length;
    };

    const getAverageRating = (productId) => {
        const productReviews = reviews.filter((review) => review.productId === productId);
        const totalRating = productReviews.reduce((acc, review) => acc + review.rating, 0);
        return productReviews.length > 0 ? (totalRating / productReviews.length).toFixed(1) : 0;
    };

    const getSeller = (sellerEmail) => {
        return sellers.find(seller => seller.UserEmail === sellerEmail);
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);

            try {
                // First, try to get all cached data
                const cachedProducts = getStoredData('products');
                const cachedSellers = getStoredData('sellers');
                const cachedReviews = getStoredData('reviews');

                let needsFresh = false;
                let products = cachedProducts;
                let sellers = cachedSellers;
                let reviews = cachedReviews;

                // If any cache is missing, fetch all fresh data
                if (!products || !sellers || !reviews) {
                    needsFresh = true;
                }

                if (needsFresh) {
                    // Fetch all data in parallel
                    const [productsRes, sellersRes, reviewsRes] = await Promise.all([
                        fetch('https://server.enjoywiki.com/flip-server/products'),
                        fetch('https://server.enjoywiki.com/flip-server/users'),
                        fetch('https://server.enjoywiki.com/flip-server/product-reviews')
                    ]);

                    const [productsData, sellersData, reviewsData] = await Promise.all([
                        productsRes.json(),
                        sellersRes.json(),
                        reviewsRes.json()
                    ]);

                    products = productsData.reverse();
                    sellers = sellersData;
                    reviews = reviewsData;

                    // Store fresh data
                    storeData('products', products);
                    storeData('sellers', sellers);
                    storeData('reviews', reviews);
                }

                // Update state
                setProducts(products);
                setSellers(sellers);
                setReviews(reviews);

            } catch (error) {
                console.error('Error fetching data:', error);
                // On error, try to use any available cached data
                const cachedProducts = getStoredData('products') || [];
                const cachedSellers = getStoredData('sellers') || [];
                const cachedReviews = getStoredData('reviews') || [];

                setProducts(cachedProducts);
                setSellers(cachedSellers);
                setReviews(cachedReviews);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    return (
        <section className="recently-view pb-110 bg-custom py-110 mb-5">
            <div className="container">
                <div className="row justify-content-between align-items-end mb-40">
                    <div className="col-auto">
                        <h2 className="fw-bold section-title">Recently Added</h2>
                        <p className="section-desc">Get Best Products for Your Work</p>
                    </div>
                    <div className="col-auto mt-3 mt-md-0">
                        <div className="d-flex gap-3">
                            <button className="recentPrev swiper-prev" tabIndex={0} aria-label="Previous slide">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>
                            </button>
                            <button className="recentNext swiper-next" tabIndex={0} aria-label="Next slide">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        spaceBetween={24}
                        slidesPerView={5}
                        navigation={{
                            prevEl: '.recentPrev',
                            nextEl: '.recentNext',
                        }}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        speed={1000}
                        loop={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 24
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 24
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 24
                            },
                            1400: {
                                slidesPerView: 5,
                                spaceBetween: 24
                            }
                        }}
                        className="swiper recentlyAdded"
                    >
                        {products.map((item) => {
                            const seller = getSeller(item.sellerEmail);
                            return (
                                <SwiperSlide key={item.id}>
                                    <article>
                                        <div class="service-card bg-white aos-init" data-aos-easing="linear">
                                            <div className="position-relative">
                                                {/* Show for 1200px+ */}
                                                <img
                                                    src={item.featuredImage}
                                                    className="recently-view-card-img w-100 d-none d-xl-block"
                                                    width={293}
                                                    height={160}
                                                    alt=""
                                                />

                                                {/* Show for <1200px */}
                                                <img
                                                    src={item.featuredImage}
                                                    className="recently-view-card-img w-100 d-block d-xl-none"
                                                    style={{ aspectRatio: '16 / 9', objectFit: 'cover' }}
                                                    alt=""
                                                />
                                            </div>

                                            <div class="service-card-content">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <h3 class="service-card-price fw-bold">${item.price}</h3>
                                                    </div>
                                                    <div class="d-flex align-items-center gap-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                                            <path d="M16 5.95909C15.8855 6.07153 15.7709 6.21207 15.6564 6.32451C14.4537 7.36454 13.2511 8.37646 12.0484 9.38838C11.9339 9.47271 11.9053 9.55704 11.9625 9.69758C12.3348 11.2717 12.707 12.8739 13.0793 14.448C13.1365 14.6448 13.1079 14.8134 12.9361 14.9258C12.7643 15.0383 12.5925 15.0102 12.4207 14.9258C10.989 14.0826 9.58587 13.2393 8.15415 12.396C8.03961 12.3117 7.9537 12.3117 7.83917 12.396C6.43607 13.2393 5.00435 14.0826 3.60126 14.8977C3.48672 14.9821 3.34355 15.0102 3.20038 14.9821C2.9713 14.9258 2.85676 14.701 2.91403 14.448C3.14311 13.5204 3.34355 12.5928 3.57262 11.6652C3.74443 10.9906 3.8876 10.316 4.05941 9.64136C4.08805 9.52893 4.05941 9.47271 3.97351 9.38838C2.74222 8.34835 1.53957 7.30832 0.308291 6.26829C0.251022 6.21207 0.193753 6.18396 0.165118 6.12775C0.0219457 6.01531 -0.0353233 5.87477 0.0219457 5.678C0.0792147 5.50935 0.222387 5.42502 0.394194 5.39691C0.651905 5.36881 0.909615 5.3407 1.19596 5.3407C2.36998 5.22826 3.54399 5.14393 4.74664 5.0315C4.97572 5.00339 5.20479 4.97528 5.43387 4.97528C5.54841 4.97528 5.60567 4.91906 5.63431 4.83474C6.2929 3.31685 6.92286 1.82708 7.58146 0.309198C7.66736 0.140545 7.75326 0.0281089 7.9537 0C8.18278 0.0562179 8.32595 0.140545 8.41186 0.365416C8.75547 1.15247 9.09908 1.96762 9.4427 2.75467C9.75768 3.4574 10.044 4.18823 10.359 4.89095C10.3876 4.97528 10.4449 5.0315 10.5594 5.0315C11.4757 5.11583 12.3921 5.17204 13.337 5.25637C14.0815 5.31259 14.8546 5.39691 15.5991 5.45313C15.7996 5.48124 15.9141 5.59368 16 5.76233C16 5.81855 16 5.90288 16 5.95909Z" fill="currentColor"></path>
                                                        </svg>
                                                        <span class="service-card-rating"> {getAverageRating(item._id)} ({getReviewCount(item._id)} {getReviewCount(item._id) === 1 ? 'Review' : 'Reviews'})</span>

                                                    </div>
                                                </div>
                                                <h3 class="service-card-title fw-semibold">
                                                    <Link to={`/${item.slug}/${item.productId}`}>
                                                        {item.productName}
                                                    </Link>

                                                </h3>
                                                <div class="d-flex align-items-center service-card-author">
                                                    <img
                                                        src={seller?.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                                                        className="service-card-author-img"
                                                        alt=""
                                                    />

                                                    <Link to={`/profile-seller/${seller?._id}`} className="service-card-author-name">
                                                        {seller?.userName}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default RecentlyAdded;

