import React, { useState } from "react";
import Banner from "../components/Banner";
import RecentProducts from "../components/HomePage/RecentProducts";
import Features from "../components/HomePage/Features";
import Testimonials from "../components/HomePage/Testimonials";
import Counter from "../components/HomePage/Counter";
import Contact from "../components/HomePage/Contact";
import HomePageFeatureProducts from "../components/HomePage/HomePageFeatureProducts";
import RecentCategory from "../components/HomePage/RecentCategory";
import RecentAddedProducts from "../components/HomePage/RecentAddedProducts";
import TopSeller from "../components/HomePage/TopSeller";
import CtaSection from "../components/HomePage/CtaSection";
import TestimonialSlide from "../components/HomePage/TestimonialSlide";
import RecentlyAdded from "../components/HomePage/RecentlyAdded";
import { Helmet } from "react-helmet";

const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };


  return (
    <main>
      <Helmet>
        <title>FlipBundle.com - Buy and Sell Bulk Digital Assets Quickly and Easily</title>
        <meta name="description" content="Explore a diverse selection of digital products on FlipBundle, crafted by talented creators. Find quality digital assets, tools, templates, and more to fit your needs." />
      </Helmet>
      <Banner onSearch={handleSearch} />
      <RecentCategory></RecentCategory>
      <RecentlyAdded />
      <RecentProducts searchQuery={searchQuery} />
      <Features />
      <TopSeller />
      <TestimonialSlide />



    </main>
  );
};

export default Home;
