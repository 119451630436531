import React, { useEffect, useState } from 'react';
import LoadingSpinner from './Loading';

const TopCategoriesNav = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [topCategories, setTopCategories] = useState([]);
    const [visibleCategories, setVisibleCategories] = useState(8);

    const STORAGE_KEY = 'topCategories';
    const CACHE_DURATION = 24 * 60 * 60 * 1000;

    const isCacheValid = (timestamp) => {
        const now = new Date().getTime();
        return now - timestamp < CACHE_DURATION;
    };

    const getStoredData = () => {
        const stored = localStorage.getItem(STORAGE_KEY);
        if (!stored) return null;

        const { data, timestamp } = JSON.parse(stored);
        if (!isCacheValid(timestamp)) {
            localStorage.removeItem(STORAGE_KEY);
            return null;
        }
        return data;
    };

    const storeData = (data) => {
        const toStore = {
            data,
            timestamp: new Date().getTime()
        };
        localStorage.setItem(STORAGE_KEY, JSON.stringify(toStore));
    };

    // New function to handle responsive category count
    const handleResize = () => {
        if (window.innerWidth >= 1400) {
            setVisibleCategories(8);
        } else if (window.innerWidth >= 1200) {
            setVisibleCategories(7);
        }
    };

    useEffect(() => {
        // Add resize event listener
        window.addEventListener('resize', handleResize);
        
        // Initial check
        handleResize();

        const fetchData = async () => {
            try {
                setIsLoading(true);
                setError(null);

                // Try to get data from localStorage first
                const storedData = getStoredData();
                if (storedData) {
                    setTopCategories(storedData);
                    setIsLoading(false);
                    return;
                }

                // If no valid cached data, fetch from API
                const productsRes = await fetch('https://server.enjoywiki.com/flip-server/products');

                if (!productsRes.ok) {
                    throw new Error('Failed to fetch products');
                }
                const productsData = await productsRes.json();
                // Create a map of categories with their counts and slugs
                const categoryMap = productsData.reduce((acc, product) => {
                    const categoryName = product.category;
                    const categorySlug = product.categorySlug;

                    if (!acc[categorySlug]) {
                        acc[categorySlug] = {
                            categoryName,
                            categorySlug,
                            productCount: 0
                        };
                    }

                    acc[categorySlug].productCount += 1;
                    return acc;
                }, {});

                // Convert the map to array and sort by product count
                const sortedCategories = Object.values(categoryMap)
                    .sort((a, b) => b.productCount - a.productCount)
                    .slice(0, 8);

                // Store in localStorage
                storeData(sortedCategories);
                setTopCategories(sortedCategories);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (error) {
        return (
            <div className="w-full p-4 text-center text-red-600">
                Error loading categories: {error}
            </div>
        );
    }

    return (
        <>
            <div className="d-none d-xl-block secondary-nav-wrapper">
                <div className="container">
                    <div className="position-relative">
                        <nav className="secondary-nav-container bg-white position-absolute w-100 start-0 z-3 border-top">
                            <ul className="secondary-nav d-flex justify-content-between align-items-center">
                                {isLoading ? (
                                    <LoadingSpinner></LoadingSpinner>
                                ) : (
                                    <>
                                        {topCategories.slice(0, visibleCategories).map((category) => (
                                            <li key={category.categorySlug}>
                                                <a href={`/category/${category.categorySlug}`}>
                                                    {category.categoryName}
                                                </a>
                                            </li>
                                        ))}

                                        <li>
                                            <button
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <svg
                                                    width={38}
                                                    height={38}
                                                    viewBox="0 0 38 38"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle cx={19} cy={19} r={19} fill="#F7F5F0" />
                                                    <path
                                                        d="M17 19C17 20.0178 17.8283 20.8461 18.8462 20.8461C19.864 20.8461 20.6923 20.0178 20.6923 19C20.6923 17.9821 19.864 17.1538 18.8462 17.1538C17.8283 17.1538 17 17.9821 17 19Z"
                                                        fill="#06131C"
                                                    />
                                                    <path
                                                        d="M17 25.1538C17 26.1716 17.8283 26.9999 18.8462 26.9999C19.864 26.9999 20.6923 26.1716 20.6923 25.1538C20.6923 24.1359 19.864 23.3076 18.8462 23.3076C17.8283 23.3076 17 24.1359 17 25.1538Z"
                                                        fill="#06131C"
                                                    />
                                                    <path
                                                        d="M17 12.8462C17 13.864 17.8283 14.6923 18.8462 14.6923C19.864 14.6923 20.6923 13.864 20.6923 12.8462C20.6923 11.8283 19.864 11 18.8462 11C17.8283 11 17 11.8283 17 12.8462Z"
                                                        fill="#06131C"
                                                    />
                                                </svg>
                                            </button>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopCategoriesNav;