import { Twitter, Youtube } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';

const Footer = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [logo, setLogo] = useState({});

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/products`)
      .then((res) => res.json())
      .then((info) => setProducts(info.reverse()));
  }, []);
  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/categories`)
      .then((res) => res.json())
      .then((info) => setCategories(info));
  }, []);

  const getProductCount = (categoryName) => {
    return products.filter((product) => product.category === categoryName).length;
  };

  const uniqueCategories = [...new Set(categories.map(category => JSON.stringify(category)))].map(item => JSON.parse(item));

  // Function to shuffle the array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle categories and get the first 10
  const shuffledCategories = shuffleArray([...uniqueCategories]);

  return (
    <>
      <footer className="footer-area">
        <div className="bg-dark-300">
          <div className="container">
            {/* Newsletter */}
            <div
              className="footer-newsletter pb-60 aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration={1000}
              data-aos-easing="linear"
            >

            </div>
            {/* Footer Widgets */}
            <div className="footer-widgets py-60">
              <div className="row justify-content-between row-gap-4">
                <div
                  className="col-md-6 col-xl-3 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  data-aos-easing="linear"
                >
                  <div className="mb-5">

                    <ul className="footer-info-widget p-0">
                      <li className="d-flex gap-3 align-items-center py-2 footer-info-widget-item">
                        <svg
                          width={17}
                          height={16}
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3497 15.9951C14.0086 15.9654 13.6674 15.9407 13.3263 15.9011C11.1211 15.654 9.07412 14.9472 7.19033 13.7708C5.2126 12.5351 3.61064 10.9237 2.39433 8.94161C1.41041 7.34507 0.762699 5.62496 0.441317 3.77634C0.302876 2.98054 0.233655 2.1798 0.223767 1.36917C0.213878 0.553599 0.75281 0 1.56863 0C2.49816 0 3.42275 0 4.35229 0C5.14338 0 5.67242 0.504171 5.6922 1.29503C5.71692 2.24405 5.85042 3.16837 6.13719 4.07291C6.29541 4.57708 6.19158 5.01205 5.85536 5.4223C5.40543 5.9759 4.99011 6.55422 4.56489 7.12759C4.54017 7.16219 4.53523 7.23633 4.555 7.27587C5.48454 9.05035 6.79973 10.4541 8.55002 11.4378C8.70824 11.5267 8.87141 11.6849 9.02468 11.675C9.17301 11.6651 9.31145 11.4872 9.45484 11.3834C9.95422 11.0077 10.4486 10.6222 10.948 10.2515C11.2595 10.0192 11.6106 9.95984 11.9863 10.0488C12.4659 10.1625 12.9406 10.3157 13.4251 10.3948C13.9097 10.4739 14.4091 10.4986 14.8986 10.5283C15.4375 10.5579 15.8528 10.7655 16.0951 11.2598C16.1495 11.3735 16.1791 11.502 16.2236 11.6256C16.2236 12.7179 16.2236 13.8103 16.2236 14.9076C16.1346 15.1053 16.0704 15.3179 15.9566 15.4958C15.7688 15.7924 15.4573 15.916 15.1309 16C14.8689 15.9951 14.6068 15.9951 14.3497 15.9951Z"
                            fill="currentColor"
                          />
                        </svg>
                        +1 331 688 2030
                      </li>
                      <li className="d-flex gap-3 align-items-center py-2 footer-info-widget-item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={17}
                          height={12}
                          viewBox="0 0 17 12"
                          fill="none"
                        >
                          <path
                            d="M17.0001 10.6994C16.9513 10.846 16.9069 10.9882 16.8626 11.1215C15.1461 9.41089 13.4384 7.71363 11.7263 6.01192C13.4473 4.30132 15.1549 2.60405 16.8714 0.902344C16.9069 1.00898 16.9557 1.1556 17.0001 1.30222C17.0001 4.43461 17.0001 7.56701 17.0001 10.6994Z"
                            fill="currentColor"
                          />
                          <path
                            d="M0.859375 0.125118C1.05897 0.0806868 1.23195 0.0406989 1.4005 0.00959711C1.45373 -0.00373221 1.51139 0.000710897 1.56461 0.000710897C6.18192 0.000710897 10.8037 0.000710897 15.421 0.005154C15.6472 0.005154 15.8734 0.0629144 16.0996 0.0940161C16.104 0.116232 16.104 0.134004 16.1085 0.15622C16.0685 0.196208 16.0286 0.240639 15.9887 0.280627C13.6512 2.63103 11.3182 4.98588 8.98069 7.33628C8.6569 7.66507 8.34198 7.66507 8.0182 7.34072C5.68071 4.98588 3.33879 2.63103 1.00131 0.276184C0.96139 0.236196 0.925907 0.196208 0.859375 0.125118Z"
                            fill="currentColor"
                          />
                          <path
                            d="M10.9856 6.754C12.6977 8.45571 14.4009 10.1485 16.1396 11.8769C15.9356 11.9213 15.7715 11.9613 15.5985 11.9924C15.532 12.0058 15.4654 11.9969 15.3989 11.9969C10.7949 11.9969 6.19534 11.9969 1.59135 11.9969C1.3474 11.9969 1.11232 11.9747 0.872803 11.8502C2.58932 10.1441 4.29254 8.45127 6.00906 6.74512C6.0268 6.75845 6.06672 6.79843 6.11107 6.83842C6.51913 7.24719 6.92276 7.65595 7.33082 8.06472C8.01832 8.7534 8.97194 8.7534 9.65943 8.06472C10.1118 7.62041 10.5598 7.1761 10.9856 6.754Z"
                            fill="currentColor"
                          />
                          <path
                            d="M0.114213 11.1218C0.0787293 10.9307 0.00332659 10.7263 0.00332659 10.522C-0.00110886 7.50954 -0.00110886 4.49711 0.00332659 1.48024C0.00332659 1.27586 0.0742938 1.07147 0.114213 0.875977C1.84404 2.59546 3.55169 4.29717 5.26821 6.00332C3.55612 7.70059 1.84847 9.39786 0.114213 11.1218Z"
                            fill="currentColor"
                          />
                        </svg>
                        support@flipbundle.com
                      </li>
                      <li className="d-flex gap-3 align-items-center py-2 footer-info-widget-item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={23}
                          viewBox="0 0 16 23"
                          fill="none"
                        >
                          <path
                            d="M8.22365 23C8.0718 23 7.91995 23 7.7681 23C7.28825 22.8742 6.94204 22.6047 6.69908 22.1674C4.82222 18.7474 2.92713 15.3333 1.03812 11.9133C0.157396 10.3201 -0.164525 8.63099 0.0784345 6.82812C0.503613 3.7375 2.86032 1.06615 5.91553 0.275521C6.40145 0.14974 6.91167 0.0898438 7.40366 0C7.79847 0 8.19328 0 8.58809 0C8.91608 0.0479167 9.25015 0.0838542 9.57207 0.14974C13.1982 0.8625 15.9194 4.09089 15.9983 7.74453C16.0287 9.27786 15.64 10.6974 14.899 12.0391C13.0525 15.3573 11.212 18.6875 9.38378 22.0177C9.11652 22.5029 8.78245 22.8682 8.22365 23ZM8.00498 3.95312C6.20708 3.95312 4.73111 5.39661 4.72503 7.16953C4.71896 8.96042 6.18279 10.4219 7.99284 10.4219C9.79074 10.4219 11.2667 8.97838 11.2728 7.20547C11.2789 5.41458 9.80896 3.95312 8.00498 3.95312Z"
                            fill="currentColor"
                          />
                        </svg>
                        2231 Glouceston Lane, Naperville, Illinois,
                        <br />United States, 60564
                      </li>
                    </ul>
                  </div>
                  <div>

                    <div className="d-flex gap-3">

                      <Link to="/">
                        <img src="/assets/img/logo/FlipBundle_footer_Logo.png" alt="Logo" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-easing="linear"
                    className="aos-init aos-animate"
                  >
                    <h3 className="footer-widget-title fw-bold mb-4">Categories</h3>
                    <nav>
                      <ul className="footer-nav-list list-unstyled">
                        {shuffledCategories.slice(0, 6).map(category => (

                          <li className="footer-nav-list-item py-1" key={category.categorySlug}>
                            <Link
                              to={`/category/${category.slug}`}
                              className="footer-nav-link d-flex gap-2 align-items-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={13}
                                height={10}
                                viewBox="0 0 13 10"
                                fill="none"
                              >
                                <path
                                  d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              {category.categoryName}
                            </Link>
                          </li>
                        ))}



                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-easing="linear"
                    className="aos-init aos-animate"
                  >
                    <h3 className="footer-widget-title fw-bold mb-4">Useful Link</h3>
                    <nav>
                      <ul className="footer-nav-list list-unstyled">
                        <li className="footer-nav-list-item py-1">
                          <Link
                            to="/"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Home
                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/about"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            About Us
                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/contact"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Contact
                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/products"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            All Products
                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/sell-now"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Sell Now
                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/blog"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Blog
                          </Link>
                        </li>


                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div
                    data-aos="fade-up"
                    data-aos-duration={1000}
                    data-aos-easing="linear"
                    className="aos-init aos-animate"
                  >
                    <h3 className="footer-widget-title fw-bold mb-4">
                      Help &amp; Supports

                    </h3>
                    <nav>
                      <ul className="footer-nav-list list-unstyled">
                        <li className="footer-nav-list-item py-1">
                          <Link
                            to="/privacy"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Privacy &amp; Policy

                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/terms"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Term & Condition
                          </Link>
                        </li>
                        <li className="footer-nav-list-item py-2">
                          <Link
                            to="/refundpolicy"
                            className="footer-nav-link d-flex gap-2 align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={13}
                              height={10}
                              viewBox="0 0 13 10"
                              fill="none"
                            >
                              <path
                                d="M8.57894 9L12.2456 5M12.2456 5L8.57894 0.999999M12.2456 5L1.24561 5"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Refund Policy
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright py-4">
          <div className="container">
            <div className="row row-gap-4 justify-content-between">
              <div className="col-auto">
                <div>
                  <p className="text-white">
                    Copyright &copy; {new Date().getFullYear()} FlipBundle.com. All rights reserved.</p>
                </div>
              </div>
              <div className="col-auto">
                <div className="footer-social d-flex align-items-center gap-4">
                  <a
                    href="https://www.facebook.com/FlipBundleFB"
                    className="footer-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                        fill="currentColor"
                      />
                    </svg>{" "}
                  </a>
                  <a
                    href="https://www.youtube.com/@FlipBundle"
                    className="footer-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Youtube />{" "}
                  </a>
                  <a
                    href="https://x.com/FlipBundle"
                    className="footer-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/flipbundle/"
                    className="footer-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0ZM8.51294 18.1406H5.59039V9.34808H8.51294V18.1406ZM7.05176 8.14746H7.03271C6.052 8.14746 5.41772 7.47235 5.41772 6.6286C5.41772 5.76581 6.07141 5.10938 7.07117 5.10938C8.07092 5.10938 8.68616 5.76581 8.7052 6.6286C8.7052 7.47235 8.07092 8.14746 7.05176 8.14746ZM19.051 18.1406H16.1288V13.4368C16.1288 12.2547 15.7057 11.4485 14.6483 11.4485C13.8409 11.4485 13.3601 11.9923 13.1488 12.5173C13.0715 12.7051 13.0527 12.9677 13.0527 13.2305V18.1406H10.1303C10.1303 18.1406 10.1686 10.173 10.1303 9.34808H13.0527V10.593C13.441 9.9939 14.1359 9.14172 15.6865 9.14172C17.6093 9.14172 19.051 10.3984 19.051 13.099V18.1406Z"
                        fill="currentColor"
                      />
                    </svg>{" "}
                  </a>
                  <a
                    href="https://www.tumblr.com/blog/flipbundle"
                    className="footer-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/mobile-app-d6c0d.appspot.com/o/images%2Ftumblr.png?alt=media&token=43f83c9f-79ff-4ab1-a900-58801db605d3"
                      class="img-fluid rounded-top"
                      width={24}
                      alt=""
                    />
                    {" "}
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  );
};

export default Footer;
