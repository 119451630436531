import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useParams } from "react-router-dom";
import auth from "../../firebase.init";
import { Helmet } from "react-helmet";


const PayNow = () => {
    const { id } = useParams();
    const [order, setOrder] = useState([]);
    const [paypal, setPaypal] = useState([]);
    const [user] = useAuthState(auth);
    const currentDomain = window.location.origin;

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/order/${id}`)
            .then((res) => res.json())
            .then((info) => setOrder(info));
    }, [id]);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/payments`)
            .then((res) => res.json())
            .then((info) => setPaypal(info));
    }, []);

    return (
        <>
            <Helmet>
                <title>Pay Now | FlipBundle - Complete Your Payment to Access Products</title>
                <meta name="description" content="Complete your payment on FlipBundle to unlock your purchased digital products. Secure payment options for a seamless transaction." />
            </Helmet>

            <main>
                {/* Breadcrumb */}
                <section
                    className="w-breadcrumb-area"
                    style={{
                        backgroundImage:
                            "url(https://server.enjoywiki.com/image-server/uploads/breadcrumb_image_2024_05_17_11_50_01_3653-1732595195179-243691209.webp)"
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div
                                    className="position-relative z-2 aos-init"
                                >
                                    <h2 className="section-title-light mb-2">Payment</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb w-breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                Payment
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Breadcrumb End */}
                {/* Services Details Start */}
                <section className="py-110 bg-offWhite">
                    <div className="container">
                        <div className="row justify-content-center  row-gap-4">
                            {/* Left */}
                            <div className="col-xl-10 mt-30 mt-xl-0">
                                <aside className="d-flex flex-column gap-4">
                                    <div>
                                        <div className="package-tab-content bg-white">
                                            <div>
                                                <div className="d-flex mb-2 justify-content-between align-items-center">
                                                    <h4 className="package-name fw-semibold">Item Name:</h4>
                                                    <h3 className="package-price fw-bold">Price: ${order.totalPrice}

                                                    </h3>


                                                </div>

                                                <p className="text-dark-200 fs-6">
                                                    {order.packageName}
                                                </p>
                                                <div className="gap-4 pt-2 pb04">
                                                    <div className="d-flex mb-2 justify-content-between align-items-center">
                                                        <h4 className="package-name fw-semibold">Other Info</h4>
                                                    </div>

                                                    <p className="text-dark-200 fs-6">
                                                        Name: {order.customerName}

                                                    </p>
                                                    <p className="text-dark-200 fs-6">
                                                        Email: {order.customerEmail}
                                                    </p>
                                                    <p className="text-dark-200 fs-6">
                                                        Payment: {order.paymentStatus}
                                                    </p>
                                                </div>
                                                <form
                                                    action="https://www.paypal.com/cgi-bin/webscr"
                                                    method="post"
                                                    target="_top"
                                                    className="text-center"
                                                >
                                                    {paypal.map((e) => (
                                                        <input name="business" hidden value={e.email} />
                                                    ))}
                                                    <input type="hidden" name="item_number" value="1" />
                                                    <input hidden name="amount" value={order.totalPrice} />

                                                    <input type="hidden" name="no_shipping" value="1" />
                                                    <input type="hidden" name="currency_code" value="USD" />
                                                    <input
                                                        type="hidden"
                                                        name="notify_url"
                                                        value="http://sitename/paypal-payment-gateway-integration-in-php/notify.php"
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="cancel_return"
                                                        value={`${currentDomain}/cancelled-payment/${order._id}`}
                                                    // value={`${currentDomain}/received-payment/${order._id}/${order.paymentId}`}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="return"
                                                        value={`${currentDomain}/received-payment/${order._id}/${order.paymentId}`}
                                                    // value={`${currentDomain}/cancelled-payment/${order._id}`}
                                                    />
                                                    <input type="hidden" name="cmd" value="_xclick" />
                                                    <div>
                                                        <img
                                                            src="https://workzone.mamunuiux.com/uploads/website-images/paypal-2024-06-26-09-12-11-9195.png"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <input
                                                        type="submit"
                                                        name="pay_now"
                                                        id="pay_now"
                                                        className="footer-newsletter-btn mt-3"
                                                        value={`Pay Now: $${order.totalPrice} USD`}
                                                    />
                                                    <div className="text-center mt-2">
                                                        <small className="text-muted">
                                                            * 3% fees applied for payment processing
                                                        </small>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>
                                    </div>

                                </aside>
                            </div>

                        </div>
                    </div>
                </section>
                {/* Services Details End */}
            </main>

        </>
    );
};

export default PayNow;