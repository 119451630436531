import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { Star } from "lucide-react";

const RelatedProducts = ({ currentProduct, currentCategory }) => {
  const [products, setProducts] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsRes, sellersRes, reviewsRes] = await Promise.all([
          fetch("https://server.enjoywiki.com/flip-server/products"),
          fetch("https://server.enjoywiki.com/flip-server/users"),
          fetch("https://server.enjoywiki.com/flip-server/product-reviews"),
        ]);

        const [productsData, sellersData, reviewsData] = await Promise.all([
          productsRes.json(),
          sellersRes.json(),
          reviewsRes.json(),
        ]);

        // Filter related products
        const relatedProducts = productsData
          .filter(
            (product) =>
              product.category === currentProduct.category &&
              product._id !== currentProduct._id
          )
          .sort(() => 0.5 - Math.random()) // Randomize the order
          .slice(0, 10); // Limit to 10 products

        setProducts(relatedProducts);
        setSellers(sellersData);
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching related products:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentProduct?.category) {
      fetchData();
    }
  }, [currentProduct]);

  const getReviewCount = (productId) => {
    return reviews.filter((review) => review.productId === productId).length;
  };

  const getAverageRating = (productId) => {
    const productReviews = reviews.filter(
      (review) => review.productId === productId
    );
    const totalRating = productReviews.reduce(
      (acc, review) => acc + review.rating,
      0
    );
    return productReviews.length > 0
      ? (totalRating / productReviews.length).toFixed(1)
      : 0;
  };

  const getSeller = (sellerEmail) => {
    return sellers.find((seller) => seller.UserEmail === sellerEmail);
  };

  if (loading || products.length === 0) return null;

  return (
    <section className="pb-60 mt-60 pt-60 bg-white service-details-content">
      <div className="container">
        <div className="row justify-content-between align-items-end mb-40">
          <div className="col-auto">
            <h2 className="fw-bold section-title">Related Products</h2>
            <p className="section-desc">
              More products in {currentProduct.category}
            </p>
          </div>
          <div className="col-auto mt-3 mt-md-0">
            <div className="d-flex gap-3">
              <button
                className="relatedPrev swiper-prev"
                tabIndex={0}
                aria-label="Previous slide"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5 8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
              <button
                className="relatedNext swiper-next"
                tabIndex={0}
                aria-label="Next slide"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={24}
            slidesPerView={3}
            navigation={{
              prevEl: ".relatedPrev",
              nextEl: ".relatedNext",
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={1000}
            loop={products.length > 3}
            breakpoints={{
              320: { slidesPerView: 1 },
              576: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              992: { slidesPerView: 3 },
              1200: { slidesPerView: 3 },
            }}
            className="swiper relatedProducts"
          >
            {products.map((item) => {
              const seller = getSeller(item.sellerEmail);
              return (
                <SwiperSlide key={item._id}>
                  <article>
                    <div className="service-card bg-white">
                      <div className="position-relative">
                        <img
                          src={item.featuredImage}
                          style={{ aspectRatio: "16 / 9", objectFit: "cover" }}
                          className="recently-view-card-img w-100"
                          alt={item.productName}
                        />
                      </div>
                      <div className="service-card-content">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="service-card-price fw-bold">
                              ${item.price}
                            </h3>
                          </div>
                          <div className="flex items-center gap-1">
                            <Star className="w-4 h-4" />
                            <span className="service-card-rating">
                              {getAverageRating(item._id)} (
                              {getReviewCount(item._id)}{" "}
                              {getReviewCount(item._id) === 1
                                ? "Review"
                                : "Reviews"}
                              )
                            </span>
                          </div>
                        </div>
                        <h3 className="service-card-title fw-semibold">
                          <Link to={`/${item.slug}/${item.productId}`}>
                            {item.productName}
                          </Link>
                        </h3>
                        <div className="flex items-center service-card-author">
                          <img
                            src={
                              seller?.profileURL ||
                              "/assets/img/others/demo.jpg"
                            }
                            className="service-card-author-img"
                            alt={seller?.userName}
                          />
                          <Link
                            to={`/profile-seller/${seller?._id}`}
                            className="service-card-author-name"
                          >
                            {seller?.userName}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default RelatedProducts;
