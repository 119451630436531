import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import auth from '../../firebase.init';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import ProductContentDisplay from './ProductContentDisplay';
import { MapPin, MoveRight, ShoppingCart, Star, StarHalf, Tag } from 'lucide-react';
import StarRating from '../Shared/StarRating';
import PaginationGlobal from '../Shared/PaginationGlobal';
import RelatedProducts from './RelatedProducts';
import NavigationHandler from '../../hooks/NavigationHandler';

const Product = () => {
    const { productId } = useParams();
    const [products, setProducts] = useState({});
    const [seller, setSeller] = useState({});
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [soldCount, setSoldCount] = useState(0);
    const [user] = useAuthState(auth);
    const [buyer, setBuyer] = useState([]);
    const [userOrders, setUserOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [reviewText, setReviewText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        fetchProductData();
        if (user?.email) {
            fetchUserData();
            fetchUserOrders();
        }
    }, [productId, user]);

    const fetchProductData = async () => {
        try {
            const res = await fetch(`https://server.enjoywiki.com/flip-server/product/${productId}`);
            const info = await res.json();
            setProducts(info);
            fetchSeller(info.sellerEmail);
            fetchReviews(info._id);
            fetchOrderCount(info._id);
        } catch (error) {
            console.error('Error fetching product:', error);
            setIsLoading(false);
        }
    };
    const fetchSeller = async (sellerEmail) => {
        try {
            const res = await fetch(`https://server.enjoywiki.com/flip-server/users`);
            const users = await res.json();
            const sellerInfo = users.find(user => user.UserEmail === sellerEmail && user.userRole === 'Seller');
            if (sellerInfo) {
                setSeller(sellerInfo);
            }
        } catch (error) {
            console.error('Error fetching seller:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const fetchReviews = async (productId) => {
        try {
            const res = await fetch(`https://server.enjoywiki.com/flip-server/product-reviews?productId=${productId}`);
            const info = await res.json();
            const filteredReviews = info.filter(review => review.productId === productId);
            setReviews(filteredReviews.reverse());
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };
    const fetchUserData = async () => {
        try {
            const res = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`);
            const info = await res.json();
            if (info && info.length > 0) {
                setBuyer(info[0]);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const fetchUserOrders = async () => {
        if (!user?.email) {
            setUserOrders([]);
            return;
        }

        try {
            const res = await fetch(`https://server.enjoywiki.com/flip-server/orders?customerEmail=${user.email}`);
            const info = await res.json();
            setUserOrders(info);
        } catch (error) {
            console.error('Error fetching user orders:', error);
            setUserOrders([]);
        }
    };

    useEffect(() => {
        if (user?.email) {
            fetchUserOrders();
        } else {
            setUserOrders([]);
        }
    }, [user]);

    const fetchOrderCount = async (productId) => {
        try {
            const res = await fetch(`https://server.enjoywiki.com/flip-server/orders`);
            const orders = await res.json();
            const soldProducts = orders.filter(order =>
                order.packageId === productId && order.paymentStatus === 'Paid'
            );
            setSoldCount(soldProducts.length);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };
    const hasPurchased = () => {
        return userOrders.some(order => order.packageId === products._id);
    };
    const getAverageRating = () => {
        if (reviews.length === 0) return 0;
        const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
        return (totalRating / reviews.length).toFixed(1);
    };
    const getRatingDistribution = () => {
        const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
        reviews.forEach(review => {
            distribution[Math.floor(review.rating)]++;
        });
        return distribution;
    };
    const prepareGalleryItems = () => {
        const items = [];
        if (products.featuredImage) {
            items.push({
                original: products.featuredImage,
                thumbnail: products.featuredImage,
                originalAlt: "Product Featured Image",
                thumbnailAlt: "Product Featured Image"
            });
        }
        if (products.productImageOne) {
            items.push({
                original: products.productImageOne,
                thumbnail: products.productImageOne,
                originalAlt: "Product Image 1",
                thumbnailAlt: "Product Thumbnail 1"
            });
        }
        if (products.productImageTwo) {
            items.push({
                original: products.productImageTwo,
                thumbnail: products.productImageTwo,
                originalAlt: "Product Image 2",
                thumbnailAlt: "Product Thumbnail 2"
            });
        }
        if (products.productImageThree) {
            items.push({
                original: products.productImageThree,
                thumbnail: products.productImageThree,
                originalAlt: "Product Image 3",
                thumbnailAlt: "Product Thumbnail 3"
            });
        }
        return items;
    };
    // Pagination calculations
    const totalPages = Math.ceil(reviews.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = reviews.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // const handleBuyNowClick = (e) => {
    //     e.preventDefault();
    //     const productId = products._id;

    //     // Store the product ID for after profile update
    //     localStorage.setItem('pendingProductPurchase', productId);

    //     // Check if profile needs update
    //     const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');

    //     if (!user) {
    //         navigate('/register-buyer');
    //     } else if (user.email) {
    //         // If profile is complete, navigate directly to buy page
    //         navigate(`/buy/${productId}`);
    //     }
    // };
    const handleBuyNowClick = (e) => {
        e.preventDefault();
        const productId = products._id;
        if (!user) {
            localStorage.setItem('pendingProductPurchase', productId);
            navigate('/register-buyer');
        } else if (user.email) {
            navigate(`/buy/${productId}`);
        }
    };
    return (
        <>
            <Helmet>
                <title>{`${products.productName} | FlipBundle.com`}</title>
                {/* Primary Meta Tags */}
                <meta name="title" content={`${products.productName} - Premium Digital Products | FlipBundle.com`} />
                <meta name="description" content={`Buy ${products.productName} on FlipBundle. ${products.shortDescription || 'Discover premium digital products'} at competitive prices. Instant delivery and lifetime access guaranteed.`} />
                <meta name="keywords" content={`${products.productName}, ${products.category}, digital products, online marketplace, FlipBundle, ${products.tags || ''}`} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="FlipBundle" />

                {/* Canonical URL */}
                <link rel="canonical" href={`https://flipbundle.com/${products.slug}/${productId}`} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="product" />
                <meta property="og:site_name" content="FlipBundle.com" />
                <meta property="og:url" content={`https://flipbundle.com/${products.slug}/${productId}`} />
                <meta property="og:title" content={`${products.productName} - FlipBundle Digital Marketplace`} />
                <meta property="og:description" content={`${products.shortDescription || 'Discover premium digital products'} on FlipBundle. Instant delivery and lifetime access.`} />
                {/* Use the direct image URL from your server */}
                <meta property="og:image" content={products.featuredImage} />
                <meta property="og:image:secure_url" content={products.featuredImage} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:alt" content={products.productName} />
                <meta property="product:price:amount" content={products.price} />
                <meta property="product:price:currency" content="USD" />
                <meta property="og:availability" content="instock" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@flipbundle" />
                <meta name="twitter:creator" content="@flipbundle" />
                <meta name="twitter:url" content={`https://flipbundle.com/${products.slug}/${productId}`} />
                <meta name="twitter:title" content={`${products.productName} - FlipBundle Digital Marketplace`} />
                <meta name="twitter:description" content={`${products.shortDescription || 'Discover premium digital products'} on FlipBundle. Instant delivery and lifetime access.`} />
                {/* Use the direct image URL from your server */}
                <meta name="twitter:image" content={products.featuredImage} />
            </Helmet>
            <NavigationHandler />
            <section className="w-breadcrumb-area" style={{ position: 'relative', overflow: 'hidden' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `url(${products.featuredImage}) no-repeat center center/cover`,
                        filter: 'blur(5px)',
                        zIndex: -1,
                    }}
                >
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="position-relative z-2">

                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {products.productName}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                {/* Services Details Start */}
                <section className="py-110 bg-offWhite">
                    <div className="container">
                        <div className="row">
                            {/* Left */}
                            <div className="col-xl-9">
                                {/* Slider */}
                                <div className="bg-white service-details-content">
                                    {isLoading ? (
                                        <SkeletonTheme baseColor="#f5f5f5" highlightColor="#ffffff">
                                            <Skeleton height={400} />
                                        </SkeletonTheme>
                                    ) : (
                                        <ReactImageGallery
                                            items={prepareGalleryItems()}
                                            showPlayButton={false}
                                            showFullscreenButton={false}
                                            showNav={true}
                                            thumbnailPosition="bottom"
                                            showBullets={false}
                                            autoPlay={false}
                                            slideInterval={2000}
                                            slideOnThumbnailOver={true}
                                            additionalClass="product-gallery"
                                        />

                                    )}
                                    <div className="mt-40">
                                        <h1 className="service-details-title fw-bold mb-4">
                                            {products.productName ? (
                                                products.productName
                                            ) : (
                                                <SkeletonTheme baseColor="#22be0d;" highlightColor="#22be0d">
                                                    <Skeleton />
                                                </SkeletonTheme>
                                            )}
                                        </h1>

                                        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2">
                                            <span className="service-card-rating">
                                                <p className="fw-semibold text-dark-300 text-18 text-dark-200 mb-0">
                                                    <a
                                                        href="#reviews"
                                                        className="text-decoration-none text-dark-300"
                                                        style={{
                                                            transition: "color 0.3s ease",
                                                        }}
                                                        onMouseOver={(e) => (e.currentTarget.style.color = "#22be0d")}
                                                        onMouseOut={(e) => (e.currentTarget.style.color = "#374151")}
                                                    >
                                                        <Star /> {getAverageRating()} ({reviews.length} {reviews.length === 1 ? "Review" : "Reviews"})
                                                    </a>
                                                </p>


                                            </span>
                                            <span className="d-none d-md-inline mx-md-2">|</span>
                                            <span className="service-card-rating">
                                                <p className="fw-semibold text-dark-300 text-18 text-dark-200 mb-0">
                                                    <Tag /> Category: {products.category}
                                                </p>
                                            </span>
                                        </div>

                                        <ul className="d-flex flex-column flex-md-row mt-4 me-2 gap-3">
                                            <li className="fs-6 d-flex align-items-center text-dark-200">
                                                <i className="fas fa-check-circle text-success"></i>
                                                <p className="mb-0 mx-2">One-time payment</p>
                                            </li>
                                            <li className="fs-6 d-flex align-items-center text-dark-200">
                                                <i className="fas fa-check-circle text-success"></i>
                                                <p className="mb-0 mx-2">Lifetime access</p>
                                            </li>
                                            <li className="fs-6 d-flex align-items-center text-dark-200">
                                                <i className="fas fa-check-circle text-success"></i>
                                                <p className="mb-0 mx-2">Instant delivery</p>
                                            </li>
                                        </ul>
                                        <div className="mt-4 text-center show-up-to-1199">

                                            <Link to={`/buy/${products._id}`} className="w-btn-secondary-xl" onClick={handleBuyNowClick}>
                                                ${products.price} USD - Buy Now
                                                <MoveRight />
                                            </Link>
                                        </div>



                                        <ProductContentDisplay
                                            productDescription={products.productDescription}
                                        />
                                    </div>
                                </div>
                                <RelatedProducts
                                    currentProduct={products}
                                    currentCategory={products.category}
                                />
                                {/* Review */}
                                <div id='reviews' className="pt-80">
                                    <h3 className="service-details-title text-dark-200 fw-bold mb-30">
                                        Reviews
                                    </h3>
                                    <div className="d-flex flex-column flex-md-row gap-4 mb-4">
                                        <div className="bg-white service-review-count p-4 rounded-3 d-flex flex-column justify-content-center align-items-center">
                                            <h4 className="service-details-subtitle fw-bold mb-1">{getAverageRating()} Rating</h4>
                                            <p className="fw-semibold text-dark-300 text-18 text-dark-200">
                                                {reviews.length} Reviews
                                            </p>
                                        </div>
                                        <div className="flex-grow-1">
                                            {[5, 4, 3, 2, 1].map(rating => {
                                                const count = getRatingDistribution()[rating] || 0;
                                                const percentage = (count / reviews.length) * 100 || 0;
                                                return (
                                                    <div key={rating} className="d-flex gap-4 align-items-center mb-2">
                                                        <div className="flex-shrink-0">
                                                            <span className="fs-6 text-dark-300">{rating} Star</span>
                                                        </div>
                                                        <div className="position-relative review-progress-wrapper">
                                                            <div
                                                                className="review-progress-bar"
                                                                style={{ width: `${percentage}%` }}
                                                            />
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <span className="fs-6 text-dark-200">({count})</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-4">
                                        {/* Buyer Review */}
                                        {currentItems
                                            .map((review, index) => (
                                                <div key={index} className="review-card bg-white">
                                                    <div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <StarRating rating={review.rating} />
                                                            <span className="text-dark-200 fs-6 ms-2">
                                                                {review.date}
                                                            </span>
                                                        </div>
                                                        <p className="text-dark-200 fs-6">{review.review}</p>
                                                        <div className="d-flex align-items-center buyer-info justify-content-between mt-4">
                                                            <div className="d-flex align-items-center gap-3">
                                                                <div>
                                                                    <img
                                                                        src={review.reviewerProfileURL || "/assets/img/others/demo.jpg"}
                                                                        className="rounded-circle w-64"
                                                                        alt=""
                                                                        width={64}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h4 className="text-18 text-dark-300 fw-semibold">
                                                                        {review.reviewerName}
                                                                    </h4>
                                                                    <p className="text-dark-200 fs-6">{review.country}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="pt-60">
                                    {/* Pagination */}
                                    {reviews.length > itemsPerPage && (
                                        <PaginationGlobal
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* Right */}
                            <div className="col-xl-3 mt-30 mt-xl-0">
                                <aside className="d-flex flex-column gap-4">
                                    <div>
                                        <nav>
                                            <div
                                                className="nav package-tabs d-flex gap-4 justify-content-between align-items-center">
                                                <p className="package-tab-btn active">
                                                    Product Information
                                                </p>
                                            </div>
                                        </nav>
                                        <div className="package-tab-content bg-white">
                                            <div className="tab-content" id="nav-tabContent">
                                                {/* Basic */}
                                                <div
                                                    className="tab-pane fade show active">
                                                    <div>
                                                        <div className="d-flex mb-2 justify-content-between align-items-center">
                                                            <h4 className="package-name fw-semibold">Price</h4>
                                                            <h3 className="package-price fw-bold">${products.price}</h3>
                                                        </div>
                                                        <ul className="py-4">
                                                            <li className="fs-6 d-flex align-items-center gap-3 text-dark-200">
                                                                <ShoppingCart color='#22be0d' />
                                                                Sales - {soldCount}
                                                            </li>
                                                            <li className="fs-6 d-flex align-items-center gap-3 text-dark-200">
                                                                <Star color='#22be0d' />
                                                                Reviews - {reviews.length}
                                                            </li>
                                                            <li className="fs-6 d-flex align-items-center gap-3 text-dark-200">
                                                                <Tag color='#22be0d' />
                                                                Category-<Link className='text-dark-200' to={`/category/${products.categorySlug}`}>
                                                                    {products.category}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                        <div className="mt-3 text-center">
                                                            <Link
                                                                onClick={handleBuyNowClick}
                                                                to={`/buy/${products._id}`}
                                                                className="w-btn-secondary-xl">
                                                                Buy Now
                                                                <MoveRight />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Card */}
                                    <div className="freelancer-sidebar-card p-4 rounded-4 bg-white position-relative">
                                        <div className="job-type-badge position-absolute d-flex flex-column gap-2">
                                            <p className="job-type-badge-tertiary">Seller</p>
                                        </div>
                                        <div className="freelancer-sidebar-card-header border-bottom d-flex flex-column justify-content-center align-items-center py-4">
                                            <img
                                                src={seller.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                                                className="freelancer-avatar rounded-circle mb-4"
                                                alt=""
                                            />
                                            <h3 className="fw-bold freelancer-name text-dark-300 mb-2">
                                                {seller.userName}
                                            </h3>
                                            <p class="text-dark-200 d-flex align-items-center">
                                                <MapPin size={18} className="me-2" />
                                                {seller.country}
                                            </p>
                                        </div>
                                        <div className="d-grid">
                                            <Link to={`/profile-seller/${seller._id}`} className="w-btn-black-lg w-100">
                                                View Profile
                                                <MoveRight />
                                            </Link>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Services Details End */}
            </main>
        </>
    );
};

export default Product;


