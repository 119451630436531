import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import toast from 'react-hot-toast';
import LoadingSpinner from '../../components/Shared/Loading';
import { Helmet } from 'react-helmet';

const EditProduct = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    // State Management
    const [product, setProduct] = useState(null);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [price, setPrice] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [productImageOne, setProductImageOne] = useState('');
    const [productImageTwo, setProductImageTwo] = useState('');
    const [productImageThree, setProductImageThree] = useState('');
    const [accessLink, setAccessLink] = useState('');
    const [guideLine, setGuideLine] = useState('');
    const [productNameError, setProductNameError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [homePageFeature, setHomePageFeature] = useState('');
    const [categoryFeature, setCategoryFeature] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMainCategory, setSelectedMainCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [uniqueMainCategories, setUniqueMainCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');



    // Quill editor configuration
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
        ],
    };
    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/categories')
            .then((res) => res.json())
            .then((info) => {
                const uniqueMainCategories = [
                    ...new Set(info.map((category) => category.mainCategory)),
                ];
                setCategories(info.reverse());
                setUniqueMainCategories(uniqueMainCategories);
            })
            .catch((error) => console.error('Error fetching categories:', error));
    }, []);

    // Fetch product data
    useEffect(() => {
        if (categories.length > 0) {  // Only fetch if categories are loaded
            fetch(`https://server.enjoywiki.com/flip-server/product-info/${id}`)
                .then((res) => res.json())
                .then((data) => {
                    setProduct(data);
                    setProductName(data.productName);
                    setCharCount(data.productName?.length || 0);
                    setProductDescription(data.productDescription);
                    setPrice(data.price.toString());
                    setFeaturedImage(data.featuredImage);
                    setProductImageOne(data.productImageOne);
                    setProductImageTwo(data.productImageTwo);
                    setProductImageThree(data.productImageThree);
                    setAccessLink(data.accessLink);
                    setGuideLine(data.guideLine);
                    setHomePageFeature(data.homePageFeature);
                    setCategoryFeature(data.categoryFeature);
                    setSelectedMainCategory(data.mainCategory);
                    setSelectedCategory(data.category);
                    setTags(data.tags || []);

                    // Filter subcategories based on the main category
                    const filteredSubcategories = categories.filter(
                        (category) => category.mainCategory === data.mainCategory
                    );
                    setSubcategories(filteredSubcategories);
                })
                .catch((error) => {
                    console.error('Error fetching product:', error);
                    toast.error('Failed to fetch product information');
                });
        }
    }, [id, categories]);

    // Add these new tag management functions
    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newTag = e.target.value.trim().toLowerCase();
            if (newTag && !tags.includes(newTag) && tags.length < 5) {
                setTags([...tags, newTag]);
                setTagInput('');
            } else if (tags.length >= 5) {
                toast.error('Maximum 5 tags allowed');
            } else if (tags.includes(newTag)) {
                toast.error('Tag already exists');
            }
        }
    };

    const handleTagRemove = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };


    const handleMainCategoryChange = (e) => {
        const mainCategory = e.target.value;
        setSelectedMainCategory(mainCategory);
        setSelectedCategory(''); // Reset subcategory when main category changes

        // Filter subcategories based on selected main category
        const filteredSubcategories = categories.filter(
            (category) => category.mainCategory === mainCategory
        );
        setSubcategories(filteredSubcategories);
    };

    // Handle product name change
    const handleProductNameChange = (e) => {
        const value = e.target.value;
        setProductName(value);
        setCharCount(value.length);

        if (value.length > 45) {
            setProductNameError('Product name cannot exceed 45 characters');
        } else {
            setProductNameError('');
        }
    };

    // Handle price change
    const handlePriceChange = (e) => {
        const value = e.target.value;
        setPrice(value);

        if (value === '') {
            setPriceError('');
        } else if (value < 1 || value > 500) {
            setPriceError('Price must be between 1 and 500 USD.');
        } else {
            setPriceError('');
        }
    };

    // Handle image upload
    const handleImageUpload = async (event, setImage) => {
        const file = event.target.files[0];
        if (!file) return;

        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
            toast.error('File size exceeds 2 MB. Please upload a smaller image.');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch(
                'https://server.enjoywiki.com/image-server/upload',
                {
                    method: 'POST',
                    body: formData,
                }
            );

            const data = await response.json();
            if (data.file && data.file.url) {
                setImage(data.file.url);
                toast.success('Image uploaded successfully!');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image.');
        }
    };

    // Handle image removal
    const handleImageRemove = (setImage) => {
        setImage('');
        toast.success('Image removed successfully!');
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (productNameError || priceError || isSubmitting) {
            toast.error('Please fix the errors before submitting.');
            return;
        }

        setIsSubmitting(true);

        const updatedProduct = {
            productName,
            productDescription,
            price,
            featuredImage,
            productImageOne,
            productImageTwo,
            productImageThree,
            accessLink,
            guideLine,
            categoryFeature,
            homePageFeature,
            category: selectedCategory,
            mainCategory: selectedMainCategory,
            tags: tags
        };

        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/product/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProduct),
            });

            if (response.ok) {
                toast.success('Product updated successfully');
                navigate('/seller/products');
                window.scrollTo(0, 0);
            } else {
                throw new Error('Failed to update product');
            }
        } catch (error) {
            console.error('Error updating product:', error);
            toast.error('Failed to update product');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!product) {
        return <LoadingSpinner />;
    }

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110">
                <Helmet>
                    <title>Edit Product | FlipBundle - Update Your Digital Product Listing</title>
                    <meta name="description" content="Edit your digital product listing on FlipBundle. Update details, pricing, and product files to keep your store fresh and attract more buyers." />
                </Helmet>

                <div>
                    <h3 className="text-24 fw-bold text-dark-300 mb-2">Edit Product</h3>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="text-dark-200 fs-6">Seller Dashboard</li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </li>
                        <li className="text-lime-300 fs-6">Edit Product</li>
                    </ul>
                </div>

                {/* Content */}
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <form onSubmit={handleSubmit} className="d-flex flex-column gap-4">
                            {/* Basic Info */}
                            <div className="gig-info-card">
                                <div className="gig-info-header">
                                    <h4 className="text-18 fw-semibold text-dark-300">Basic Info</h4>
                                </div>
                                <div className="gig-info-body bg-white">
                                    <div className="row g-4">
                                        {/* Hidden Fields */}
                                        <input
                                            hidden
                                            type="text"
                                            name="categoryFeature"
                                            value={categoryFeature}
                                        />
                                        <input
                                            hidden
                                            type="text"
                                            name="homePageFeature"
                                            value={homePageFeature}
                                        />

                                        {/* Product Name */}
                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">
                                                    Item Name (Eg: 70+ Premium WordPress Themes)
                                                    {productNameError && <span className="text-danger ms-2">({productNameError})</span>}
                                                    <span className="ms-2">Character count: {charCount}/45</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control shadow-none"
                                                    value={productName}
                                                    onChange={handleProductNameChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        {/* Price */}
                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">Price (USD)</label>
                                                <input
                                                    type="number"
                                                    className="form-control shadow-none"
                                                    value={price}
                                                    onChange={handlePriceChange}
                                                    onWheel={(e) => e.target.blur()} // Prevent scrolling by blurring the input
                                                    min="1"
                                                    max="500"
                                                    step="0.01"
                                                    required
                                                />

                                                {priceError && <div className="text-danger">{priceError}</div>}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">Main Category</label>
                                                <select
                                                    className="form-control shadow-none"
                                                    value={selectedMainCategory}
                                                    onChange={handleMainCategoryChange}
                                                    required
                                                >
                                                    <option value="">Select a main category</option>
                                                    {uniqueMainCategories.map((mainCategory, index) => (
                                                        <option key={index} value={mainCategory}>
                                                            {mainCategory}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">Sub Category</label>
                                                <select
                                                    className="form-control shadow-none"
                                                    value={selectedCategory}
                                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                                    required
                                                    disabled={!selectedMainCategory}
                                                >
                                                    <option value="">Select a subcategory</option>
                                                    {subcategories.map((category) => (
                                                        <option key={category._id} value={category.categoryName}>
                                                            {category.categoryName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>





                                        {/* Description */}
                                        <div className="col-12">
                                            <>
                                                <label className="form-label">Product Description</label>
                                                <ReactQuill
                                                    value={productDescription}
                                                    onChange={setProductDescription}
                                                    modules={quillModules}
                                                    className="form-control text-area-sec"
                                                />
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Images Section */}
                            <div className="gig-info-card">
                                <div className="gig-info-header">
                                    <h4 className="text-18 fw-semibold text-dark-300">Upload Images</h4>
                                </div>
                                <div className="gig-info-body bg-white">
                                    {/* Featured Image */}
                                    <div className="mb-4">
                                        <label className="form-label">Featured Image (960x540)</label>
                                        <div className="d-flex align-items-center gap-3">
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => handleImageUpload(e, setFeaturedImage)}
                                                accept="image/*"
                                            />
                                            {featuredImage && (
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => handleImageRemove(setFeaturedImage)}
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>
                                        {featuredImage && (
                                            <img src={featuredImage} alt="Featured" className="mt-2" style={{ height: '100px' }} />
                                        )}
                                    </div>

                                    {/* Additional Images */}
                                    {[
                                        { state: productImageOne, setState: setProductImageOne, label: 'Product Image One' },
                                        { state: productImageTwo, setState: setProductImageTwo, label: 'Product Image Two' },
                                        { state: productImageThree, setState: setProductImageThree, label: 'Product Image Three' },
                                    ].map((image, index) => (
                                        <div key={index} className="mb-4">
                                            <label className="form-label">{image.label}</label>
                                            <div className="d-flex align-items-center gap-3">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={(e) => handleImageUpload(e, image.setState)}
                                                    accept="image/*"
                                                />
                                                {image.state && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleImageRemove(image.setState)}
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>
                                            {image.state && (
                                                <img src={image.state} alt={image.label} className="mt-2" style={{ height: '100px' }} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Access Information */}
                            <div className="gig-info-card">
                                <div className="gig-info-header">
                                    <h4 className="text-18 fw-semibold text-dark-300">Access Information</h4>
                                </div>
                                <div className="gig-info-body bg-white">
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">Product Access Link</label>
                                                <input
                                                    type="text"
                                                    className="form-control shadow-none"
                                                    value={accessLink}
                                                    onChange={(e) => setAccessLink(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">Guide Line (e.g How to access)</label>
                                                <textarea
                                                    className="form-control shadow-none"
                                                    value={guideLine}
                                                    onChange={(e) => setGuideLine(e.target.value)}
                                                    rows="3"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gig-info-card">
                                <div className="gig-info-header">
                                    <h4 className="text-18 fw-semibold text-dark-300">Tags Information</h4>
                                </div>
                                <div className="gig-info-body bg-white">
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <div className="form-container">
                                                <label className="form-label">
                                                    Tags (Add up to 5 tags by pressing Enter)
                                                    <span className="ms-2 text-muted">
                                                        {tags.length}/5 tags used
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter a tag and press Enter"
                                                    value={tagInput}
                                                    onChange={(e) => setTagInput(e.target.value)}
                                                    onKeyDown={handleTagInputKeyDown}
                                                    disabled={tags.length >= 5}
                                                />
                                                <div className="mt-3">
                                                    {tags.map((tag, index) => (
                                                        <span
                                                            key={index}
                                                            className="badge bg-primary me-2 mb-2 p-2"
                                                            style={{ fontSize: '0.9rem' }}
                                                        >
                                                            {tag}
                                                            <button
                                                                type="button"
                                                                className="btn-close btn-close-white ms-2"
                                                                onClick={() => handleTagRemove(tag)}
                                                                style={{ fontSize: '0.6rem' }}
                                                            />
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-btn-secondary-lg"
                                    disabled={isSubmitting || !!productNameError || !!priceError}
                                >
                                    {isSubmitting ? 'Updating...' : 'Update Product'}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={14}
                                        height={10}
                                        viewBox="0 0 14 10"
                                        fill="none"
                                        className="ms-2"
                                    >
                                        <path
                                            d="M9 9L13 5M13 5L9 1M13 5L1 5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EditProduct;