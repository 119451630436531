import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const SupportMessage = () => {
    const [support, setSupport] = useState([]);
    const [user] = useAuthState(auth);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/support-messages?userEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => setSupport(info.reverse()));
    }, [user?.email]);

    // Search and Filter States
    const [searchInput, setSearchInput] = useState("");
    const [statusFilter, setStatusFilter] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const filteredMessages = support.filter((message) => {
        const ticketId = message.ticket_id ? message.ticket_id.toLowerCase() : '';
        const matchesSearchInput = ticketId.includes(searchInput.toLowerCase());
        const matchesStatusFilter = statusFilter === "all" || message.ticketStatus === statusFilter;
        return matchesSearchInput && matchesStatusFilter;
    });

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
        setCurrentPage(1);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
        setCurrentPage(1);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = filteredMessages.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCreate = (event) => {
        event.preventDefault();
        const ticket_id = generateUniqueOrderId();
        const ticketDate = event.target.ticketDate.value;
        const ticketStatus = "pending";
        const ticketUserEmail = event.target.ticketUserEmail.value;
        const subject = event.target.subject.value;
        const message = event.target.message.value;
        const customerType = event.target.customerType.value;

        const add = {
            ticket_id,
            ticketDate,
            ticketStatus,
            ticketUserEmail,
            subject,
            message,
            customerType
        };

        const url = `https://server.enjoywiki.com/flip-server/new-support-message`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(add),
        })
            .then((res) => {
                if (!res.ok) {
                    return res.text().then(errorMessage => {
                        throw new Error(errorMessage);
                    });
                }
                return res.json();
            })
            .then((result) => {
                event.target.reset();
                toast.success("Message sent successfully!")

                // Refresh support messages
                fetch(`https://server.enjoywiki.com/flip-server/support-messages?userEmail=${user?.email}`)
                    .then((res) => res.json())
                    .then((info) => setSupport(info.reverse()));
            })
            .catch((error) => {
                console.error('Error creating message:', error);
                toast.error(error.message);
            });
    };

    const generateUniqueOrderId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let ticket_id = "";
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            ticket_id += characters.charAt(randomIndex);
        }
        return ticket_id;
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const [currentDate] = useState(formatDate(new Date()));

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4">
                <Helmet>
                    <title>Support Message | FlipBundle - Get Help and Assistance</title>
                    <meta name="description" content="Need help? Send us a message and our support team will assist you with any issues or questions regarding your orders or account on FlipBundle." />
                </Helmet>

                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">
                            Support Messages
                        </h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="11" viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">Support Messages</li>
                        </ul>
                    </div>
                </div>

                {/* Content */}
                <div>
                    <div className="mb-4">
                        <button
                            className="btn btn-primary me-3"
                            data-bs-toggle="modal"
                            data-bs-target="#newMessageModal"
                        >
                            New Support Message
                        </button>
                        <div className="d-inline-block">
                            <input
                                type="text"
                                className="form-control d-inline-block w-auto me-3"
                                placeholder="Search by Ticket ID"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                            />
                            <select
                                className="form-select d-inline-block w-auto"
                                value={statusFilter}
                                onChange={handleStatusFilterChange}
                            >
                                <option value="all">All Status</option>
                                <option value="pending">Pending</option>
                                <option value="completed">Completed</option>
                                <option value="closed">Closed</option>
                            </select>
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <div className="w-100">
                            <table className="w-100 dashboard-table">
                                <thead className="pb-3">
                                    <tr>
                                        <th scope="col">Ticket Id</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentService.map((message, index) => (
                                        <tr key={message._id}>
                                            <td className="text-dark-200">
                                                <a href={`/ticket/${message.ticket_id}`}>
                                                    #{message.ticket_id}
                                                </a>
                                            </td>
                                            <td className="text-dark-200">{message.subject}</td>
                                            <td className="text-dark-200">{message.ticketDate}</td>
                                            <td className="text-dark-200">
                                                <span className={`status-badge ${message.ticketStatus}`}>
                                                    {message.ticketStatus}
                                                </span>
                                            </td>
                                            <td className="text-dark-200">
                                                <div className="d-flex justify-content-end gap-2">
                                                    <button
                                                        className="dashboard-action-btn"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#viewModal${message._id}`}
                                                    >
                                                        <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M23.3187 6.66195C24.6716 8.08537 24.6716 10.248 23.3187 11.6714C21.0369 14.0721 17.1181 17.3333 12.6667 17.3333C8.21523 17.3333 4.29641 14.0721 2.01466 11.6714C0.661781 10.248 0.661781 8.08537 2.01466 6.66195C4.29641 4.26122 8.21523 1 12.6667 1C17.1181 1 21.0369 4.26122 23.3187 6.66195Z" stroke="#5B5B5B" strokeWidth="1.5" />
                                                            <circle cx="12.668" cy="9.16699" r="3.5" stroke="#5B5B5B" strokeWidth="1.5" />
                                                        </svg>
                                                    </button>
                                                </div>

                                                {/* View Modal */}
                                                <div className="modal fade" id={`viewModal${message._id}`} tabIndex="-1" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">View Message</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Subject</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={message.subject}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Message</label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        rows="4"
                                                                        value={message.message}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                {message.adminMessage && (
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Admin Response</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            rows="4"
                                                                            value={message.adminMessage}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    ))}
                                    {currentService.length === 0 && (
                                        <tr>
                                            <td colSpan="6" className="text-center py-4">
                                                No Tickets found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {support.length > itemsPerPage && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(support.length / itemsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}


                </div>
            </div>

            {/* New Message Modal */}
            <div className="modal fade" id="newMessageModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">New Support Message</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleCreate}>
                                <input
                                    type="hidden"
                                    name="customerType"
                                    value="buyer"
                                />
                                <input
                                    type="hidden"
                                    name="ticketDate"
                                    value={currentDate}
                                />
                                <input
                                    type="hidden"
                                    name="ticketStatus"
                                    value="pending"
                                />
                                <input
                                    type="hidden"
                                    name="ticketUserEmail"
                                    value={user?.email}
                                />

                                <div className="mb-3">
                                    <label className="form-label">Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subject"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea
                                        className="form-control"
                                        name="message"
                                        rows="4"
                                        required
                                    />
                                </div>
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary">
                                        Submit Message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SupportMessage;