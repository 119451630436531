import React from 'react';
import "./LoadingSpinner.css";

const PulseLoader = () => {
  return (
    <div className="modern-loader-overlay">
    <div className="modern-loader">
      <div className="modern-dot"></div>
      <div className="modern-dot"></div>
      <div className="modern-dot"></div>
      <div className="modern-dot"></div>
    </div>
  </div>
  );
};

export default PulseLoader;
