
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

const Contact = () => {
    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const navigate = useNavigate();

    const notifySuccess = () => {
        toast.success("Message Sent Successfully!");
    };
    const UserContactMessage = (event) => {
        event.preventDefault();
        const name = event.target.name.value;
        const email = event.target.email.value;
        const message = event.target.msg.value;
        const subject = event.target.subject.value;
        const phone = event.target.phone.value;
        const date = currentDate;
        const messageStatus = 'unread';  

        const contact = {
            name,
            email,
            message,
            subject,
            phone,
            date,
            messageStatus,
        };

        const url = `https://server.enjoywiki.com/flip-server/add-contact-message`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(contact),
        })
            .then((res) => res.json())
            .then((result) => {
                event.target.reset();
                notifySuccess();
            })
            .catch(error => {
                toast.error("Failed to send message. Please try again.");
                console.error("Error sending message:", error);
            });
    };

    // Function to get the current date in yyyy-MM-dd format
    function getCurrentDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    return (
        <>
            <Helmet>
                <title>Contact Us | FlipBundle - We're Here to Help</title>
                <meta name="description" content="Get in touch with FlipBundle for any questions, support, or feedback. Our team is here to assist you with anything you need related to our digital product marketplace." />
            </Helmet>
            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(https://server.enjoywiki.com/image-server/uploads/breadcrumb_image_2024_05_17_11_50_01_3653-1732595195179-243691209.webp)"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div
                                className="position-relative z-2 aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                                data-aos-easing="linear"
                            >
                                <h2 className="section-title-light mb-2">Contact Us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Contact Us
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Widgets  */}
            <section className="py-110 bg-offWhite">
                <div className="container">
                    <div className="bg-white contact-widgets rounded-4 p-5">
                        <div className="row g-5 justify-content-between d-flex">
                            <div className="col-xxl col-lg-6">
                                <div className="contact-widget-item d-flex text-center text-md-start flex-column align-content-md-start align-content-center flex-md-row gap-3">
                                    <div>
                                        <img src="assets/img/contact/ic-1.svg" alt="" />
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="contact-widget-title">Phone</h4>
                                        <p className="contact-widget-info">+1 331 688 2030</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl col-lg-6">
                                <div className="contact-widget-item d-flex text-center text-md-start flex-column align-content-md-start align-content-center flex-md-row gap-3">
                                    <div>
                                        <img src="assets/img/contact/ic-2.svg" alt="" />
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h4 className="contact-widget-title">Email</h4>
                                        <p className="contact-widget-info">support@flipbundle.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl col-lg-6">
                                <div className="contact-widget-item d-flex text-center text-md-start flex-column align-content-md-start align-content-center flex-md-row gap-3">
                                    <div>
                                        <img src="assets/img/contact/ic-3.svg" alt="" />
                                    </div>
                                    <div>
                                        <h4 className="contact-widget-title">Location</h4>
                                        <p className="contact-widget-info">
                                        2231 Glouceston Lane, Naperville, Illinois,
                                        United States, 60564
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl col-lg-6">
                                <div className="contact-widget-item ps-0 ps-xl-4 text-center text-md-start text-md-start d-flex flex-column gap-3">
                                    <div>
                                        <h4 className="contact-widget-title">Follow Us:</h4>
                                    </div>
                                    <div className="d-flex justify-content-center justify-content-md-start gap-4 contact-social-links">
                                        <a href="https://www.facebook.com/FlipBundleFB" target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-facebook-f" />
                                        </a>
                                        <a href="https://www.youtube.com/@FlipBundle" target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-youtube" />
                                        </a>
                                        <a href="http://" target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-linkedin-in" />
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Form */}
            <section className="pb-110 bg-offWhite">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="contact-form">
                                <div className="mb-5">
                                    <h3 className="mb-4 contact-form-title">Get in Touch</h3>
                                    <p className="contact-form-desc">
                                        Award-winning, family owned dealership of new and pre-owned
                                        lisline with several locations across the city. Lowest prices
                                        and the best customer service guaranteed.
                                    </p>
                                </div>
                                <form onSubmit={UserContactMessage}>
                                    <div className="row g-4">
                                        <div className="contact-form-input col-lg-6">
                                            <label htmlFor="name" className="form-label">
                                                Name <span className="text-lime-300">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Name"
                                                className="form-control shadow-none"
                                                required
                                            />
                                        </div>
                                        <div className="contact-form-input col-lg-6">
                                            <label htmlFor="phone" className="form-label">
                                                Phone <span className="text-lime-300">*</span>
                                            </label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                placeholder="Phone Number"
                                                className="form-control shadow-none"
                                                required
                                            />
                                        </div>
                                        <div className="contact-form-input col-lg-6">
                                            <label htmlFor="email" className="form-label">
                                                Email <span className="text-lime-300">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Email Here"
                                                className="form-control shadow-none"
                                                required
                                            />
                                        </div>
                                        <div className="contact-form-input col-lg-6">
                                            <label htmlFor="subject" className="form-label">
                                                Subject <span className="text-lime-300">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="subject"
                                                name="subject"
                                                placeholder="Subject"
                                                className="form-control shadow-none"
                                                required
                                            />
                                        </div>
                                        <div className="contact-form-input col-lg-12">
                                            <label htmlFor="msg" className="form-label d-block">
                                                Message <span className="text-lime-300">*</span>
                                            </label>
                                            <textarea
                                                id="msg"
                                                name="msg"
                                                placeholder="Message Here"
                                                className="form-textarea form-control shadow-none"
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button type="submit" className="w-btn-secondary-sm">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-map">
                                <iframe
                                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2231%20Glouceston%20Lane,%20Naperville,%20Illinois%20%20United%20States,%2060564+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                    allowFullScreen=""
                                    width="100%"
                                    height={650}
                                    style={{ borderRadius: 16 }}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;