import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Link } from "react-router-dom";
import Loading from "../../components/Shared/Loading";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";

const SellerProducts = () => {
    const [products, setProducts] = useState([]);
    const [user] = useAuthState(auth);
    const [categories, SetCategories] = useState([]);
    const [orders, setOrders] = useState([]);

    // Fetch products
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/products?sellerEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => setProducts(info.reverse()));
    }, [user?.email]);

    // Fetch categories
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/categories`)
            .then((res) => res.json())
            .then((info) => SetCategories(info.reverse()));
    }, [user]);

    // Fetch orders
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/orders`)
            .then((res) => res.json())
            .then((info) => setOrders(info));
    }, []);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = products.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>Seller Products | FlipBundle - Manage Your Digital Product Listings</title>
                    <meta name="description" content="View and manage your digital product listings on FlipBundle. Easily update, promote, and track your products for increased sales." />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Products List</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Seller Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">Products</li>
                        </ul>
                    </div>
                    <Link
                        to="/seller/add-product"
                        className="d-flex align-items-center gap-2 w-btn-secondary-lg"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Add Product
                    </Link>
                </div>

                {/* Content */}
                <div className="gig-info-card">
                    <div className="gig-info-body ">
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="ps-4">Product Name</th>
                                            <th scope="col">Total Sales</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Category</th>
                                            <th scope="col" className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products === null ? (
                                            <tr>
                                                <td colSpan="5" className="text-center p-4">

                                                    <LoadingAnimations
                                                        type="pulse"    // Options: 'pulse', 'ring', 'dots', 'gradient'
                                                        color="#2563eb" // Any valid color
                                                        size="md"       // Options: 'sm', 'md', 'lg'
                                                    />
                                                </td>
                                            </tr>
                                        ) : currentService.length === 0 ? (
                                            <tr>
                                                <td colSpan="5" className="text-center p-4">
                                                    No products found
                                                </td>
                                            </tr>
                                        ) : (
                                            currentService.map((product) => (
                                                <tr key={product._id}>
                                                    <td>
                                                        <div className="d-flex gap-3 align-items-center project-name">
                                                            <div className="order-img">
                                                                {product.featuredImage && (
                                                                    <img
                                                                        src={product.featuredImage}
                                                                        alt={product.productName}
                                                                        style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div>
                                                                <a
                                                                    href={`/${product.slug}/${product.productId}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="project-link"
                                                                >
                                                                    {product.productName}
                                                                </a>
                                                                <ul className="d-flex gap-1 order-category mt-1">
                                                                    <li className="text-dark-200">{product.category}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-dark-200">
                                                        {orders.filter(order =>
                                                            order.packageId === product._id &&
                                                            order.paymentStatus === 'Paid'
                                                        ).length}
                                                    </td>
                                                    <td className="text-dark-200">
                                                        ${product.price} USD
                                                    </td>
                                                    <td className="text-dark-200">
                                                        <span className="category-badge">
                                                            {product.category}
                                                        </span>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link
                                                            to={`/seller/edit-product/${product._id}`}
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-1">
                                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                            </svg>
                                                            Edit
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Pagination */}
                        {products.length > itemsPerPage && (
                            <div className="pagination-container mt-4 d-flex justify-content-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(products.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SellerProducts;