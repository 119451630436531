import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';

const RecentCategory = () => {
    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/categories`)
            .then((res) => res.json())
            .then((info) => SetCategories(info));
    }, []);

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    return (
        <section className="bg-offWhite py-110">
            <div className="container">
                <div className="row mb-40 justify-content-between align-items-end">
                    <div className="col-md-auto">
                        <h2 className="fw-bold section-title">Featured Categories</h2>
                        <p className="section-desc">Explore 2000+ Premium Digital Products</p>
                    </div>
                    <div className="col-md-auto position-relative mt-30 mt-md-0">
                        <div className="d-flex gap-3">
                            <button
                                className="featurePrev swiper-prev"
                                tabIndex={0}
                                aria-label="Previous slide"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 19.5 8.25 12l7.5-7.5"
                                    />
                                </svg>
                            </button>
                            <button
                                className="featureNext swiper-next"
                                tabIndex={0}
                                aria-label="Next slide"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        spaceBetween={24}
                        slidesPerView={6}
                        navigation={{
                            prevEl: '.featurePrev',
                            nextEl: '.featureNext',
                        }}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        speed={1000}
                        loop={true}
                        className="swiper featureCategory"
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            576: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 24
                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 24
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 24
                            },
                            1400: {
                                slidesPerView: 6,
                                spaceBetween: 24
                            }
                        }}
                    >
                        {categories.map((category, index) => (
                            <SwiperSlide
                                key={category.id}
                                className="swiper-slide"
                            >
                                <div className="feature-category-card bg-white">
                                    <img src={category.image || "https://work-zone.vercel.app/assets/img/categories/3.svg"} width={75} height={75} className="mb-4" alt="" />
                                    <h3 className="feature-category-link fw-semibold">
                                        <Link
                                            to={`/category/${category.slug}`}
                                            title={category.categoryName} // Show full name on hover
                                        >
                                            {truncateText(category.categoryName, 16)}
                                        </Link>
                                    </h3>
                                    <p className="feature-category-desc fs-6">
                                        {truncateText(category.mainCategory, 16)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default RecentCategory;