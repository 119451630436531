import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Helmet } from 'react-helmet';

const BuyerMainDashboardContent = () => {
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [userData, setUserData] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    let from = location.state?.from?.pathname || "/";
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`https://server.enjoywiki.com/flip-server/orders?userEmail=${user.email}`, {
                    method: 'GET',
                });

                if (!response.ok) {
                    if ([401, 403].includes(response.status)) {
                        navigate('/login');
                        return;
                    }
                    throw new Error('Failed to fetch orders');
                }

                const data = await response.json();
                setOrders(data.reverse()); // Reverse the order of data here
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [navigate]);




    const handleAccessClick = (order) => {
        setSelectedOrder(order);
    };

    // Calculate dashboard statistics
    const calculateDashboardStats = () => {
        const totalExpenses = orders
            .filter(order => order.paymentStatus === 'Paid')
            .reduce((sum, order) => sum + parseFloat(order.packagePrice), 0);

        const totalOrders = orders.length;

        const pendingPayments = orders
            .filter(order => order.paymentStatus === 'pending')
            .length;

        return {
            totalExpenses: totalExpenses.toFixed(2),
            totalOrders,
            pendingPayments
        };
    };

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    const matchedUser = info.find(item => item.UserEmail === user.email);
                    if (matchedUser) {
                        setUserData(matchedUser);
                    } else {
                        toast.error("User not found!");
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);

    const getStatusBadgeClass = (status) => {
        status = status.toLowerCase();
        if (status === 'paid' || status === 'completed') return 'status-badge completed';
        if (status === 'pending') return 'status-badge pending';
        return 'status-badge in-progress';
    };

    if (!userData || loading) {
        return <div>Loading...</div>;
    }

    const stats = calculateDashboardStats();

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4">
                <Helmet>
                    <title>Buyer Dashboard | FlipBundle - Manage Your Purchases and Orders</title>
                    <meta name="description" content="Access your buyer dashboard on FlipBundle. View your purchased products, track orders, and manage downloads in one place." />
                </Helmet>

                <div className="d-flex gap-4 flex-column flex-md-row align-items-md-center justify-content-between">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Buyer Dashboard</h3>
                    </div>
                </div>

                {/* Statistics Cards */}
                <div className="row g-4">
                    <div className="col-xl col-md-6">
                        <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                            <div>
                                <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                    ${stats.totalExpenses}
                                </h3>
                                <p className="text-18 text-dark-200">Total Expenses</p>
                            </div>
                            <div className="dashboard-widget-icon">
                                <img src="/assets/img/dashboard/icon/icon-1.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl col-md-6">
                        <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                            <div>
                                <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                    {stats.totalOrders}
                                </h3>
                                <p className="text-18 text-dark-200">Total Orders</p>
                            </div>
                            <div className="dashboard-widget-icon">
                                <img src="/assets/img/dashboard/icon/icon-3.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl col-md-6">
                        <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                            <div>
                                <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                    {stats.pendingPayments}
                                </h3>
                                <p className="text-18 text-dark-200">Pending Payments</p>
                            </div>
                            <div className="dashboard-widget-icon">
                                <img src="/assets/img/dashboard/icon/icon-4.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Latest Orders Table */}
                <div>
                    <h3 className="text-24 fw-bold text-dark-300 mb-2">Latest Orders</h3>
                    <div className="overflow-x-auto">
                        <div className="w-100">
                            <table className="w-100 dashboard-table">
                                <thead className="pb-3">
                                    <tr>
                                        <th scope="col" className="ps-4">Package Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Order Date</th>

                                        <th scope="col" className="text-start">Payment Status</th>
                                        <th scope="col" className="text-start">Access Now</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.slice(0, 10).map((order) => (
                                        <tr key={order._id}>
                                            <td>
                                                <div className="d-flex gap-3 align-items-center project-name">
                                                    <div className="order-img">
                                                        <img
                                                            src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"}
                                                            alt=""
                                                            className="rounded-circle"
                                                            style={{ width: '40px', height: '40px' }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Link to={`/${order.productSlug}/${order.productId}`} className="text-dark-200 project-link">
                                                            {order.packageName}
                                                        </Link>
                                                        <small className="d-block text-muted">
                                                            ID: {order.orderId}
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-dark-200">${order.packagePrice}</td>
                                            <td className="text-dark-200">{order.orderDate}</td>

                                            <td>
                                                <span className={getStatusBadgeClass(order.paymentStatus)}>
                                                    {order.paymentStatus[0].toUpperCase() + order.paymentStatus.slice(1)}
                                                </span>
                                            </td>

                                            <td>
                                                {order.paymentStatus === "Paid" ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#accessModal"
                                                        onClick={() => handleAccessClick(order)}
                                                    >
                                                        Access Now
                                                    </button>
                                                ) : (
                                                    <Link
                                                        to={`/pay-now/${order._id}`}
                                                        className="btn btn-warning btn-sm text-white"
                                                    >
                                                        Pay Now
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    {orders.length === 0 && (
                                        <tr>
                                            <td colSpan="6" className="text-center py-4">
                                                No orders found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bootstrap Modal */}
            <div className="modal fade" id="accessModal" tabIndex="-1" aria-labelledby="accessModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="accessModalLabel">Access Package Content</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {selectedOrder && (
                                <div className="p-3">
                                    <div className="mb-4">
                                        <h5 className="fw-bold">Item Details</h5>
                                        <p className="mb-2"><strong>Item Name:</strong> {selectedOrder.packageName}</p>
                                        <p className="mb-2"><strong>Order ID:</strong> {selectedOrder.orderId}</p>
                                        <p className="mb-2"><strong>Purchase Date:</strong> {selectedOrder.orderDate}</p>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className="fw-bold">Access Information</h5>
                                        <p>Your purchased content is ready to access. Click the button below to view your package content.</p>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <a
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            href={selectedOrder.accessLink.startsWith('http') ? selectedOrder.accessLink : `https://${selectedOrder.accessLink}`}
                                            className="btn btn-primary px-4"
                                        >
                                            Access Now
                                        </a>

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .status-badge {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                }
                
                .status-badge.completed {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }
                
                .status-badge.pending {
                    background-color: #fff3e0;
                    color: #ef6c00;
                }
                
                .status-badge.in-progress {
                    background-color: #e3f2fd;
                    color: #1565c0;
                }
                
                .dashboard-table thead th {
                    background-color: #f8f9fa;
                    padding: 12px;
                    font-weight: 600;
                }
                
                .dashboard-table tbody td {
                    padding: 16px 12px;
                    border-bottom: 1px solid #eee;
                }
                
                .text-dark-200 {
                    color: #6c757d;
                }
                
                .text-dark-300 {
                    color: #343a40;
                }

                .btn-success {
                    background-color: #4CAF50;
                    border-color: #45a049;
                }

                .btn-success:hover {
                    background-color: #45a049;
                    border-color: #3d8b40;
                }

                .btn-warning {
                    background-color: #ff9800;
                    border-color: #fb8c00;
                }

                .btn-warning:hover {
                    background-color: #fb8c00;
                    border-color: #f57c00;
                }
            `}</style>
        </main>
    );
};

export default BuyerMainDashboardContent;