import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const useCountAnimation = (end, duration, shouldStart) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!shouldStart) return;
    let startTime;
    let animationFrame;
    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = Math.min((currentTime - startTime) / (duration * 1000), 1);
      setCount(Math.floor(progress * end));
      if (progress < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };
    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration, shouldStart]);
  
  return count;
};
// Custom hook for intersection observer
const useIntersectionObserver = (ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1 
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isVisible;
};

const Features = () => {
  const sectionRef = useRef(null);
  const isVisible = useIntersectionObserver(sectionRef);
  
  const totalProducts = useCountAnimation(2000, 2, isVisible);
  const totalOrders = useCountAnimation(5000, 2, isVisible);
  const totalSellers = useCountAnimation(800, 2, isVisible);

  return (
    <>
      <section className="cta-area py-110" ref={sectionRef}>
        <div className="container">
          <div className="bg-darkGreen cta-area-bg">
            <div className="row align-items-center">
              <div className="col-12 col-xl-7">
                <div
                  className="cta-content aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  data-aos-easing="linear"
                >
                  <p className="cta-subtitle fw-bold mb-2">Explore New Life</p>
                  <h2 className="section-title-light fw-bold mb-4">
                    Discover Our Marketplace: Empowering You to Buy and Sell Bulk Digital Assets
                  </h2>
                  <p className="section-desc-light mb-40">
                    We are a dynamic digital marketplace offering a wide range of bulk digital assets, from Canva templates to web scripts and graphics. Our platform makes buying and selling digital products quick and easy
                  </p>
                  <Link to="/products" className="cta-btn-link">
                    Browse Product Now
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={10}
                      viewBox="0 0 14 10"
                      fill="none"
                    >
                      <path
                        d="M9 9L13 5M13 5L9 1M13 5L1 5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="cta-counter mt-5">
                  <div className="cta-counter-item">
                    <h3 className="cta-counter-title fw-bold">
                      {totalProducts}
                      <span>+</span>
                    </h3>
                    <p className="cta-counter-desc fw-bold">Total Products</p>
                  </div>
                  <div className="cta-counter-item">
                    <h3 className="cta-counter-title fw-bold">
                      {totalOrders}
                      <span>+</span>
                    </h3>
                    <p className="cta-counter-desc fw-bold">Total Orders</p>
                  </div>
                  <div className="cta-counter-item">
                    <h3 className="cta-counter-title fw-bold">
                      {totalSellers}
                      <span>+</span>
                    </h3>
                    <p className="cta-counter-desc fw-bold">Total Sellers</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-5 mt-5 mt-xl-0">
                <div className="cta-img">
                  <img
                    src="https://work-zone.vercel.app/assets/img/cta/cta-img.png"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;