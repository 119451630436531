import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Pagination from '../Shared/Pagination';
import PaginationGlobal from '../Shared/PaginationGlobal';

const SearchProductsAll = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const query = searchParams.get('query') || '';

    const [searchValue, setSearchValue] = useState(query);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12);

    // Fetch initial data (reviews and sellers)
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const [reviewsRes, sellersRes] = await Promise.all([
                    fetch('https://server.enjoywiki.com/flip-server/product-reviews'),
                    fetch('https://server.enjoywiki.com/flip-server/users')
                ]);
                const [reviewsData, sellersData] = await Promise.all([
                    reviewsRes.json(),
                    sellersRes.json()
                ]);
                setReviews(reviewsData);
                setSellers(sellersData);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };
        fetchInitialData();
    }, []);

    // Function to shuffle array (Fisher-Yates algorithm)
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Search products when query changes
    useEffect(() => {
        const performSearch = async () => {
            if (!query) return;

            setLoading(true);
            try {
                const response = await fetch(`https://server.enjoywiki.com/flip-server/search-products?searchTerm=${encodeURIComponent(query)}`);
                const data = await response.json();
                // Randomize the search results before setting them
                const randomizedResults = shuffleArray(data);
                setSearchResults(randomizedResults);
                setCurrentPage(1);
            } catch (error) {
                console.error('Error searching products:', error);
            } finally {
                setLoading(false);
            }
        };

        performSearch();
    }, [query]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchValue.trim()) {
            setSearchParams({ query: searchValue.trim() });
        }
    };

    // Helper functions
    const getSeller = (sellerEmail) => {
        return sellers.find(seller => seller.UserEmail === sellerEmail);
    };

    const getReviewCount = (productId) => {
        return reviews.filter((review) => review.productId === productId).length;
    };

    const getAverageRating = (productId) => {
        const productReviews = reviews.filter((review) => review.productId === productId);
        const totalRating = productReviews.reduce((acc, review) => acc + review.rating, 0);
        return productReviews.length > 0 ? (totalRating / productReviews.length).toFixed(1) : 0;
    };

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = searchResults.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(searchResults.length / productsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Helmet>
                <title>Search Results - FlipBundle.com</title>
                <meta name="description" content="Search results for digital products on FlipBundle.com" />
            </Helmet>

            {/* Search Banner Section */}
            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(https://server.enjoywiki.com/image-server/uploads/breadcrumb_image_2024_05_17_11_50_01_3653-1732595195179-243691209.webp)"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="position-relative z-2 aos-init">
                                <h2 className="section-title-light mb-4">Search Products</h2>
                                <form onSubmit={handleSearch}>
                                    <div className="hero-form-wrapper bg-white d-flex position-relative" style={{ height: 68 }}>
                                        <div className="flex-grow-1">
                                            <input
                                                type="text"
                                                className="form-control shadow-none"
                                                placeholder="Search for any service..."
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                            <button type="submit" className="hero-form-btn position-absolute">
                                                <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#F2F2F2" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M18 17L14 13" stroke="#F2F2F2" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Results Section */}
            <section className="blog-page-area pd-top-100 pd-bottom-100 min-vh-100 mt-4 mb-5">
                <div className="container">
                    {loading ? (
                        <div className="text-center py-5">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {query && (
                                <h3 className="mb-4">
                                    {searchResults.length} results found for "{query}"
                                </h3>
                            )}

                            <div className="row">
                                {currentProducts.map(e => {
                                    const seller = getSeller(e.sellerEmail);
                                    return (
                                        <div key={e._id} className="col-lg-3 col-md-6 mb-4">
                                            <div className="service-card bg-white">
                                                <div className="position-relative">
                                                    <img
                                                        src={e.featuredImage || "assets/img/services/1.png"}
                                                        className="recently-view-card-img w-100"
                                                        alt={e.productName}
                                                        width={253} height={200}
                                                    />
                                                   
                                                </div>
                                                <div className="service-card-content">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h3 className="service-card-price fw-bold">${e.price}</h3>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={15} viewBox="0 0 16 15" fill="none">
                                                                <path d="M16 5.95909C15.8855 6.07153 15.7709 6.21207 15.6564 6.32451C14.4537 7.36454 13.2511 8.37646 12.0484 9.38838C11.9339 9.47271 11.9053 9.55704 11.9625 9.69758C12.3348 11.2717 12.707 12.8739 13.0793 14.448C13.1365 14.6448 13.1079 14.8134 12.9361 14.9258C12.7643 15.0383 12.5925 15.0102 12.4207 14.9258C10.989 14.0826 9.58587 13.2393 8.15415 12.396C8.03961 12.3117 7.9537 12.3117 7.83917 12.396C6.43607 13.2393 5.00435 14.0826 3.60126 14.8977C3.48672 14.9821 3.34355 15.0102 3.20038 14.9821C2.9713 14.9258 2.85676 14.701 2.91403 14.448C3.14311 13.5204 3.34355 12.5928 3.57262 11.6652C3.74443 10.9906 3.8876 10.316 4.05941 9.64136C4.08805 9.52893 4.05941 9.47271 3.97351 9.38838C2.74222 8.34835 1.53957 7.30832 0.308291 6.26829C0.251022 6.21207 0.193753 6.18396 0.165118 6.12775C0.0219457 6.01531 -0.0353233 5.87477 0.0219457 5.678C0.0792147 5.50935 0.222387 5.42502 0.394194 5.39691C0.651905 5.36881 0.909615 5.3407 1.19596 5.3407C2.36998 5.22826 3.54399 5.14393 4.74664 5.0315C4.97572 5.00339 5.20479 4.97528 5.43387 4.97528C5.54841 4.97528 5.60567 4.91906 5.63431 4.83474C6.2929 3.31685 6.92286 1.82708 7.58146 0.309198C7.66736 0.140545 7.75326 0.0281089 7.9537 0C8.18278 0.0562179 8.32595 0.140545 8.41186 0.365416C8.75547 1.15247 9.09908 1.96762 9.4427 2.75467C9.75768 3.4574 10.044 4.18823 10.359 4.89095C10.3876 4.97528 10.4449 5.0315 10.5594 5.0315C11.4757 5.11583 12.3921 5.17204 13.337 5.25637C14.0815 5.31259 14.8546 5.39691 15.5991 5.45313C15.7996 5.48124 15.9141 5.59368 16 5.76233C16 5.81855 16 5.90288 16 5.95909Z" fill="currentColor" />
                                                            </svg>
                                                            <span className="service-card-rating">
                                                                {getAverageRating(e._id)} ({getReviewCount(e._id)} {getReviewCount(e._id) === 1 ? 'Review' : 'Reviews'})
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <h3 className="service-card-title fw-semibold">
                                                        <Link to={`/${e.slug}/${e.productId}`}>
                                                            {e.productName}
                                                        </Link>
                                                    </h3>
                                                    <div className="d-flex align-items-center service-card-author">
                                                        <img
                                                            src={seller?.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                                                            className="service-card-author-img"
                                                            alt=""
                                                        />
                                                        <Link to={`/profile-seller/${seller?._id}`} className="service-card-author-name">
                                                            {seller?.userName}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/* Pagination */}
                            {searchResults.length > productsPerPage && (
                                <div className="text-center mt-4">
                                    <PaginationGlobal
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={paginate}
                                    />
                                </div>
                            )}

                            {/* No Results Message */}
                            {!loading && query && searchResults.length === 0 && (
                                <div className="text-center py-5">
                                    <h4>No products found matching "{query}"</h4>
                                    <p>Try different keywords or check your spelling</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
        </>
    );
};

export default SearchProductsAll;