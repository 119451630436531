import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { Loader2 } from "lucide-react"; // Import Lucide loading icon

const SellerWithdrawNew = () => {
    const [withdraw, setWithdraw] = useState(null);
    const [sellers, setSellers] = useState([]);
    const [user] = useAuthState(auth);
    const [currentDate] = useState(formatDate(new Date()));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission loading

    // Fetch sellers data
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/users`)
            .then((res) => res.json())
            .then((info) => setSellers(info));
    }, []);

    // Fetch withdraw data
    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/all-withdraw?withdrawalUserEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => setWithdraw(info.reverse()))
                .catch((error) => {
                    console.error("Error fetching withdrawals:", error);
                    toast.error("Failed to fetch withdrawals. Please try again later.");
                });
        }
    }, [user?.email]);

    // Helper Functions
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const generateUniqueOrderId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let withdrawal_id = "";
        for (let i = 0; i < 8; i++) {
            withdrawal_id += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return withdrawal_id;
    };

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const currentService = withdraw?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Form submission
    const handleCreate = async (event) => {
        event.preventDefault();
        const withdrawalAmount = parseFloat(event.target.withdrawalAmount.value);

        if (withdrawalAmount < 50) {
            toast.error("Minimum withdrawal amount is $50");
            return;
        }

        const hasPendingWithdrawal = withdraw?.some((item) => item.withdrawalStatus === "pending");
        if (hasPendingWithdrawal) {
            toast.error("You already have a pending withdrawal request.");
            return;
        }

        const withdrawal_id = generateUniqueOrderId();
        const add = {
            withdrawal_id,
            withdrawalDate: currentDate,
            withdrawalStatus: "pending",
            withdrawalUserEmail: user?.email,
            withdrawalAmount,
            paypalEmail: event.target.paypalEmail.value,
        };

        try {
            setIsSubmitting(true); // Start loading state

            const response = await fetch(`https://server.enjoywiki.com/flip-server/add-withdraw`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(add),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }

            await response.json();
            setIsModalOpen(false);
            event.target.reset();

            // Refresh withdraw list
            window.location.reload();
            toast.success('Withdrawal request submitted successfully');
        } catch (error) {
            console.error("Error creating withdrawal:", error);
            toast.error(error.message || "Failed to submit withdrawal request");
        } finally {
            setIsSubmitting(false); // End loading state
        }
    };

    return (
        <main className="dashboard-main">
            <div className="page-content">
                <div className="container-fluid">
                    <Helmet>
                        <title>Seller Withdraw | FlipBundle - Manage Your Earnings</title>
                        <meta name="description" content="Withdraw your earnings from FlipBundle. Manage and transfer funds easily and securely from your seller account." />
                    </Helmet>

                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Withdraw History</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Seller Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">Withdrawals</li>
                        </ul>
                    </div>

                    {/* New Withdrawal Button */}
                    <div className="action-header m-5">
                        <button
                            className="btn btn-primary d-flex align-items-center gap-2 w-btn-secondary-lg"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            Withdraw Request
                        </button>
                    </div>
                    {/* Withdrawal Modal */}
                    {isModalOpen && (
                        <div className="modal-overlay mb-5 mt-5">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4>Withdraw Request</h4>

                                    <button type="button" class="btn-close text-primary" onClick={() => setIsModalOpen(false)}></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleCreate}>
                                        <input type="hidden" name="withdrawalDate" value={currentDate} />
                                        <input type="hidden" name="withdrawalStatus" value="pending" />
                                        <input type="hidden" name="withdrawalUserEmail" value={user?.email} />

                                        <div className="form-group mb-4">
                                            <label className="form-label">Withdrawal Amount</label>
                                            {sellers.map((seller) =>
                                                seller.UserEmail === user?.email ? (
                                                    <input
                                                        key={seller._id}
                                                        type="number"
                                                        required
                                                        className="form-control"
                                                        name="withdrawalAmount"
                                                        value={Math.floor(seller.currentBalance)}
                                                        readOnly
                                                    />
                                                ) : null
                                            )}
                                        </div>

                                        <div className="form-group mb-4">
                                            <label className="form-label">PayPal Email</label>
                                            <input
                                                type="email"
                                                required
                                                name="paypalEmail"
                                                placeholder="Enter PayPal Email"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="mt-4 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-btn-secondary-lg"
                                                disabled={isSubmitting} // Disable button during submission
                                            >
                                                {isSubmitting ? (
                                                    <>
                                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                        Submitting...
                                                    </>
                                                ) : (
                                                    'Submit Withdrawal'
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Withdrawal Table */}
                    <div className="card">
                        <div className="card-body">
                            <div className="overflow-x-auto">
                                <div className="w-100">
                                    <table className="w-100 dashboard-table">
                                        <thead className="pb-3">
                                            <tr>
                                                <th scope="col" className="ps-4">Withdrawal ID</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Paypal Email</th>
                                                <th scope="col" className="text-start">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {withdraw === null ? (
                                                <tr>
                                                    <td colSpan="5" className="p-4">
                                                        <Skeleton height={50} count={5} />
                                                    </td>
                                                </tr>
                                            ) : currentService?.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center p-4">
                                                        No withdrawal history found
                                                    </td>
                                                </tr>
                                            ) : (
                                                currentService?.map((withdrawal) => (
                                                    <tr key={withdrawal._id}>
                                                        <td>
                                                            <div className="ps-4 fw-semibold">
                                                                {withdrawal.withdrawal_id}
                                                            </div>
                                                        </td>
                                                        <td className="text-dark-200">
                                                            {withdrawal.withdrawalDate}
                                                        </td>
                                                        <td className="text-dark-200">
                                                            ${withdrawal.withdrawalAmount.toFixed(2)} USD
                                                        </td>
                                                        <td className="text-dark-200">
                                                            {withdrawal.paypalEmail}
                                                        </td>
                                                        <td>
                                                            <span className={`status-badge ${withdrawal.withdrawalStatus.toLowerCase()}`}>
                                                                {withdrawal.withdrawalStatus}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Pagination */}
                            {withdraw && withdraw.length > itemsPerPage && (
                                <div className="pagination-container mt-4">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(withdraw.length / itemsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SellerWithdrawNew;