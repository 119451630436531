import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import slugify from 'slugify';
import toast from 'react-hot-toast';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Info, MoveRight } from 'lucide-react';

const AddProductNew = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    // State management
    const [productName, setProductName] = useState('');
    const [productNameError, setProductNameError] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [productSlug, setProductSlug] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [featuredImage, setFeaturedImage] = useState('');
    const [productImageOne, setProductImageOne] = useState('');
    const [productImageTwo, setProductImageTwo] = useState('');
    const [productImageThree, setProductImageThree] = useState('');
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [accessLink, setAccessLink] = useState('');
    const [guideLine, setGuideLine] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMainCategory, setSelectedMainCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [uniqueMainCategories, setUniqueMainCategories] = useState([]);

    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/categories')
            .then((res) => res.json())
            .then((info) => {
                const uniqueMainCategories = [
                    ...new Set(info.map((category) => category.mainCategory)),
                ];
                setCategories(info.reverse());
                setUniqueMainCategories(uniqueMainCategories);
                if (selectedMainCategory) {
                    const filteredSubcategories = info.filter(
                        (category) => category.mainCategory === selectedMainCategory
                    );
                    setSubcategories(filteredSubcategories);
                }
            })
            .catch((error) => console.error('Error fetching categories:', error));
    }, [selectedMainCategory]);

    // Quill editor configuration
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
        ],
    };

    // Handle product name change and slug generation
    const handleProductNameChange = (event) => {
        const value = event.target.value;
        setProductName(value);
        setCharCount(value.length);

        if (value.length > 45) {
            setProductNameError('Product name cannot exceed 45 characters.');
        } else {
            setProductNameError('');
        }

        const generatedSlug = slugify(value, { lower: true, remove: /[*+~.()'"!:@]/g });
        setProductSlug(generatedSlug);
    };

    // Handle price input
    const handlePriceChange = (event) => {
        const value = event.target.value;
        setPrice(value);

        if (value === '') {
            setPriceError('');
        } else if (value < 1 || value > 500) {
            setPriceError('Price must be between 1 and 500 USD.');
        } else {
            setPriceError('');
        }
    };

    // Handle tag management
    const handleTagInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const newTag = event.target.value.trim();
            if (newTag && !tags.includes(newTag) && tags.length < 5) {
                setTags([...tags, newTag]);
                event.target.value = '';
            }
        }
    };

    const handleTagRemove = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    // Handle image upload
    const handleImageUpload = async (event, setImage) => {
        const file = event.target.files[0];
        if (!file) return;

        const maxSize = 2 * 1024 * 1024; // 2 MB
        if (file.size > maxSize) {
            toast.error('File size exceeds 2 MB. Please upload a smaller image.');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://server.enjoywiki.com/image-server/upload', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.file && data.file.url) {
                setImage(data.file.url);
                toast.success('Image uploaded successfully!');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image.');
        }
    };

    // Handle image removal
    const handleImageRemove = (setImage) => {
        setImage('');
        toast.success('Image removed successfully!');
    };

    // Generate unique product ID
    const generateUniqueProductId = async () => {
        let uniqueId;
        while (true) {
            uniqueId = Math.floor(100000 + Math.random() * 900000).toString();
            const response = await fetch(`https://server.enjoywiki.com/flip-server/check-product-id?id=${uniqueId}`);
            const data = await response.json();
            if (!data.exists) {
                break;
            }
        }
        return uniqueId;
    };

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (productNameError || priceError || isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        try {
            const uniqueProductId = await generateUniqueProductId();
            const selectedCategorySlug = event.target.category.value;
            const selectedCategory = categories.find(category => category.slug === selectedCategorySlug);
            const productData = {
                productId: uniqueProductId,
                productStatus: 'Approved',
                sellerEmail: event.target.sellerEmail.value,
                productName,
                slug: productSlug,
                price: parseFloat(price),
                category: selectedCategory?.categoryName,
                mainCategory: selectedCategory?.mainCategory,
                categorySlug: selectedCategorySlug,
                productDescription,
                featuredImage,
                productImageOne,
                productImageTwo,
                productImageThree,
                accessLink,
                guideLine,
                tags,
            };

            const response = await fetch('https://server.enjoywiki.com/flip-server/add-product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productData),
            });

            const result = await response.json();

            if (response.ok) {
                toast.success('Product added successfully!');
                // Reset form
                setProductName('');
                setProductSlug('');
                setProductDescription('');
                setTags([]);
                setFeaturedImage('');
                setProductImageOne('');
                setProductImageTwo('');
                setProductImageThree('');
                setPrice('');
                setAccessLink('');
                setGuideLine('');

                event.target.reset();
            } else {
                throw new Error(result.message || 'Failed to add product');
            }
        } catch (error) {
            console.error('Error adding product:', error);
            toast.error(error.message || 'Failed to add product');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Add this function with other handlers
    const filteredCategories = categories.filter(category =>
        category.categoryName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110">
                <Helmet>
                    <title>Add New Product | FlipBundle - Upload Your Digital Product</title>
                    <meta name="description" content="Easily add a new digital product to your FlipBundle store. Upload your files, set product details, and start selling to a wide audience." />
                </Helmet>
                <div>
                    <h3 className="text-24 fw-bold text-dark-300 mb-2">Add Product</h3>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="text-dark-200 fs-6">Seller Dashboard</li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </li>
                        <li className="text-lime-300 fs-6">Add Product</li>
                    </ul>
                </div>
                {/* Content */}
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <form onSubmit={handleSubmit} className="comment-form container add-product">
                            <input type="hidden" name="productStatus" value="Approved" />
                            <input type="hidden" name="sellerEmail" value={user?.email} />
                            <div className="columns gap20">
                                {/* Basic Info */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Basic Info</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        <div className="row g-4">
                                            {/* Product Name */}
                                            <div className="col-12">
                                                <div className="form-container">
                                                    <label className="form-label">
                                                        Item Name (Eg: 70+ Premium WordPress Themes) <br />
                                                        <div className='mt-2'>
                                                            <span className="mt-2">Character count: {charCount}/45</span>
                                                            {productNameError && <span className="text-danger ms-2">({productNameError})</span>}
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        placeholder="Item Name"
                                                        value={productName}
                                                        onChange={handleProductNameChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {/* Price */}
                                            <div className="col-12">
                                                <div className="form-container">
                                                    <label className="form-label">Price (USD)</label>
                                                    <input
                                                        type="number"
                                                        className="form-control shadow-none"
                                                        placeholder="Price"
                                                        value={price}
                                                        onChange={handlePriceChange}
                                                        onWheel={(e) => e.target.blur()}
                                                        min="1"
                                                        max="500"
                                                        step="0.01"
                                                        required
                                                    />
                                                    {priceError && <div className="text-danger">{priceError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-container">
                                                    <label className="form-label">Main Category</label>
                                                    <select
                                                        className="form-control shadow-none"
                                                        value={selectedMainCategory}
                                                        onChange={(e) => {
                                                            setSelectedMainCategory(e.target.value);
                                                            setSearchTerm('');
                                                            setIsDropdownOpen(false);
                                                        }}
                                                    >
                                                        <option value="">Select a main category</option>
                                                        {uniqueMainCategories.map((mainCategory, index) => (
                                                            <option key={index} value={mainCategory}>
                                                                {mainCategory}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-container">
                                                    <label className="form-label">Sub Category</label>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            placeholder="Search categories..."
                                                            value={searchTerm}
                                                            onChange={(e) => {
                                                                setSearchTerm(e.target.value);
                                                                setIsDropdownOpen(true);
                                                            }}
                                                            onClick={() => setIsDropdownOpen(true)}
                                                        />
                                                        {isDropdownOpen && subcategories.length > 0 && (
                                                            <div
                                                                className="position-absolute w-100 bg-white border rounded mt-1 shadow-sm"
                                                                style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1000 }}
                                                            >
                                                                {subcategories.map((category) => (
                                                                    <div
                                                                        key={category._id}
                                                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                                                        onClick={() => {
                                                                            setSelectedCategory(category.slug);
                                                                            setSearchTerm(category.categoryName);
                                                                            setIsDropdownOpen(false);
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {category.categoryName}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <input
                                                            type="hidden"
                                                            name="category"
                                                            value={selectedCategory}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Description */}
                                            <div className="col-12">
                                                <>
                                                    <label className="form-label">Product Description</label>
                                                    <ReactQuill
                                                        value={productDescription}
                                                        onChange={setProductDescription}
                                                        modules={quillModules}
                                                        className="form-control text-area-sec"
                                                        placeholder="Product Description"
                                                    />
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Images Section */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Upload Images</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        {/* Featured Image */}
                                        <div className="mb-4">
                                            <label className="form-label">Featured Image (960x540)</label>

                                            <div className="d-flex align-items-center gap-3">

                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={(e) => handleImageUpload(e, setFeaturedImage)}
                                                    accept="image/*"
                                                />

                                                {featuredImage && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleImageRemove(setFeaturedImage)}
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>

                                            {featuredImage && (
                                                <img src={featuredImage} alt="Featured" className="mt-2" style={{ height: '100px' }} />
                                            )}
                                        </div>
                                        {/* Additional Images */}
                                        {[
                                            { state: productImageOne, setState: setProductImageOne, label: 'Product Image One' },
                                            { state: productImageTwo, setState: setProductImageTwo, label: 'Product Image Two' },
                                            { state: productImageThree, setState: setProductImageThree, label: 'Product Image Three' },
                                        ].map((image, index) => (
                                            <div key={index} className="mb-4">
                                                <label className="form-label">{image.label}</label>
                                                <div className="d-flex align-items-center gap-3">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => handleImageUpload(e, image.setState)}
                                                        accept="image/*"
                                                    />
                                                    {image.state && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => handleImageRemove(image.setState)}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                                {image.state && (
                                                    <img src={image.state} alt={image.label} className="mt-2" style={{ height: '100px' }} />
                                                )}
                                            </div>
                                        ))}
                                        <small
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "0.85rem",
                                                color: "#6c757d",
                                                marginTop: "10px",
                                                padding: "5px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <span style={{ color: "red", marginRight: "5px" }}>*</span>
                                            <Info size={15} style={{ marginRight: "5px" }} />
                                            Maximum File Size: 2MB
                                        </small>




                                    </div>

                                </div>
                                {/* Access Information */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Access Information</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <div className="form-label">
                                                    <label className="form-label">Product Access Link</label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        placeholder="Access Link"
                                                        value={accessLink}
                                                        onChange={(e) => setAccessLink(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-label">
                                                    <label className="form-label">Guide Line (e.g How to access)</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        rows="5"
                                                        placeholder="Guide Line"
                                                        value={guideLine}
                                                        onChange={(e) => setGuideLine(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* SEO Information */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Tags Information</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        {/* Tags */}
                                        <div className="mb-4">
                                            <label className="form-label">
                                                Tags (Add max 5 tags by pressing Enter. Tags help buyers find your product in search results.)
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control shadow-none"
                                                placeholder="Enter a tag and press Enter"
                                                onKeyDown={handleTagInputKeyDown}
                                            />
                                            <div className="tags-list mt-2">
                                                {tags.map((tag, index) => (
                                                    <span key={index} className="badge bg-primary me-2 mb-2">
                                                        {tag}
                                                        <button
                                                            type="button"
                                                            className="btn-close btn-close-white ms-2"
                                                            onClick={() => handleTagRemove(tag)}
                                                            style={{ fontSize: '0.75rem' }} />
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Submit Button */}
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-btn-secondary-lg"
                                        disabled={isSubmitting || !!productNameError || !!priceError}>
                                        {isSubmitting ? 'Publishing...' : 'Publish Now'}
                                        <MoveRight />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddProductNew;