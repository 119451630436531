import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../firebase.init";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const UpdateAccessItemForOrder = () => {
    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [accessLink, setAccessLink] = useState("");
    const [guideLine, setGuideLine] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/order/${id}`)
            .then((res) => res.json())
            .then((info) => {
                setOrder(info);
                setAccessLink(info.accessLink);
                setGuideLine(info.guideLine);
            });
    }, [id]);

    const handleUpdateAccessOrderLink = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const updateAccessLink = {
            accessLink,
            guideLine,
        };

        const url = `https://server.enjoywiki.com/flip-server/update-order-seller/${order._id}`;
        try {
            await fetch(url, {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(updateAccessLink),
            });
            toast.success("Update Successfully!");
            navigate('/seller/orders');
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110">
                <Helmet>
                    <title>Update Access for Order | FlipBundle - Manage Your Order Access</title>
                    <meta name="description" content="Update the access items for your order on FlipBundle. Ensure that your customers can access the correct digital products they purchased." />
                </Helmet>

                <div>
                    <h3 className="text-24 fw-bold text-dark-300 mb-2">Update Order</h3>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="text-dark-200 fs-6">Seller Dashboard</li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </li>
                        <li className="text-lime-300 fs-6">Update Order</li>
                    </ul>
                </div>

                {/* Content */}
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <form onSubmit={handleUpdateAccessOrderLink} className="comment-form container add-product">
                            <div className="columns gap20">
                                {/* Access Information */}
                                <div className="gig-info-card mb-4">
                                    <div className="gig-info-header">
                                        <h4 className="text-18 fw-semibold text-dark-300">Access Information</h4>
                                    </div>
                                    <div className="gig-info-body bg-white">
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <div className="form-label">
                                                    <label className="form-label">Product Access Link</label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        placeholder="Access Link"
                                                        value={accessLink}
                                                        onChange={(e) => setAccessLink(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-label">
                                                    <label className="form-label">Guide Line (e.g How to access)</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        rows="5"
                                                        placeholder="Guide Line"
                                                        value={guideLine}
                                                        onChange={(e) => setGuideLine(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-btn-secondary-lg"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Updating...' : 'Update Now'}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={14}
                                            height={10}
                                            viewBox="0 0 14 10"
                                            fill="none"
                                            className="ms-2"
                                        >
                                            <path
                                                d="M9 9L13 5M13 5L9 1M13 5L1 5"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default UpdateAccessItemForOrder;