import React from "react";
import { 
  Home, 
  RotateCcw, 
  Clock, 
  CheckCircle2, 
  XCircle, 
  HelpCircle,
  ClipboardList,
  AlertTriangle,
  Mail,
  FileWarning,
  ShieldAlert
} from "lucide-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
    return (
        <div className="bg-offWhite">
            <Helmet>
                <title>Refund Policy | FlipBundle - Digital Product Marketplace</title>
                <meta 
                    name="description" 
                    content="Read FlipBundle's Refund Policy to understand our guidelines for refunds on digital product purchases. We aim to provide fair, transparent, and customer-focused support for every transaction." 
                />
            </Helmet>

            {/* Hero Section */}
            <div className="position-relative bg-primary text-white py-5 bg-offWhite">
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25"></div>
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3">
                                    <li className="breadcrumb-item d-flex align-items-center">
                                        <Home className="me-1" size={16} />
                                        <Link to="/" className="text-white text-decoration-none">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        Refund Policy
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="display-5 fw-bold mb-3 d-flex align-items-center">
                                <RotateCcw className="me-3" size={45} />
                                Refund Policy
                            </h1>
                            <p className="fs-4 opacity-75">Welcome to FlipBundle.com</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <main className="py-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {/* Introduction Alert */}
                            <div className="alert alert-info mb-4">
                                <div className="d-flex gap-3">
                                    <Clock className="flex-shrink-0" size={24} />
                                    <p className="mb-0">
                                        Since we are offering non-tangible irrevocable, digital goods we issue refunds on a case-by-case basis only. 
                                        Refunds must be requested within <strong>15 days</strong> of the purchase order.
                                    </p>
                                </div>
                            </div>

                            {/* Conditions Section */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <CheckCircle2 className="text-primary flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-4">1. Conditions for a Refund</h2>
                                            <p className="text-muted mb-3">You must meet ALL of the following conditions to qualify for a refund:</p>
                                            <div className="bg-light p-4 rounded">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="mb-3 d-flex align-items-start">
                                                        <Clock className="text-primary me-2 flex-shrink-0" size={20} />
                                                        <span>Within the first 15 days of purchase</span>
                                                    </li>
                                                    <li className="mb-3 d-flex align-items-start">
                                                        <FileWarning className="text-primary me-2 flex-shrink-0" size={20} />
                                                        <span>Issues with product installation or basic functionality</span>
                                                    </li>
                                                    <li className="mb-3 d-flex align-items-start">
                                                        <HelpCircle className="text-primary me-2 flex-shrink-0" size={20} />
                                                        <span>Attempted resolution with our support team</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Exceptions Section */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <AlertTriangle className="text-warning flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">2. Exceptions</h2>
                                            <p className="text-muted">
                                                In the case the product you purchased didn't work properly as advertised and we couldn't resolve the issue, 
                                                we will offer a full refund within 15 days of the purchase. However, since the products are digitally delivered 
                                                and are non-tangible goods, we can offer a refund only if the item(s) you purchased is:
                                            </p>
                                            <ul className="list-unstyled border-start border-warning border-4 ps-3 mt-3">
                                                <li className="mb-2">• Broken or not working properly</li>
                                                <li>• Missing features as advertised on the sales page</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Grounds for Refusal */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <XCircle className="text-danger flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">3. Grounds for Refusal</h2>
                                            <div className="alert alert-danger">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="mb-2 d-flex align-items-start">
                                                        <ShieldAlert className="text-danger me-2 flex-shrink-0" size={20} />
                                                        <span>Issues caused by third-party plugins, themes, or other software</span>
                                                    </li>
                                                    <li className="d-flex align-items-start">
                                                        <ShieldAlert className="text-danger me-2 flex-shrink-0" size={20} />
                                                        <span>Issues related to demo import (depends on hosting service)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Process Section */}
                            <div className="card mb-4 shadow-sm border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex gap-3">
                                        <ClipboardList className="text-success flex-shrink-0" size={24} />
                                        <div>
                                            <h2 className="h3 mb-3">4. Process for Requesting a Refund</h2>
                                            <p className="text-muted mb-3">Before you request a refund, you must do the following:</p>
                                            <div className="bg-light p-4 rounded">
                                                <ol className="mb-0">
                                                    <li className="mb-3">Read the extensive documentation related to the item you purchased.</li>
                                                    <li className="mb-3">Confirm that your server meets the specifications laid out in the requirements.</li>
                                                    <li>Open a new support ticket if you're still facing issues.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Contact Section */}
                            <div className="card bg-primary text-white text-center">
                                <div className="card-body p-4">
                                    <h2 className="h3 mb-3">Need Help?</h2>
                                    <p className="mb-4">
                                        If you have any questions about our refund policy or need assistance, 
                                        our support team is here to help.
                                    </p>
                                    <Link   
                                        to="/contact"
                                        className="btn btn-light d-inline-flex align-items-center"
                                    >
                                        <Mail className="me-2" size={16} />
                                        Contact Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <style jsx>{`
                .card {
                    transition: all 0.3s ease;
                }
                .card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
                }
            `}</style>
        </div>
    );
};

export default RefundPolicy;