
import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';

// Modal component
const CategoriesModal = ({ isOpen, onClose, categories }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal fade categor show" tabIndex={-1} style={{ display: 'block', background: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header px-5 py-4 d-flex justify-content-between border-bottom">
            <div>
              <h3 className="text-dark-300 fw-bold text-24">All Categories</h3>
            </div>
            <div>
              <button 
                type="button" 
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
          </div>
          <div className="modal-body px-5 py-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  {/* Graphic */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        Graphic
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "Graphic")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  {/* Templates */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        Templates
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "Template")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  {/* Database */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        Database
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "Database")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  {/* Website */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        Website
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "Website")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  {/* E-Book */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        E-Book
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "eBook")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  {/* Photos */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        Photos
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "StockPhotos")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  {/* Software */}
                  <div className="col-lg-3 mb-4">
                    <div>
                      <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                        Software
                      </h4>
                      <nav className="category-nav">
                        <ul>
                          {categories
                            .filter(category => category.mainCategory === "Software")
                            .map(category => (
                              <li key={category.slug}>
                                <a 
                                  href={`/category/${category.slug}`}
                                  onClick={onClose}
                                >
                                  {category.categoryName}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CategoriesNavItem = ({ categories }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button 
        onClick={() => setIsModalOpen(true)}
        className="nav-link d-flex align-items-center gap-2"
      >
        <Menu size={20} />
        All Categories
      </button>
      <CategoriesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        categories={categories}
      />
    </>
  );
};

// Mobile Categories Component
export const MobileCategoriesNavItem = ({ categories }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button 
        onClick={() => setIsModalOpen(true)}
        className="nav-link w-100 d-flex align-items-center gap-2"
      >
        <Menu size={20} />
        All Categories
      </button>
      <CategoriesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        categories={categories}
      />
    </>
  );
};