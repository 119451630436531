import React from "react";
import { Shield, Home, ChevronRight, Mail, Lock, Cookie, Bell, Users, CreditCard, RefreshCw } from "lucide-react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <main className="privacy-policy-wrapper bg-offWhite">
            <Helmet>
                <title>Privacy Policy | FlipBundle</title>
                <meta name="description" content="Read FlipBundle's Privacy Policy to learn how we collect, use, and protect your personal information. We value your privacy and are committed to securing your data." />

            </Helmet>
            <div className="position-relative bg-primary text-white py-5 ">
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25"></div>
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3">
                                    <li className="breadcrumb-item d-flex align-items-center">
                                        <Home className="me-1" size={16} />
                                        <a href="/" className="text-white text-decoration-none">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        Privacy Policy
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="display-5 fw-bold mb-3 d-flex align-items-center">
                                <Shield className="me-3" size={45} />
                                Privacy Policy
                            </h2>
                            <p className="fs-4 opacity-75">Your privacy is our top priority at FlipBundle</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container py-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        {/* Alert */}
                        <div className="alert alert-info mb-4">
                            <p className="mb-0">
                                This privacy policy outlines how we collect, use, and protect your personal information.
                                Last updated: November 2024
                            </p>
                        </div>

                        {/* Information Collection Section */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <Users className="text-primary mt-1 me-3" size={24} />
                                    <div>
                                        <h2 className="h3 mb-4">Information We Collect</h2>
                                        <ul className="list-unstyled">
                                            <li className="mb-3 d-flex align-items-center">
                                                <span className="bg-primary rounded-circle me-2" style={{ width: '8px', height: '8px' }}></span>
                                                Personal identification information (Name, email address, phone number)
                                            </li>
                                            <li className="mb-3 d-flex align-items-center">
                                                <span className="bg-primary rounded-circle me-2" style={{ width: '8px', height: '8px' }}></span>
                                                Payment information (credit card details, billing address)
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <span className="bg-primary rounded-circle me-2" style={{ width: '8px', height: '8px' }}></span>
                                                Technical data (IP address, browser type)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* How We Use Information */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <RefreshCw className="text-success mt-1 me-3" size={24} />
                                    <div className="w-100">
                                        <h2 className="h3 mb-4">How We Use Your Information</h2>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <CreditCard className="text-success mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Process Transactions</h3>
                                                    <p className="text-muted small mb-0">Secure handling of your payment information</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <Bell className="text-success mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Service Updates</h3>
                                                    <p className="text-muted small mb-0">Keep you informed about your orders and services</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Protection Section */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <Lock className="text-purple mt-1 me-3" size={24} />
                                    <div>
                                        <h2 className="h3 mb-4">How We Protect Your Information</h2>
                                        <p className="text-muted mb-4">
                                            We implement industry-standard security measures to maintain the safety of your personal information.
                                        </p>
                                        <div className="bg-light p-4 rounded">
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-2 d-flex align-items-center">
                                                    <span className="bg-primary rounded-circle me-2" style={{ width: '6px', height: '6px' }}></span>
                                                    Encrypted data transmission
                                                </li>
                                                <li className="mb-2 d-flex align-items-center">
                                                    <span className="bg-primary rounded-circle me-2" style={{ width: '6px', height: '6px' }}></span>
                                                    Secure server infrastructure
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <span className="bg-primary rounded-circle me-2" style={{ width: '6px', height: '6px' }}></span>
                                                    Regular security audits
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Cookies Section */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <Cookie className="text-warning mt-1 me-3" size={24} />
                                    <div>
                                        <h2 className="h3 mb-4">Cookies</h2>
                                        <p className="text-muted mb-0">
                                            We use cookies to enhance your browsing experience and provide personalized services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Contact Section */}
                        <div className="bg-light rounded p-5 text-center mb-4">
                            <Mail className="text-primary mb-3" size={32} />
                            <h2 className="h3 mb-3">Have Questions?</h2>
                            <p className="text-muted mb-4">
                                If you have any questions about our privacy policy, please don't hesitate to contact us.
                            </p>
                            <a
                                href="mailto:support@flipbundle.com"
                                className="btn btn-primary d-inline-flex align-items-center"
                            >
                                <Mail className="me-2" size={16} />
                                Contact Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
        .privacy-policy-wrapper {
          margin-bottom: 60px; /* Adjust this value based on your footer height */
        }
        .card {
          transition: transform 0.2s ease-in-out;
        }
        .card:hover {
          transform: translateY(-5px);
        }
      `}</style>
        </main>
    );
};

export default PrivacyPolicy;