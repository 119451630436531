import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import auth from "../../../firebase.init";

const AuthorProfileUpdate = () => {
    const [user] = useAuthState(auth);
    const [authorData, setAuthorData] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const navigate = useNavigate();
    const defaultImageURL =
        "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg";

    // Check if the author profile exists
    useEffect(() => {
        const checkAuthorProfile = async () => {
            if (user?.email) {
                try {
                    const response = await fetch(
                        `https://server.enjoywiki.com/flip-server/author?authorEmail=${user.email}`
                    );
                    const data = await response.json();
                    if (data) {
                        setAuthorData(data);
                    }
                } catch (error) {
                    console.error("Error checking author profile:", error);
                }
            }
        };
        checkAuthorProfile();
    }, [user]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 1 * 1024 * 1024) {
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
        } else {
            toast.error("Image size exceeds 1 MB.");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const authorName = formData.get("authorName");
        const authorDescription = formData.get("authorDescription");
        const imageUpload = imageFile ? imageFile : authorData?.authorImage || defaultImageURL;

        let authorImage = imageUpload;
        if (imageFile) {
            const uploadData = new FormData();
            uploadData.append("image", imageFile);
            try {
                const response = await fetch("https://server.enjoywiki.com/image-server/upload", {
                    method: "POST",
                    body: uploadData,
                });
                const result = await response.json();
                authorImage = result.file?.url || defaultImageURL;
            } catch (error) {
                toast.error("Failed to upload image.");
            }
        }

        const authorProfile = {
            authorName,
            authorEmail: user.email,
            authorDescription,
            authorImage,
            authorStatus: authorData ? authorData.authorStatus : "pending",
        };

        try {
            const response = await fetch("https://server.enjoywiki.com/flip-server/author", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(authorProfile),
            });
            const result = await response.json();
            if (result.upsertedCount || result.modifiedCount) {
                toast.success(
                    authorData ? "Profile updated successfully!" : "Your profile has been created and is currently awaiting approval! "
                );
                navigate("/seller/dashboard");
            }
        } catch (error) {
            toast.error("Failed to save profile.");
        }
    };

    return (
        <>
            <main className="dashboard-main min-vh-100">
                <div className="d-flex flex-column gap-4 pb-110 p-4">
                    <Helmet>
                        <title> Author | FlipBundle - Manage Author Profile</title>
                    </Helmet>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="text-24 fw-bold text-dark-300 mb-2">Author Profile</h3>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="text-dark-200 fs-6">Dashboard</li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                        <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </li>
                                <li className="text-lime-300 fs-6">Author Profile</li>
                            </ul>
                        </div>
                    </div>
                    {/* Content */}
                    <div className="gig-info-card">
                        <div className="gig-info-body ">
                            <div className="overflow-x-auto">
                                <div className="w-100">
                                    {authorData?.authorStatus === "pending" ? (
                                        <div className="alert alert-warning" role="alert">
                                            Your profile is currently under review. You will be able to view or update it once it has been approved. Thank you for your patience!
                                        </div>
                                    ) : authorData?.authorStatus === "Approved" ? (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label htmlFor="authorName" className="form-label">
                                                    Author Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="authorName"
                                                    name="authorName"
                                                    defaultValue={authorData?.authorName || ""}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="authorDescription" className="form-label">
                                                    Author Description
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    id="authorDescription"
                                                    name="authorDescription"
                                                    defaultValue={authorData?.authorDescription || ""}
                                                    rows="4"
                                                    required
                                                ></textarea>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="authorImage" className="form-label">
                                                    Upload Author Image
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="authorImage"
                                                    name="authorImage"
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                />
                                                <img
                                                    src={imagePreview || authorData?.authorImage || defaultImageURL}
                                                    alt="Author Preview"
                                                    className="mt-3"
                                                    style={{ maxWidth: "150px", borderRadius: "8px" }}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary">
                                                Update Profile
                                            </button>
                                        </form>
                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label htmlFor="authorName" className="form-label">
                                                    Author Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="authorName"
                                                    name="authorName"
                                                    defaultValue={authorData?.authorName || ""}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="authorDescription" className="form-label">
                                                    Author Description
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    id="authorDescription"
                                                    name="authorDescription"
                                                    defaultValue={authorData?.authorDescription || ""}
                                                    rows="4"
                                                    required
                                                ></textarea>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="authorImage" className="form-label">
                                                    Upload Author Image
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="authorImage"
                                                    name="authorImage"
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                />
                                                <img
                                                    src={imagePreview || authorData?.authorImage || defaultImageURL}
                                                    alt="Author Preview"
                                                    className="mt-3"
                                                    style={{ maxWidth: "150px", borderRadius: "8px" }}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary">
                                                Create Author Profile
                                            </button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default AuthorProfileUpdate;
