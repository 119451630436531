import React from "react";
import { Shield, Home, Users, Target, Lightbulb, Star, ChevronRight, Download, CheckCircle, Clock, Gift } from "lucide-react";
import { Helmet } from "react-helmet";
import TestimonialSlide from "../components/HomePage/TestimonialSlide";
import Features from "../components/HomePage/Features";

const About = () => {
    return (
        <main className="about-wrapper bg-offWhite">
            <Helmet>
                <title>About Us | FlipBundle</title>
                <meta
                    name="description"
                    content="Discover FlipBundle, a trusted marketplace for buying and selling digital products. Learn about our mission to empower creators and connect buyers with unique digital content."
                />
            </Helmet>

            {/* Hero Section */}
            <div className="position-relative bg-primary text-white py-5">
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25"></div>
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3">
                                    <li className="breadcrumb-item d-flex align-items-center">
                                        <Home className="me-1" size={16} />
                                        <a href="/" className="text-white text-decoration-none">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        About Us
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="display-5 fw-bold mb-3 d-flex align-items-center">
                                <Shield className="me-3" size={45} />
                                About FlipBundle
                            </h2>
                            <p className="fs-4 opacity-75">Your trusted marketplace for digital products</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        {/* Image Section */}
                        <div className="card mb-4 shadow-sm border-0 overflow-hidden">
                            <img
                                src="https://i.postimg.cc/RVXCg43d/About-Us-1.jpg"
                                alt="About FlipBundle"
                                className="w-100"
                            />
                        </div>

                        {/* About Us Section */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">

                                    <div>
                                        <h2 className="h3 mb-4">   <Users className="text-primary mb-1 me-3" size={24} />About Us</h2>
                                        <p className="text-muted">
                                            Welcome to our digital marketplace, your one-stop destination for high-quality bulk digital products. We specialize in connecting sellers and buyers in a seamless, secure, and user-friendly environment. Our platform offers a diverse range of products, including bulk Canva editable templates, social media post designs, email databases, website scripts, WordPress themes, and email templates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Why Choose Us Section */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <Star className="text-warning mt-1 me-3" size={24} />
                                    <div className="w-100">
                                        <h2 className="h3 mb-4">Why Choose Us</h2>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <Shield className="text-primary mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Secure Platform</h3>
                                                    <p className="text-muted small mb-0">Guaranteed secure transactions with encrypted data protection</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <Target className="text-success mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Quality Products</h3>
                                                    <p className="text-muted small mb-0">Stringent quality checks for all digital assets</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <Download className="text-info mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Instant Downloads</h3>
                                                    <p className="text-muted small mb-0">Access your purchased products immediately after payment</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <CheckCircle className="text-danger mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Verified Sellers</h3>
                                                    <p className="text-muted small mb-0">All sellers are vetted to ensure highest quality standards</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <Clock className="text-warning mb-2" size={20} />
                                                    <h3 className="h5 mb-2">24/7 Support</h3>
                                                    <p className="text-muted small mb-0">Round-the-clock customer service for all your needs</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bg-light p-4 rounded h-100">
                                                    <Gift className="text-purple mb-2" size={20} />
                                                    <h3 className="h5 mb-2">Regular Updates</h3>
                                                    <p className="text-muted small mb-0">Fresh content and new digital products added daily</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        


                        {/* Our Mission Section */}
                        <div className="card mb-4 shadow-sm border-0">

                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <div>
                                        <h2 className="h3 mb-4"><Lightbulb className="text-purple mb-1 me-2" size={24} />Our Mission</h2>
                                        <p className="text-muted">
                                            At FlipBundle.com, our mission is to empower businesses, marketers, and creatives by providing high-quality bulk digital products that meet their diverse needs. We are dedicated to fostering a seamless, secure, and efficient platform where sellers can showcase their expertise and buyers can find the perfect digital assets to enhance their projects. <br></br><br></br>We believe in the power of digital innovation and strive to make it accessible to everyone. Our commitment to quality ensures that every product on our marketplace meets the highest standards, helping our customers achieve their goals with confidence. <br></br><br></br>By bridging the gap between talented creators and eager consumers, we aim to build a thriving community that thrives on collaboration, creativity, and mutual success. Join us on our journey to revolutionize the digital marketplace, making it easier and more rewarding for both sellers and buyers to succeed.
                                        </p>
                                        <div className="bg-light p-4 rounded mt-4">
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-2 d-flex align-items-center">
                                                    <span className="bg-primary rounded-circle me-2" style={{ width: '6px', height: '6px' }}></span>
                                                    Bridge the gap between creators and consumers
                                                </li>
                                                <li className="mb-2 d-flex align-items-center">
                                                    <span className="bg-primary rounded-circle me-2" style={{ width: '6px', height: '6px' }}></span>
                                                    Foster innovation and accessibility
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <span className="bg-primary rounded-circle me-2" style={{ width: '6px', height: '6px' }}></span>
                                                    Maintain highest quality standards
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Features />
            <TestimonialSlide></TestimonialSlide>
            <style jsx>{`
                .about-wrapper {
                    margin-bottom: 60px;
                }
                .card {
                    transition: transform 0.2s ease-in-out;
                }
                .card:hover {
                    transform: translateY(-5px);
                }
                .text-purple {
                    color: #6f42c1;
                }
            `}</style>
        </main>
    );
};

export default About;