import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <div className="row justify-content-end mt-4">
            <div className="col-auto">
                <nav>
                    <ul className="custom-pagination pagination">
                        <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="custom-page-link page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                        </li>
                        {[...Array(totalPages)].map((_, i) => (
                            <li
                                key={i + 1}
                                className={`custom-page-item page-item ${currentPage === i + 1 ? 'active' : ''}`}
                            >
                                <button
                                    className="custom-page-link page-link"
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button
                                className="custom-page-link page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Pagination;
