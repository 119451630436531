import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const ProductContentDisplay = ({ productDescription }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const maxLength = 500;

  // Parse and enhance the HTML content with the desired classes
  const processDescription = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Process headings and add respective classes
    const addClasses = (selector, className) => {
      tempDiv.querySelectorAll(selector).forEach(element => {
        element.className = className;
      });
    };

    // Add classes to different elements
    addClasses('h2', 'service-details-title fw-bold mb-4');
    addClasses('h3', 'service-details-subtitle fw-bold mb-3');
    addClasses('h4', 'fw-semibold text-dark-300 text-18 fw-semibold mb-2');

    // Process paragraphs
    tempDiv.querySelectorAll('p').forEach(p => {
      const text = p.textContent.toLowerCase();
      if (text.includes('e-book:') || text.includes('why is')) {
        p.className = 'text-dark-200 mb-4';
      } else if (text.includes('let\'s connect')) {
        p.className = 'pt-4';
      } else if (text.includes('ready to')) {
        p.className = 'py-4';
      } else {
        p.className = 'text-dark-200';
      }
    });

    // Process lists
    tempDiv.querySelectorAll('ul').forEach(ul => {
      ul.className = 'list-group list-group-numbered border-0';
      ul.querySelectorAll('li').forEach(li => {
        li.className = 'list-group-item border-0 p-0';
      });
    });

    return tempDiv.innerHTML;
  };

  useEffect(() => {
    // Create a temporary div to count characters without HTML tags
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = productDescription;
    const textContent = tempDiv.textContent || tempDiv.innerText;
    setShouldShowButton(textContent.length > maxLength);
  }, [productDescription]);

  const getDisplayContent = () => {
    if (!productDescription) return '';
    if (isExpanded) return productDescription;

    // Create a temporary div to truncate text while preserving HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = productDescription;
    let currentLength = 0;
    let truncatedHTML = '';

    const walkNodes = (node) => {
      if (currentLength >= maxLength) return;

      if (node.nodeType === 3) { // Text node
        const remainingLength = maxLength - currentLength;
        const text = node.textContent;
        if (currentLength + text.length <= maxLength) {
          truncatedHTML += text;
          currentLength += text.length;
        } else {
          truncatedHTML += text.substr(0, remainingLength) + '...';
          currentLength = maxLength;
        }
      } else if (node.nodeType === 1) { // Element node
        truncatedHTML += `<${node.tagName.toLowerCase()}`;
        // Copy attributes
        Array.from(node.attributes).forEach(attr => {
          truncatedHTML += ` ${attr.name}="${attr.value}"`;
        });
        truncatedHTML += '>';

        // Process child nodes
        Array.from(node.childNodes).some(child => {
          if (currentLength < maxLength) {
            walkNodes(child);
            return false;
          }
          return true;
        });

        truncatedHTML += `</${node.tagName.toLowerCase()}>`;
      }
    };

    Array.from(tempDiv.childNodes).some(node => {
      if (currentLength < maxLength) {
        walkNodes(node);
        return false;
      }
      return true;
    });

    return truncatedHTML;
  };

  return (
    <div className="mt-40">
      <div
        className="product-content"
        dangerouslySetInnerHTML={{
          __html: processDescription(getDisplayContent())
        }}
      />

      {shouldShowButton && (
        <div className='text-center'>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              transition: "color 0.3s ease",fontWeight: "bold"
            }}
            onMouseOver={(e) => (e.currentTarget.style.color = "#22be0d")}
            onMouseOut={(e) => (e.currentTarget.style.color = "#374151")}
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUp className="w-4 h-4" />
              </>
            ) : (
              <>
                View Full Description <ChevronDown className="w-4 h-4" />
              </>
            )}
          </button>
        </div>
      )}

      <style>{`
        .product-content h2.service-details-title {
          font-size: 24px;
          margin-bottom: 1.5rem;
          font-weight: bold;
        }

        .product-content h3.service-details-subtitle {
          font-size: 20px;
          margin-bottom: 1rem;
          font-weight: bold;
        }

        .product-content h4 {
          font-size: 18px;
          color: var(--text-dark-300);
          font-weight: 600;
          margin-bottom: 0.5rem;
        }

        .product-content p.text-dark-200 {
          color: var(--text-dark-200);
          margin-bottom: 1rem;
        }

        .product-content .py-4 {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .product-content .pt-4 {
          padding-top: 1rem;
        }

        .product-content .mb-4 {
          margin-bottom: 1.5rem;
        }

        .product-content .list-group {
          list-style: decimal;
          padding-left: 1rem;
          margin-bottom: 1rem;
        }

        .product-content .list-group-numbered {
          counter-reset: section;
        }

        .product-content .list-group-item {
          display: list-item;
          border: none;
          padding: 0;
          margin-bottom: 0.5rem;
        }

        .product-content strong {
          font-weight: 600;
          color: var(--text-dark-300);
        }

        .product-content ul {
          margin-bottom: 1.5rem;
        }
      `}</style>
    </div>
  );
};

export default ProductContentDisplay;