import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const Register = () => {
    return (
        <>
            <Helmet>
                <title>Register | FlipBundle - Join Our Digital Marketplace</title>
                <meta name="description" content="Sign up on FlipBundle to start exploring and selling high-quality digital products. Create your account today for access to a world of digital assets." />
            </Helmet>

            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(https://server.enjoywiki.com/image-server/uploads/breadcrumb_image_2024_05_17_11_50_01_3653-1732595195179-243691209.webp)"
                }}
            >
                <div className="container">
                    <div className="row text-center">
                        <div className="col-auto text-center">
                            <div
                                className="position-relative z-2 aos-init aos-animate"

                                data-aos-easing="linear"
                            >
                                <h2 className="section-title-light mb-2">Select Role</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-110 bg-offWhite">
                <div className="container">
                    <div className=" rounded-3 p-3">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8 text-center">
                                <motion.div
                                    className="sign-in-area p-5 rounded shadow-lg"
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 1, ease: "easeOut" }}
                                >
                                    <h2 className="mb-4 fw-bold text-primary">Join Us Today</h2>
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Link to='/register-seller' className="btn btn-lg btn-primary w-100 mb-3">
                                            Register as a Seller
                                        </Link>
                                    </motion.div>
                                    <hr className="my-4" />
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Link to='/register-buyer' className="btn btn-lg btn-secondary w-100">
                                            Register as a Buyer
                                        </Link>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Register;
