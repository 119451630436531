import { Eye } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const RecentProducts = ({ searchQuery }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage] = useState(8);
  const [sellers, setSellers] = useState({});
  const [randomizedProducts, setRandomizedProducts] = useState({});

  // Function to check if data is expired (older than 1 hour)
  const isDataExpired = (timestamp) => {
    if (!timestamp) return true;
    const now = new Date().getTime();
    const oneHour = 60 * 60 * 1000;
    return now - timestamp > oneHour;
  };

  // Function to store data with timestamp
  const storeData = (key, data) => {
    const dataWithTimestamp = {
      data: data,
      timestamp: new Date().getTime()
    };
    localStorage.setItem(key, JSON.stringify(dataWithTimestamp));
  };

  // Function to get data and check expiration
  const getStoredData = (key) => {
    try {
      const storedItem = localStorage.getItem(key);
      if (!storedItem) return null;

      const { data, timestamp } = JSON.parse(storedItem);
      if (isDataExpired(timestamp)) {
        localStorage.removeItem(key);
        return null;
      }
      return data;
    } catch (error) {
      console.error(`Error reading ${key} from localStorage:`, error);
      localStorage.removeItem(key);
      return null;
    }
  };

  // Function to shuffle array using Fisher-Yates algorithm
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Function to extract and deduplicate categories from products, then select 4 random categories
  const extractCategories = (products) => {
    const categories = products
      .filter(product => product.category)
      .map(product => product.category.trim())
      .filter(Boolean);

    const uniqueCategories = [...new Set(categories)];
    return shuffleArray(uniqueCategories).slice(0, 4);
  };

  // Function to randomize products for each category
  const randomizeProducts = (products, categories) => {
    const randomized = {};
    categories.forEach(category => {
      const categoryProducts = products.filter(product =>
        product.category === category &&
        product.productName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      randomized[category] = shuffleArray(categoryProducts);
    });
    return randomized;
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);

      try {
        // First, try to get all cached data
        const cachedProducts = getStoredData('products');
        const cachedSellers = getStoredData('sellers');
        const cachedReviews = getStoredData('reviews');

        let needsFresh = false;
        let products = cachedProducts;
        let sellers = cachedSellers;
        let reviews = cachedReviews;

        if (!products || !sellers || !reviews) {
          needsFresh = true;
        }

        if (needsFresh) {
          const [productsRes, sellersRes, reviewsRes] = await Promise.all([
            fetch('https://server.enjoywiki.com/flip-server/products'),
            fetch('https://server.enjoywiki.com/flip-server/users'),
            fetch('https://server.enjoywiki.com/flip-server/product-reviews')
          ]);

          const [productsData, sellersData, reviewsData] = await Promise.all([
            productsRes.json(),
            sellersRes.json(),
            reviewsRes.json()
          ]);

          products = productsData;
          sellers = sellersData;
          reviews = reviewsData;

          storeData('products', products);
          storeData('sellers', sellers);
          storeData('reviews', reviews);
        }

        // Extract random categories
        const randomCategories = extractCategories(products);

        // Randomize products for each category
        const randomized = randomizeProducts(products, randomCategories);

        setProducts(products);
        setCategories(randomCategories);
        setSellers(sellers);
        setReviews(reviews);
        setRandomizedProducts(randomized);

      } catch (error) {
        console.error('Error fetching data:', error);
        const cachedProducts = getStoredData('products') || [];
        const cachedSellers = getStoredData('sellers') || [];
        const cachedReviews = getStoredData('reviews') || [];

        const randomCategories = extractCategories(cachedProducts);
        const randomized = randomizeProducts(cachedProducts, randomCategories);

        setProducts(cachedProducts);
        setCategories(randomCategories);
        setSellers(cachedSellers);
        setReviews(cachedReviews);
        setRandomizedProducts(randomized);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [searchQuery]);

  const getSeller = (sellerEmail) => {
    return sellers.find(seller => seller.UserEmail === sellerEmail);
  };

  const getReviewCount = (productId) => {
    return reviews.filter((review) => review.productId === productId).length;
  };

  const getAverageRating = (productId) => {
    const productReviews = reviews.filter((review) => review.productId === productId);
    const totalRating = productReviews.reduce((acc, review) => acc + review.rating, 0);
    return productReviews.length > 0 ? (totalRating / productReviews.length).toFixed(1) : 0;
  };

  const ProductCard = ({ product }) => {
    const seller = getSeller(product.sellerEmail);
    return (
      <div className="col-lg-3 col-md-4 mb-4">
        <div className="service-card bg-white">
          <div className="position-relative">
           
            <img
              src={product.featuredImage || "/assets/img/services/1.png"}
              className="recently-view-card-img w-100"
              alt={product.productName}
              style={{ aspectRatio: '16 / 9', objectFit: 'cover' }}
            />
          </div>
          <div className="service-card-content">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3 className="service-card-price fw-bold">${product.price}</h3>
              </div>
              <div className="d-flex align-items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={15} viewBox="0 0 16 15" fill="none">
                  <path d="M16 5.95909C15.8855 6.07153 15.7709 6.21207 15.6564 6.32451C14.4537 7.36454 13.2511 8.37646 12.0484 9.38838C11.9339 9.47271 11.9053 9.55704 11.9625 9.69758C12.3348 11.2717 12.707 12.8739 13.0793 14.448C13.1365 14.6448 13.1079 14.8134 12.9361 14.9258C12.7643 15.0383 12.5925 15.0102 12.4207 14.9258C10.989 14.0826 9.58587 13.2393 8.15415 12.396C8.03961 12.3117 7.9537 12.3117 7.83917 12.396C6.43607 13.2393 5.00435 14.0826 3.60126 14.8977C3.48672 14.9821 3.34355 15.0102 3.20038 14.9821C2.9713 14.9258 2.85676 14.701 2.91403 14.448C3.14311 13.5204 3.34355 12.5928 3.57262 11.6652C3.74443 10.9906 3.8876 10.316 4.05941 9.64136C4.08805 9.52893 4.05941 9.47271 3.97351 9.38838C2.74222 8.34835 1.53957 7.30832 0.308291 6.26829C0.251022 6.21207 0.193753 6.18396 0.165118 6.12775C0.0219457 6.01531 -0.0353233 5.87477 0.0219457 5.678C0.0792147 5.50935 0.222387 5.42502 0.394194 5.39691C0.651905 5.36881 0.909615 5.3407 1.19596 5.3407C2.36998 5.22826 3.54399 5.14393 4.74664 5.0315C4.97572 5.00339 5.20479 4.97528 5.43387 4.97528C5.54841 4.97528 5.60567 4.91906 5.63431 4.83474C6.2929 3.31685 6.92286 1.82708 7.58146 0.309198C7.66736 0.140545 7.75326 0.0281089 7.9537 0C8.18278 0.0562179 8.32595 0.140545 8.41186 0.365416C8.75547 1.15247 9.09908 1.96762 9.4427 2.75467C9.75768 3.4574 10.044 4.18823 10.359 4.89095C10.3876 4.97528 10.4449 5.0315 10.5594 5.0315C11.4757 5.11583 12.3921 5.17204 13.337 5.25637C14.0815 5.31259 14.8546 5.39691 15.5991 5.45313C15.7996 5.48124 15.9141 5.59368 16 5.76233C16 5.81855 16 5.90288 16 5.95909Z" fill="currentColor" />
                </svg>
                <span className="service-card-rating">
                  {getAverageRating(product._id)} ({getReviewCount(product._id)} {getReviewCount(product._id) === 1 ? 'Review' : 'Reviews'})
                </span>
              </div>
            </div>
            <h3 className="service-card-title fw-semibold">
              <Link to={`/${product.slug}/${product.productId}`}>
                {product.productName}
              </Link>
            </h3>
            <div className="d-flex align-items-center service-card-author">
              <img
                src={seller?.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                className="service-card-author-img"
                alt=""
              />
              <Link to={`/profile-seller/${seller?._id}`} className="service-card-author-name">
                {seller?.userName}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CategorySection = ({ category }) => {
    const categoryProducts = randomizedProducts[category] || [];
    const displayProducts = categoryProducts.slice(0, itemsPerPage);
    const categorySlug = category.toLowerCase().replace(/\s+/g, '-');
    if (categoryProducts.length === 0) return null;

    return (
      <section className="pb-110">
        <div className="container">
          <div className="row mb-16 justify-content-between align-items-end">
            <div className="col-md-auto">
              <div>
                <h2 className="fw-bold section-title">{category}</h2>
                <p className="section-desc">Explore our {category.toLowerCase()} collection</p>
              </div>
            </div>
            <div className="col-md-auto">
              <Link
                to={`/category/${categorySlug}`}
                className="btn d-inline-flex align-items-center gap-2"
                style={{
                  background: '#22be0d',
                  color: '#F2F2F2',
                  padding: '12px 24px',
                  borderRadius: '8px',
                  textDecoration: 'none',
                  fontSize: '16px',
                  fontWeight: '500'
                }}
              >
                <Eye />
                View All {category}
              </Link>
            </div>
          </div>
          <div className="row">
            {displayProducts.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))}
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
          <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem' }}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {categories.map((category) => (
            <CategorySection key={category} category={category} />
          ))}
        </>
      )}
    </>
  );
};

export default RecentProducts;