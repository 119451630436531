// // import React from 'react';
// // import auth from '../../firebase.init';
// // import { useAuthState } from 'react-firebase-hooks/auth';
// // import { Navigate, useLocation } from 'react-router-dom';
// // import Loading from './Loading';


// // const RequireAuth = ({ children }) => {
// //     const [user, loading, error] = useAuthState(auth);
// //     const location = useLocation();

// //     if (loading) {
// //         return <Loading />; 
// //     }

// //     if (!user) {
// //         return <Navigate to='/login' state={{ from: location }} replace />;
// //     }

// //     return children;
// // };

// // export default RequireAuth;

// import React, { useEffect, useState } from 'react';
// import auth from '../../firebase.init';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { Navigate, useLocation } from 'react-router-dom';
// import Loading from './Loading';

// const RequireAuth = ({ children }) => {
//   const [user, loading, error] = useAuthState(auth);
//   const location = useLocation();
//   const [profileComplete, setProfileComplete] = useState(false);
//   const [profileLoading, setProfileLoading] = useState(true);

//   useEffect(() => {
//     const checkProfileCompleteness = async () => {
//       try {
//         const response = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user?.email}`);
//         const data = await response.json();
//         const userProfile = data[0];

//         // Check if all required fields are filled
//         const isComplete = userProfile.userName && userProfile.address && userProfile.city && userProfile.country;
//         setProfileComplete(isComplete);
//       } catch (error) {
//         console.error('Error checking profile completeness:', error);
//       }
//       setProfileLoading(false);
//     };

//     if (user) {
//       checkProfileCompleteness();
//     }
//   }, [user]);

//   if (!user) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   if (loading || profileLoading) {
//     return <Loading />;
//   }

 


//   if (!profileComplete) {
//     return <Navigate to="/update-profile" state={{ from: location }} replace />;
//   }

//   return children;
// };

// export default RequireAuth;

import React, { useEffect, useState } from 'react';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from './Loading';

const RequireAuth = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const [profileComplete, setProfileComplete] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);

  useEffect(() => {
    const checkProfileCompleteness = async () => {
      if (user) {
        try {
          const response = await fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`);
          const data = await response.json();
          const userProfile = data[0];

          // Check if all required fields are filled
          const isComplete = userProfile.userName && userProfile.address && userProfile.city && userProfile.country;
          setProfileComplete(isComplete);
        } catch (error) {
          console.error('Error checking profile completeness:', error);
        }
        setProfileLoading(false);
      }
    };

    checkProfileCompleteness();
  }, [user]);

  // Show loading if authentication or profile loading is still in progress
  if (loading || (user && profileLoading)) {
    return <Loading />;
  }

  // Redirect to login if user is not logged in
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Redirect to profile update if profile is incomplete
  if (!profileComplete) {
    return <Navigate to="/update-profile" state={{ from: location }} replace />;
  }

  // Render children if authenticated and profile is complete
  return children;
};

export default RequireAuth;
