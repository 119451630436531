import React from 'react';
import { Outlet } from 'react-router-dom';
import BuyerSidebarMenu from './BuyerSidebarMenu';
import BuyerMobileMenu from './BuyerMobileMenu';
import BuyerDashboardHeader from './BuyerDashboardHeader';


const BuyerMainDashBoardLayout = () => {
  return (
    <div className="d-xl-flex">
      {/* Sidebar */}
      <BuyerSidebarMenu />
      {/* Mobile Menu */}
      <BuyerMobileMenu />
      {/* Mobile End */}
      {/* Right */}
      <div className="flex-grow-1 align-items-center position-relative">
        {/* Header */}
        <BuyerDashboardHeader />
        {/* Main Content - Outlet for Nested Routes */}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BuyerMainDashBoardLayout;
