// import React, { useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useAuthState } from "react-firebase-hooks/auth";
// import auth from "../../firebase.init";
// import { Helmet } from "react-helmet";

// const Package = () => {
//     const [product, setProduct] = useState({});
//     const [orderDate, setOrderDate] = useState("");
//     const { id } = useParams();
//     const [user] = useAuthState(auth);
//     const navigate = useNavigate();
//     const [userInfo, setUserInfo] = useState([]);

//     useEffect(() => {
//         fetch(`https://server.enjoywiki.com/flip-server/product-info/${id}`)
//             .then((res) => res.json())
//             .then((info) => setProduct(info));
//     }, [id]);

//     useEffect(() => {
//         fetch(`https://server.enjoywiki.com/flip-server/users`)
//             .then((res) => res.json())
//             .then((info) => setUserInfo(info));
//     }, [user]);

//     useEffect(() => {
//         const currentDate = new Date();
//         const day = currentDate.getDate().toString().padStart(2, "0");
//         const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//         const year = currentDate.getFullYear();
//         setOrderDate(`${day}/${month}/${year}`);
//     }, []);

//     const generateUniquePaymentId = () => {
//         const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
//         let paymentId = "";
//         for (let i = 0; i < 8; i++) {
//             const randomIndex = Math.floor(Math.random() * characters.length);
//             paymentId += characters.charAt(randomIndex);
//         }
//         return paymentId;
//     };

//     const generateUniqueOrderId = () => {
//         const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
//         let orderId = "";
//         for (let i = 0; i < 8; i++) {
//             const randomIndex = Math.floor(Math.random() * characters.length);
//             orderId += characters.charAt(randomIndex);
//         }
//         return orderId;
//     };

//     const handleOrder = (event) => {
//         event.preventDefault();
//         const paymentId = generateUniquePaymentId();
//         const orderId = generateUniqueOrderId();

//         const formData = {
//             paymentId,
//             orderId,
//             packageId: product._id,
//             featuredImage: product.featuredImage,
//             packageName: product.productName,
//             sellerEmail: product.sellerEmail,
//             sellerId: userInfo.find(u => u.UserEmail === product.sellerEmail)?._id,
//             packagePrice: product.price,
//             paymentStatus: "pending",
//             orderStatus: "pending",
//             customerEmail: user?.email,
//             customerName: event.target.customerName.value,
//             customerNote: event.target.customerNote.value,
//             address: event.target.address.value,
//             cityName: event.target.cityName.value,
//             guideLine: product.guideLine,
//             accessLink: product.accessLink,
//             productId: product.productId,
//             productSlug: product.slug,
//             orderDate: orderDate,
//             productReview: "none"
//         };

//         fetch('https://server.enjoywiki.com/flip-server/new-order', {
//             method: "POST",
//             headers: {
//                 "content-type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         })
//             .then(() => {
//                 navigate("/buyer/pending-payment/");
//             });
//     };

//     const currentUser = userInfo.find(u => u.UserEmail === user?.email);
//     const isSeller = currentUser?.userRole === 'Seller';

//     return (
//         <>
//             <Helmet>
//                 <title>Complete Your Order | FlipBundle - Quick and Easy Checkout</title>
//                 <meta name="description" content="Complete your order on FlipBundle with our simple and secure checkout process. Enjoy instant access to your digital products after payment." />
//             </Helmet>

//             <section
//                 className="w-breadcrumb-area"
//                 style={{
//                     backgroundImage: "url(https://img.freepik.com/free-vector/half-tone-blue-abstract-background-with-text-space_1017-41428.jpg)",
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     backgroundPosition: "center"
//                 }}
//             >
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-auto">
//                             <div className="position-relative z-2">
//                                 <h2 className="section-title-light mb-2">Buy Now</h2>
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb w-breadcrumb">
//                                         <li className="breadcrumb-item">
//                                             <Link to="/">Home</Link>
//                                         </li>
//                                         <li className="breadcrumb-item active">Buy Now</li>
//                                     </ol>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <main className="min-vh-100">
//                 <div className="container py-5">
//                     <div className="row justify-content-center">
//                         <div className="col-xl-8">
//                             <div className="card">
//                                 <div className="card-body">
//                                     <h2 className="text-center mb-4">Fill in the Billing Information</h2>

//                                     {isSeller ? (
//                                         <div className="alert alert-warning text-center">
//                                             Sellers cannot purchase products
//                                         </div>
//                                     ) : (
//                                         <form onSubmit={handleOrder} className="gig-info-header">
//                                             <div className="row g-4">
//                                                 <div className="col-12">
//                                                     <label className="form-label">Item Name</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         value={product.productName}
//                                                         disabled
//                                                     />
//                                                 </div>

//                                                 <div className="col-12">
//                                                     <label className="form-label">Price</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         value={`$ ${product.price}`}
//                                                         disabled
//                                                     />
//                                                 </div>

//                                                 <div className="col-md-6">
//                                                     <label className="form-label">Full Name</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         name="customerName"
//                                                         defaultValue={currentUser?.userName}
//                                                         required
//                                                     />
//                                                 </div>

//                                                 <div className="col-md-6">
//                                                     <label className="form-label">Email</label>
//                                                     <input
//                                                         type="email"
//                                                         className="form-control"
//                                                         value={user?.email}
//                                                         disabled
//                                                     />
//                                                 </div>

//                                                 <div className="col-12">
//                                                     <label className="form-label">Address</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         name="address"
//                                                         defaultValue={currentUser?.address}
//                                                         required
//                                                     />
//                                                 </div>

//                                                 <div className="col-md-12">
//                                                     <label className="form-label">City</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         name="cityName"
//                                                         defaultValue={currentUser?.city}
//                                                         required
//                                                     />
//                                                 </div>

//                                                 <div className="col-12">
//                                                     <label className="form-label">Additional Notes</label>
//                                                     <textarea
//                                                         className="form-control"
//                                                         name="customerNote"
//                                                         rows="4"
//                                                     />
//                                                 </div>

//                                                 <div className="col-12">
//                                                     <button type="submit" className="w-btn-secondary-lg w-100">
//                                                         Make Payment
//                                                         <svg
//                                                             xmlns="http://www.w3.org/2000/svg"
//                                                             width={14}
//                                                             height={10}
//                                                             viewBox="0 0 14 10"
//                                                             fill="none"
//                                                         >
//                                                             <path
//                                                                 d="M9 9L13 5M13 5L9 1M13 5L1 5"
//                                                                 stroke="white"
//                                                                 strokeWidth="1.5"
//                                                                 strokeLinecap="round"
//                                                                 strokeLinejoin="round"
//                                                             />
//                                                         </svg>
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// };

// export default Package;





import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { Helmet } from "react-helmet";

const Package = () => {
    const [product, setProduct] = useState({});
    const [orderDate, setOrderDate] = useState("");
    const { id } = useParams();
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState([]);

    // Payment processing fee rate
    const PAYMENT_PROCESSING_FEE_RATE = 0.03; // 3%

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/product-info/${id}`)
            .then((res) => res.json())
            .then((info) => setProduct(info));
    }, [id]);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/users`)
            .then((res) => res.json())
            .then((info) => setUserInfo(info));
    }, [user]);

    useEffect(() => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear();
        setOrderDate(`${day}/${month}/${year}`);
    }, []);

    const generateUniquePaymentId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let paymentId = "";
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            paymentId += characters.charAt(randomIndex);
        }
        return paymentId;
    };

    const generateUniqueOrderId = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let orderId = "";
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            orderId += characters.charAt(randomIndex);
        }
        return orderId;
    };

    const handleOrder = (event) => {
        event.preventDefault();
        const paymentId = generateUniquePaymentId();
        const orderId = generateUniqueOrderId();

        // Calculate payment processing fee
        const originalPrice = parseFloat(product.price);
        const processingFee = originalPrice * PAYMENT_PROCESSING_FEE_RATE;
        const totalPrice = originalPrice + processingFee;

        const formData = {
            paymentId,
            orderId,
            packageId: product._id,
            featuredImage: product.featuredImage,
            packageName: product.productName,
            sellerEmail: product.sellerEmail,
            sellerId: userInfo.find(u => u.UserEmail === product.sellerEmail)?._id,
            packagePrice: product.price,
            processingFee: processingFee.toFixed(2), // Add processing fee to the order data
            totalPrice: totalPrice.toFixed(2), // Add total price including processing fee
            paymentStatus: "pending",
            orderStatus: "pending",
            customerEmail: user?.email,
            customerName: event.target.customerName.value,
            customerNote: event.target.customerNote.value,
            address: event.target.address.value,
            cityName: event.target.cityName.value,
            guideLine: product.guideLine,
            accessLink: product.accessLink,
            productId: product.productId,
            productSlug: product.slug,
            orderDate: orderDate,
            productReview: "none"
        };

        fetch('https://server.enjoywiki.com/flip-server/new-order', {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then(() => {
                navigate("/buyer/pending-payment/");
            });
    };

    const currentUser = userInfo.find(u => u.UserEmail === user?.email);
    const isSeller = currentUser?.userRole === 'Seller';

    // Calculate price details
    const originalPrice = parseFloat(product.price || 0);
    const processingFee = originalPrice * PAYMENT_PROCESSING_FEE_RATE;
    const totalPrice = originalPrice + processingFee;

    return (
        <>
            <Helmet>
                <title>Complete Your Order | FlipBundle - Quick and Easy Checkout</title>
                <meta name="description" content="Complete your order on FlipBundle with our simple and secure checkout process. Enjoy instant access to your digital products after payment." />
            </Helmet>

            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage: "url(https://img.freepik.com/free-vector/half-tone-blue-abstract-background-with-text-space_1017-41428.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="position-relative z-2">
                                <h2 className="section-title-light mb-2">Buy Now</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Buy Now</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <main className="min-vh-100">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="text-center mb-4">Fill in the Billing Information</h2>

                                    {isSeller ? (
                                        <div className="alert alert-warning text-center">
                                            Sellers cannot purchase products
                                        </div>
                                    ) : (
                                        <form onSubmit={handleOrder} className="gig-info-header">
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <label className="form-label">Item Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={product.productName}
                                                        disabled
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <label className="form-label">Price Details</label>
                                                    <div className="border rounded p-3">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <span>Original Price:</span>
                                                            <span>$ {originalPrice.toFixed(2)}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <span>Payment Processing Fee (3%):</span>
                                                            <span>$ {processingFee.toFixed(2)}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between fw-bold">
                                                            <span>Total Price:</span>
                                                            <span>$ {totalPrice.toFixed(2)}</span>
                                                        </div>
                                                        <div className="text-center mt-2">
                                                            <small className="text-muted">
                                                                * 3% fees applied for payment processing
                                                            </small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label">Full Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="customerName"
                                                        defaultValue={currentUser?.userName}
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={user?.email}
                                                        disabled
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <label className="form-label">Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="address"
                                                        defaultValue={currentUser?.address}
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-12">
                                                    <label className="form-label">City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="cityName"
                                                        defaultValue={currentUser?.city}
                                                        required
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <label className="form-label">Additional Notes</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="customerNote"
                                                        rows="4"
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <button type="submit" className="w-btn-secondary-lg w-100">
                                                        Make Payment
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={14}
                                                            height={10}
                                                            viewBox="0 0 14 10"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M9 9L13 5M13 5L9 1M13 5L1 5"
                                                                stroke="white"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Package;