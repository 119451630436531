import React, { useState } from "react";
import { LayoutTemplate, Target, Download, CheckCircle, Clock, Gift, Palette, Image, Shirt, Calendar, Package, FileType, FileCode, FileText, Layout, Mail, PresentationScreen, FileSpreadsheet, BarChartBig, ChevronDown, ChevronUp, Camera, ImagePlus, FileImage, Plus, Key, Tool, PresentationIcon, Home, ShoppingCart, CreditCard, Users, Star, PenToolIcon, Laptop, Book, BookOpen, DollarSign, HeartPulse, Cat, CalendarRange, Utensils, PanelsTopLeft, AppWindow } from 'lucide-react';

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const SellNow = () => {
    const [showAll, setShowAll] = useState(false);

    const categories = [
        {
            icon: <Image className="text-blue-600 mb-2" size={20} />,
            title: "Graphic Wall Art Bundles",
            description: "Elevate interior spaces with our curated graphic wall art bundles. High-resolution digital designs perfect for home decor, office spaces, and creative projects. Easy to print and customize across various sizes and styles."
        },
        {
            icon: <Palette className="text-purple-600 mb-2" size={20} />,
            title: "Digital Art Prints",
            description: "Transform your walls with our diverse digital art print collections. From modern abstract to vintage-inspired designs, these high-quality digital downloads offer instant art solutions for designers, decorators, and art enthusiasts."
        },
        {
            icon: <Shirt className="text-green-600 mb-2" size={20} />,
            title: "T-shirt Design",
            description: "Unlock creativity with our versatile t-shirt design templates. Perfect for entrepreneurs, print-on-demand businesses, and graphic designers seeking unique, market-ready graphics for various niches and styles."
        },
        {
            icon: <Calendar className="text-orange-600 mb-2" size={20} />,
            title: "Digital Planner",
            description: "Streamline productivity with comprehensive digital planner templates. Designed for professionals, students, and entrepreneurs, these interactive planners help you organize tasks, track goals, and enhance personal and work efficiency."
        },
        {
            icon: <Package className="text-yellow-600 mb-2" size={20} />,
            title: "Icons",
            description: "Enhance your digital projects with our comprehensive icon design collections. Featuring multiple styles, file formats, and customizable options for web designers, app developers, and creative professionals."
        },
        {
            icon: <FileType className="text-pink-600 mb-2" size={20} />,
            title: "Fonts",
            description: "Elevate your design projects with our extensive font collections. From modern minimalist to elegant script styles, these professionally crafted typefaces cater to graphic designers, marketers, and branding experts."
        },
        {
            icon: <FileCode className="text-indigo-600 mb-2" size={20} />,
            title: "Templates",
            description: "Accelerate your design workflow with our all-in-one template collection. Spanning multiple categories and design styles, these templates provide instant solutions for professionals across industries."
        },
        {
            icon: <FileText className="text-red-600 mb-2" size={20} />,
            title: "PSD Template",
            description: "Streamline your design process with our high-quality PSD templates. Fully layered and easily customizable, these templates are perfect for graphic designers, marketers, and creative professionals."
        },
        {
            icon: <Layout className="text-emerald-600 mb-2" size={20} />,
            title: "Logo Template",
            description: "Create stunning brand identities with our professional logo template collections. Designed for businesses, startups, and entrepreneurs seeking unique and adaptable branding solutions."
        },
        {
            icon: <Mail className="text-cyan-600 mb-2" size={20} />,
            title: "Email Template",
            description: "Elevate your email communications with our pre-designed, responsive email templates. Perfect for marketing campaigns, newsletters, and business correspondence across various industries."
        },
        {
            icon: <PresentationIcon className="text-violet-600 mb-2" size={20} />,
            title: "Powerpoint Template",
            description: "Impress your audience with professional PowerPoint templates. Featuring modern layouts, smooth transitions, and easy customization for business, education, and creative presentations."
        },
        {
            icon: <FileSpreadsheet className="text-lime-600 mb-2" size={20} />,
            title: "Resume Template",
            description: "Stand out in the job market with our comprehensive resume template designs. Crafted to highlight your skills, experience, and professional brand across multiple industries and career levels."
        },
        {
            icon: <BarChartBig className="text-amber-600 mb-2" size={20} />,
            title: "Infographic Template",
            description: "Transform complex data into compelling visual stories with our infographic template collections. Designed for marketers, analysts, and content creators seeking to communicate information effectively."
        },
        {
            icon: <Layout className="text-emerald-600 mb-2" size={20} />,
            title: "Database B2B Lead",
            description: "Accelerate your business growth with our meticulously curated B2B lead databases. Featuring verified contact information, segmented by industry, company size, and geographic location."
        },
        {
            icon: <Mail className="text-blue-600 mb-2" size={20} />,
            title: "Business Email Database",
            description: "Expand your marketing reach with our precision-targeted business email databases. Segmented by industry, company type, and professional roles to optimize your outreach strategies."
        },
        {
            icon: <Mail className="text-purple-600 mb-2" size={20} />,
            title: "Email Database",
            description: "Access extensive email databases covering diverse industries and demographics. Perfect for marketing campaigns, lead generation, and business networking opportunities."
        },
        {
            icon: <Laptop className="text-green-600 mb-2" size={20} />,
            title: "Website WP Plugin",
            description: "Enhance your WordPress website with our powerful plugin solutions. From SEO optimization to e-commerce integrations, these plugins provide robust functionality for website owners."
        },
        {
            icon: <AppWindow className="text-green-600 mb-2" size={20} />,
            title: "WordPress Themes",
            description: "Provide a variety of WordPress themes—from business to portfolio to blog themes—giving users the perfect design for their websites."
        },
        {
            icon: <FileCode className="text-orange-600 mb-2" size={20} />,
            title: "Website Script",
            description: "Accelerate web development with our comprehensive website script collection. Featuring e-commerce solutions, interactive features, and customizable components for developers and businesses."
        },
        {
            icon: <Layout className="text-yellow-600 mb-2" size={20} />,
            title: "Web Design",
            description: "Access cutting-edge web design resources, including UI/UX templates, design systems, and responsive frameworks to streamline your digital design process."
        },
        {
            icon: <Book className="text-pink-600 mb-2" size={20} />,
            title: "E-Book",
            description: "Create professional e-books with our comprehensive template library. Designed for authors, marketers, and content creators across various genres and styles."
        },
        {
            icon: <BookOpen className="text-indigo-600 mb-2" size={20} />,
            title: "Marketing eBooks",
            description: "Craft compelling marketing guides and resources with our specialized e-book templates. Perfect for digital marketers, entrepreneurs, and business strategists."
        },
        {
            icon: <Book className="text-red-600 mb-2" size={20} />,
            title: "Business eBooks",
            description: "Develop authoritative business guides with our sophisticated e-book templates. Ideal for thought leaders, consultants, and business professionals sharing insights."
        },
        {
            icon: <DollarSign className="text-emerald-600 mb-2" size={20} />,
            title: "Earning eBooks",
            description: "Design inspiring and informative e-books focused on financial strategies, side hustles, and wealth-building techniques. Tailored for aspiring entrepreneurs and financial enthusiasts."
        },
        {
            icon: <HeartPulse className="text-cyan-600 mb-2" size={20} />,
            title: "Fitness & Health eBooks",
            description: "Create engaging health and fitness guides with our specialized e-book templates. Perfect for trainers, nutritionists, and wellness professionals."
        },
        {
            icon: <Cat className="text-violet-600 mb-2" size={20} />,
            title: "Pet Care eBooks",
            description: "Develop informative and engaging pet care guides. Ideal for veterinarians, pet trainers, and animal care professionals sharing expert knowledge."
        },
        {
            icon: <CalendarRange className="text-lime-600 mb-2" size={20} />,
            title: "Event Planning eBooks",
            description: "Design comprehensive event planning guides with our specialized templates. Perfect for wedding planners, corporate event coordinators, and hospitality professionals."
        },
        {
            icon: <Utensils className="text-amber-600 mb-2" size={20} />,
            title: "Recipe eBook",
            description: "Create stunning digital cookbooks with our professional recipe e-book templates. Designed for chefs, food bloggers, and culinary enthusiasts."
        },
        {
            icon: <Camera className="text-rose-600 mb-2" size={20} />,
            title: "Product Idea Photos",
            description: "Access high-quality product photography and mockup images to showcase your designs, perfect for e-commerce, marketing, and branding purposes."
        },
        {
            icon: <ImagePlus className="text-sky-600 mb-2" size={20} />,
            title: "Stock Photo Bundles",
            description: "Discover extensive, high-resolution stock photo collections covering diverse themes, styles, and industries for creative and commercial use."
        },
        {
            icon: <FileImage className="text-fuchsia-600 mb-2" size={20} />,
            title: "Digital Backgrounds",
            description: "Enhance your design projects with our premium digital background libraries. From minimalist to complex textures, perfect for designers and content creators."
        },
        {
            icon: <Plus className="text-blue-600 mb-2" size={20} />,
            title: "Software Add-ons",
            description: "Extend software functionality with our comprehensive add-on collection. Designed to improve performance, add features, and optimize user experience."
        },
        {
            icon: <Key className="text-purple-600 mb-2" size={20} />,
            title: "License Key",
            description: "Access verified and secure software license keys for various digital tools and applications. Streamline software acquisition for professionals and businesses."
        },
        {
            icon: <PenToolIcon className="text-green-600 mb-2" size={20} />,
            title: "Tools/Kit",
            description: "Comprehensive digital resources bundling software, templates, and tools to accelerate workflow and enhance creative and professional capabilities."
        }
    ];

    const visibleCategories = showAll ? categories : categories.slice(0, 10);
    return (
        <main className="sell-now-wrapper bg-offWhite">
            <Helmet>
                <title>Sell Now | FlipBundle</title>
                <meta name="description" content="Start selling your digital products on FlipBundle. Learn how to set up your store, upload products, and offer services to a global audience." />
            </Helmet>
            <div className="position-relative bg-primary text-white py-5">
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25"></div>
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-3">
                                    <li className="breadcrumb-item d-flex align-items-center">
                                        <Home className="me-1" size={16} />
                                        <a href="/" className="text-white text-decoration-none">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        Sell Now
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="display-5 fw-bold mb-3 d-flex align-items-center">
                                <ShoppingCart className="me-3" size={45} />
                                Sell Your Products
                            </h2>
                            <p className="fs-4 opacity-75">Start selling and showcase your digital products to a global audience</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container py-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        {/* Introduction Section */}
                        <div className="alert alert-info mb-4">
                            <p className="mb-0">
                                Welcome to the marketplace! Set up your store and start selling your digital products today.
                            </p>
                        </div>
                        {/* Call to Action */}
                        <div className="bg-light rounded p-5 text-center mb-4">
                            <CreditCard className="text-primary mb-3" size={32} />
                            <h2 className="h3 mb-3">Start Selling Now</h2>
                            <p className="text-muted mb-4">
                                Ready to start selling? Set up your store, upload your products, and watch your sales grow!
                            </p>
                            <Link to='/seller/add-product' className="btn btn-primary d-inline-flex align-items-center">
                                <CreditCard className="me-2" size={16} />
                                Sell Now
                            </Link>
                        </div>
                        {/* How to Get Started */}
                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">

                                <div className="d-flex">
                                    <Users className="text-primary mt-1 me-3" size={24} />
                                    <div>
                                        <h2 className="h3 mb-4">How to Get Started</h2>
                                        <ul className="list-unstyled">
                                            <li className="mb-3 d-flex align-items-center">
                                                <span className="bg-primary rounded-circle me-2" style={{ width: '8px', height: '8px' }}></span>
                                                Register an account and complete your profile setup.
                                            </li>
                                            <li className="mb-3 d-flex align-items-center">
                                                <span className="bg-primary rounded-circle me-2" style={{ width: '8px', height: '8px' }}></span>
                                                Upload your products with high-quality images and descriptions.
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <span className="bg-primary rounded-circle me-2" style={{ width: '8px', height: '8px' }}></span>
                                                Set competitive pricing and utilize promotional tools.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <Star className="text-warning mt-1 me-3" size={24} />
                                    <div className="w-100">
                                        <h2 className="h3 mb-4">What Can Sellers Offer on Our Marketplace?</h2>
                                        <div className="row g-3">
                                            {visibleCategories.map((category, index) => (
                                                <>
                                                    <div key={index} className="col-md-6">
                                                        <div className="bg-light p-4 rounded h-100">

                                                            {category.icon}
                                                            <h3 className="h5 mb-2">{category.title}</h3>
                                                            <p className="text-muted small mb-0">{category.description}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                            {categories.length > 10 && (
                                                <div className="col-12 d-flex justify-content-center mt-4">
                                                    <button
                                                        onClick={() => setShowAll(!showAll)}
                                                        className="btn btn-primary rounded-lg d-inline-flex align-items-center gap-2"
                                                    >
                                                        {showAll ? (
                                                            <>
                                                                Show Less <ChevronUp size={20} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Show More <ChevronDown size={20} />
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card mb-4 shadow-sm border-0">
                            <div className="card-body p-4">
                                <div className="d-flex">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-lock text-purple mt-1 me-3"
                                    >
                                        <rect width={18} height={11} x={3} y={11} rx={2} ry={2} />
                                        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                                    </svg>
                                    <div>
                                        <h2 className="h3 mb-4">More Options for Sellers</h2>
                                        <p className="text-muted mb-4">
                                            Expand your offerings with these additional products:
                                        </p>
                                        <div className="d-flex justify-content-evenly gap-5">
                                            <div className="bg-light p-4 rounded">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span
                                                            className="bg-primary rounded-circle me-2"
                                                            style={{ width: 6, height: 6 }}
                                                        />
                                                        Bulk Digital Art Collections: High-resolution images and graphics for creative projects.
                                                    </li>
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span
                                                            className="bg-primary rounded-circle me-2"
                                                            style={{ width: 6, height: 6 }}
                                                        />
                                                        Bulk Software Licenses: Licensing agreements for software products and applications.
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <span
                                                            className="bg-primary rounded-circle me-2"
                                                            style={{ width: 6, height: 6 }}
                                                        />
                                                        Bulk E-books and Guides: Educational materials and guides on various topics.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="bg-light p-4 rounded">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span
                                                            className="bg-primary rounded-circle me-2"
                                                            style={{ width: 6, height: 6 }}
                                                        />
                                                        Bulk Video Templates: Pre-designed video templates for marketing and presentations.
                                                    </li>
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span
                                                            className="bg-primary rounded-circle me-2"
                                                            style={{ width: 6, height: 6 }}
                                                        />
                                                        Bulk Audio Files: Music tracks, sound effects, and voiceovers for multimedia projects.
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <span
                                                            className="bg-primary rounded-circle me-2"
                                                            style={{ width: 6, height: 6 }}
                                                        />
                                                        Offer bulk stock photos and illustrations that cater to a variety of industries and creative needs.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p className="text-muted mt-4">
                                            These options provide a glimpse into the vast opportunities available for sellers on FlipBundle.com. Start selling today and reach a global audience eager for your digital products!    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Call to Action */}
                        <div className="bg-light rounded p-5 text-center mb-4">
                            <CreditCard className="text-primary mb-3" size={32} />
                            <h2 className="h3 mb-3">Start Selling Now</h2>
                            <p className="text-muted mb-4">
                                Ready to start selling? Set up your store, upload your products, and watch your sales grow!
                            </p>
                            <Link to='/seller/add-product' className="btn btn-primary d-inline-flex align-items-center">
                                <CreditCard className="me-2" size={16} />
                                Sell Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .sell-now-wrapper {
                    margin-bottom: 60px; /* Adjust this value based on your footer height */
                }
                .card {
                    transition: transform 0.2s ease-in-out;
                }
                .card:hover {
                    transform: translateY(-5px);
                }
            `}</style>
        </main>
    );
};

export default SellNow;


