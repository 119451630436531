import React from 'react';
import { Outlet } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import SellerMobileMenu from './SellerMobileMenu';
import SellerDashboardHeader from './SellerDashboardHeader';

const MainDashBoardLayout = () => {
  return (
    <div className="d-xl-flex">
      {/* Sidebar */}
      <SidebarMenu />
      {/* Mobile Menu */}
      <SellerMobileMenu />
      {/* Mobile End */}
      {/* Right */}
      <div className="flex-grow-1 align-items-center position-relative">
        {/* Header */}
        <SellerDashboardHeader />
        {/* Main Content - Outlet for Nested Routes */}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainDashBoardLayout;
