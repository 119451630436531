import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/Shared/Loading";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import toast from "react-hot-toast";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [user] = useAuthState(auth);
    const [categories, SetCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/blogs?authorEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => setBlogs(info.reverse()));
    }, [user?.email]);

    // Fetch categories
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/categories`)
            .then((res) => res.json())
            .then((info) => SetCategories(info.reverse()));
    }, [user]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentService = blogs.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDeleteBlog = async (blogId) => {
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/blog/${blogId}`, {
                method: "DELETE",
            });
            const result = await response.json();
            if (response.ok) {
                toast.success("Blog post deleted successfully!");
                // Refresh the blog list
                fetch(`https://server.enjoywiki.com/flip-server/blogs?authorEmail=${user?.email}`)
                    .then((res) => res.json())
                    .then((info) => setBlogs(info.reverse()));
            } else {
                throw new Error(result.message || "Failed to delete blog post");
            }
        } catch (error) {
            console.error("Error deleting blog post:", error);
            toast.error(error.message || "Failed to delete blog post");
        }
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title> Blog | FlipBundle - Manage Your Digital Product Listings</title>
                    <meta
                        name="description"
                        content="View and manage your digital product listings on FlipBundle. Easily update, promote, and track your products for increased sales."
                    />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Blog Post List</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Seller Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">Blog Post</li>
                        </ul>
                    </div>
                    <Link
                        to="/seller/blog/add-post"
                        className="d-flex align-items-center gap-2 w-btn-secondary-lg"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Add Post
                    </Link>
                </div>
                {/* Content */}
                <div className="gig-info-card">
                    <div className="gig-info-body ">
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="ps-4">Blog Title</th>
                                            <th scope="col">Category</th>
                                            <th scope="col" className="text-center">Edit</th>
                                            <th scope="col" className="text-center">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {blogs === null ? (
                                            <tr>
                                                <td colSpan="5" className="text-center p-4">
                                                    <LoadingAnimations type="pulse" color="#2563eb" size="md" />
                                                </td>
                                            </tr>
                                        ) : currentService.length === 0 ? (
                                            <tr>
                                                <td colSpan="5" className="text-center p-4">
                                                    No Post Found.
                                                </td>
                                            </tr>
                                        ) : (
                                            currentService.map((blog) => (
                                                <tr key={blog._id}>
                                                    <td>
                                                        <div className="d-flex gap-3 align-items-center project-name">
                                                            <div className="order-img">
                                                                {blog.featuredImage && (
                                                                    <img
                                                                        src={blog.featuredImage}
                                                                        alt={blog.blogTitle}
                                                                        style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "8px" }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div>
                                                                <a
                                                                    href={`/blog/${blog.slug}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="project-link"
                                                                >
                                                                    {blog.blogTitle}
                                                                </a>
                                                                <ul className="d-flex gap-1 order-category mt-1">
                                                                    <li className="text-dark-200">{blog.category}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="text-dark-200">
                                                        <span className="category-badge">{blog.category}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`/seller/edit-blog/${blog._id}`} className="btn btn-primary btn-sm">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="me-1"
                                                            >
                                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                            </svg>
                                                            Edit
                                                        </Link>
                                                    </td>
                                                    <td className="text-center">
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => handleDeleteBlog(blog._id)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="me-1"
                                                            >
                                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                            </svg>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Pagination */}
                        {blogs.length > itemsPerPage && (
                            <div className="pagination-container mt-4 d-flex justify-content-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(blogs.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default BlogList;