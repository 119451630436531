import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import auth from "../../../../firebase.init";
import LoadingSpinner from "../../../../components/Shared/Loading";
import { Helmet } from "react-helmet";

const BuyerAllOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState("all");
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState("all");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("all");
    const [loading, setLoading] = useState(true);
    const [user] = useAuthState(auth);
    const [activeTab, setActiveTab] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [reviews, setReviews] = useState([]);

    // Function to fetch orders from server
    const fetchOrders = () => {
        fetch(`https://server.enjoywiki.com/flip-server/orders?userEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => {
                setOrders(info.reverse());
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching orders:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchOrders();
    }, [user]);

    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/product-reviews`)
            .then((res) => res.json())
            .then((info) => setReviews(info));
    }, []);


    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedDateFilter, customStartDate, customEndDate, selectedOrderStatus, selectedPaymentStatus, activeTab]);

    // Function to filter orders based on all criteria
    const filterOrders = () => {
        const currentDate = new Date();

        return orders.filter((order) => {
            const orderDate = new Date(order.orderDate);
            const isOrderWithinDateFilter =
                selectedDateFilter === "all" ||
                (selectedDateFilter === "thisMonth" &&
                    orderDate.getMonth() === currentDate.getMonth()) ||
                (selectedDateFilter === "last7Days" &&
                    orderDate >= new Date(currentDate.setDate(currentDate.getDate() - 7))) ||
                (selectedDateFilter === "previousMonth" &&
                    orderDate >= new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1) &&
                    orderDate <= new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)) ||
                (selectedDateFilter === "custom" &&
                    customStartDate &&
                    customEndDate &&
                    orderDate >= new Date(customStartDate) &&
                    orderDate <= new Date(customEndDate));

            const isOrderMatchingStatus =
                selectedOrderStatus === "all" ||
                order.orderStatus === selectedOrderStatus;

            const isOrderMatchingPaymentStatus =
                selectedPaymentStatus === "all" ||
                order.paymentStatus === selectedPaymentStatus;

            // Tab filtering
            if (activeTab === 'active') return order.paymentStatus === 'Paid';
            if (activeTab === 'pending') return order.paymentStatus === 'pending';
            if (activeTab === 'completed') return order.paymentStatus === 'Completed';
            if (activeTab === 'close') return order.paymentStatus === 'Cancelled';

            return isOrderWithinDateFilter && isOrderMatchingStatus && isOrderMatchingPaymentStatus;
        });
    };

    const filteredOrders = filterOrders();

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle tab changes
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
    };

    // Handle date filter changes
    const handleDateFilterChange = (filter) => {
        setSelectedDateFilter(filter);
        setCurrentPage(1);
    };

    // Handle custom date changes
    const handleCustomDateChange = (startDate, endDate) => {
        setCustomStartDate(startDate);
        setCustomEndDate(endDate);
        setCurrentPage(1);
    };

    // Handle status filter changes
    const handleStatusFilterChange = (status) => {
        setSelectedOrderStatus(status);
        setCurrentPage(1); // Reset to first page when changing status filter
    };

    // Handle payment status filter changes
    const handlePaymentStatusFilterChange = (status) => {
        setSelectedPaymentStatus(status);
        setCurrentPage(1); // Reset to first page when changing payment status filter
    };

    // Note handling
    const handleEditNote = (event, id, index) => {
        event.preventDefault();
        const buyerAddedNote = event.target.buyerAddedNote.value;
        const noteAddedByBuyer = event.target.noteAddedByBuyer.value;

        const edit = {
            noteAddedByBuyer,
            buyerAddedNote
        };

        const url = `https://server.enjoywiki.com/flip-server/update-note-buyer/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(edit),
        })
            .then((res) => res.json())
            .then((result) => {
                toast.success('Note Updated Successfully!');
                event.target.reset();
                const modal = document.querySelector(`#exampleModal${index}`);
                if (modal) {
                    modal.classList.remove('show');
                }
                // Fetch the updated data after successful edit
                fetch(`https://server.enjoywiki.com/flip-server/orders?userEmail=${user?.email}`)
                    .then((res) => res.json())
                    .then((info) => {
                        setOrders(info.reverse());
                    });
            })
    };

    if (loading) return <LoadingSpinner />;
    const handleAccessClick = (order) => {
        setSelectedOrder(order);
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4">
                <Helmet>
                    <title>Your Orders | FlipBundle - Manage and Track Your Purchases</title>
                    <meta name="description" content="Access your complete order history on FlipBundle. Track the status of your purchases and easily find your downloaded digital products." />
                </Helmet>

                <div className="d-flex flex-column flex-md-row gap-4 align-items-md-center justify-content-between">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">My Orders</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Buyer Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">My Orders</li>
                        </ul>
                    </div>
                </div>



                {/* Tabs */}
                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All({orders.length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'active' ? 'active' : ''}`}
                                onClick={() => setActiveTab('active')}
                            >
                                Paid({orders.filter(order => order.paymentStatus === 'Paid').length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'pending' ? 'active' : ''}`}
                                onClick={() => setActiveTab('pending')}
                            >
                                Pending({orders.filter(order => order.paymentStatus === 'pending').length})
                            </button>


                            <button
                                className={`dashboard-tab-btn ${activeTab === 'close' ? 'active' : ''}`}
                                onClick={() => setActiveTab('close')}
                            >
                                Cancelled({orders.filter(order => order.paymentStatus === 'Cancelled').length})
                            </button>
                        </div>
                    </nav>

                    {/* Table Content */}
                    <div className="tab-content mt-4">
                        <div className="overflow-x-auto">
                            <table className="w-100 dashboard-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="ps-4">Item Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Status</th>

                                        <th scope="col">Review & Rating</th>

                                        <th scope="col">Order Date</th>
                                        <th scope="col">Notes</th>
                                        <th scope="col">View</th>
                                        <th scope="col">Access Now</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentOrders.map((order, index) => (
                                        <tr key={order._id}>
                                            <td>
                                                <div className="d-flex gap-3 align-items-center project-name">
                                                    <div className="order-img">
                                                        <img src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"} alt="" />
                                                    </div>
                                                    <div>
                                                        <Link to={`/${order.productSlug}/${order.productId}`} className="text-dark-200 project-link">
                                                            {order.packageName}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-dark-200">${order.packagePrice}</td>
                                            <td>
                                                <span className={`status-badge ${order.paymentStatus.toLowerCase()}`}>
                                                    {order.paymentStatus[0].toUpperCase() + order.paymentStatus.slice(1)}
                                                </span>
                                            </td>

                                            <td>
                                                {
                                                    order.paymentStatus === 'Paid' ?
                                                        <h6 className="mb-1 font-size-13">
                                                            {
                                                                reviews.filter(review => review.reviewerEmail === user?.email && review.orderId === order._id).length === 0 &&
                                                                <Link to={`/review/${order._id}`} className="text-dark mb-1 font-size-14">
                                                                    Write a review
                                                                </Link>
                                                            }
                                                            {
                                                                reviews.filter(review => review.orderId === order._id && review.reviewerEmail === user?.email).length === 1 &&
                                                                <Link className="text-dark mb-1 font-size-14">
                                                                    Already Reviewed
                                                                </Link>
                                                            }
                                                        </h6>
                                                        :
                                                        <p>First Complete Payment</p>
                                                }
                                            </td>
                                            <td className="text-dark-200">{order.orderDate}</td>
                                            <td>
                                                {order.paymentStatus === 'Paid' && (
                                                    <button
                                                        className="dashboard-action-btn"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#noteModal${order._id}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48" fill="none">
                                                            <circle cx={24} cy={24} r={24} fill="#EDEBE7" />
                                                            <path d="M18 20L21.7812 22.5208C23.1248 23.4165 24.8752 23.4165 26.2188 22.5208L30 20M18 33H30C32.2091 33 34 31.2091 34 29V19C34 16.7909 32.2091 15 30 15H18C15.7909 15 14 16.7909 14 19V29C14 31.2091 15.7909 33 18 33Z"
                                                                stroke="#5B5B5B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                <div>
                                                    {(order.paymentStatus === "Cancelled" || order.paymentStatus === "pending") && (
                                                        <div className="isotope-filters item-isotope-btn">
                                                            <button className="button active btn-sm">
                                                                <Link className="text-lime-300 fs-6" to={`/pay-now/${order._id}`}>
                                                                    Pay Now
                                                                </Link>
                                                            </button>
                                                        </div>
                                                    )}

                                                    {order.paymentStatus === "Paid" &&
                                                        <button
                                                            className="dashboard-action-btn"
                                                            data-bs-toggle="modal" data-bs-target={`#orderDetails${order._id}`}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48" fill="none">
                                                                <circle cx={24} cy={24} r={24} fill="#EDEBE7" />
                                                                <path d="M34.3187 21.6619C35.6716 23.0854 35.6716 25.248 34.3187 26.6714C32.0369 29.0721 28.1181 32.3333 23.6667 32.3333C19.2152 32.3333 15.2964 29.0721 13.0147 26.6714C11.6618 25.248 11.6618 23.0854 13.0147 21.6619C15.2964 19.2612 19.2152 16 23.6667 16C28.1181 16 32.0369 19.2612 34.3187 21.6619Z"
                                                                    stroke="#5B5B5B" strokeWidth="1.5" />
                                                                <circle cx="23.667" cy="24.167" r="3.5" stroke="#5B5B5B" strokeWidth="1.5" />
                                                            </svg>
                                                        </button>
                                                    }

                                                </div>
                                            </td>
                                            <td>
                                                {order.paymentStatus === "Paid" ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#accessModal"
                                                        onClick={() => handleAccessClick(order)}
                                                    >
                                                        Access Now
                                                    </button>
                                                ) : (
                                                    <>
                                                        -</>
                                                )}
                                            </td>

                                            {/* Note Modal */}
                                            <div className="modal fade" id={`noteModal${order._id}`} tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Add or Edit Note</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form onSubmit={(event) => handleEditNote(event, order._id, index)}>
                                                                <input
                                                                    type="text"
                                                                    hidden
                                                                    name="noteAddedByBuyer"
                                                                    value={user?.email}
                                                                    readOnly
                                                                />
                                                                <div className="mb-3">
                                                                    <label className="form-label">Note Description</label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        name="buyerAddedNote"
                                                                        rows={5}
                                                                        defaultValue={order.buyerAddedNote}
                                                                    />
                                                                </div>
                                                                <div className="text-center">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save Note
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Order Details Modal */}
                                            <div className="modal fade" id={`orderDetails${order._id}`} tabIndex="-1" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Order Details</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Order ID</td>
                                                                        <td>{order.orderId}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Date</td>
                                                                        <td>{order.orderDate}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Product Name</td>
                                                                        <td>{order.packageName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Price</td>
                                                                        <td>${order.packagePrice}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Payment Status</td>
                                                                        <td>{order.paymentStatus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Customer Name</td>
                                                                        <td>{order.customerName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Customer Note</td>
                                                                        <td>{order.customerNote || 'No note provided'}</td>
                                                                    </tr>
                                                                    {order.paymentStatus === "Paid" && (
                                                                        <>
                                                                            {order.accessLink && (
                                                                                <tr>
                                                                                    <td>Access Link</td>
                                                                                    <td>{order.accessLink}</td>
                                                                                </tr>
                                                                            )}
                                                                            {order.guideLine && (
                                                                                <tr>
                                                                                    <td>Guide Line</td>
                                                                                    <td>{order.guideLine}</td>
                                                                                </tr>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Empty State */}
                        {currentOrders.length === 0 && (
                            <div className="row justify-content-center">
                                <div className="col-7">
                                    <div className="bg-white p-5 rounded-3 text-center">
                                        <img
                                            src="https://workzone.mamunuiux.com/uploads/website-images/empty-2024-05-17-11-50-01-3653.png"
                                            className="img-fluid mb-4"
                                            alt="Empty orders"
                                        />
                                        <h3 className="text-24 fw-bold text-dark-300 mb-2">
                                            No Orders Found
                                        </h3>
                                        <p className="fs-6 text-dark-200">
                                            No orders match your current filter criteria
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Pagination */}
                        {filteredOrders.length > itemsPerPage && (
                            <div className="row justify-content-end mt-4">
                                <div className="col-auto">
                                    <nav>
                                        <ul className="custom-pagination pagination">
                                            <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button
                                                    className="custom-page-link page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                            </li>
                                            {[...Array(totalPages)].map((_, i) => (
                                                <li
                                                    key={i + 1}
                                                    className={`custom-page-item page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                                >
                                                    <button
                                                        className="custom-page-link page-link"
                                                        onClick={() => handlePageChange(i + 1)}
                                                    >
                                                        {i + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button
                                                    className="custom-page-link page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Bootstrap Modal */}
            <div className="modal fade" id="accessModal" tabIndex="-1" aria-labelledby="accessModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="accessModalLabel">Access Package Content</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {selectedOrder && (
                                <div className="p-3">
                                    <div className="mb-4">
                                        <h5 className="fw-bold">Package Details</h5>
                                        <p className="mb-2"><strong>Package Name:</strong> {selectedOrder.packageName}</p>
                                        <p className="mb-2"><strong>Order ID:</strong> {selectedOrder.orderId}</p>
                                        <p className="mb-2"><strong>Purchase Date:</strong> {selectedOrder.orderDate}</p>
                                    </div>

                                    <div className="mb-4">
                                        <h5 className="fw-bold">Access Information</h5>
                                        <p>Your purchased content is ready to access. Click the button below to view your package content.</p>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <a
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            href={selectedOrder.accessLink.startsWith('http') ? selectedOrder.accessLink : `https://${selectedOrder.accessLink}`}
                                            className="btn btn-primary px-4"
                                        >
                                            Access Now
                                        </a>

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
};

export default BuyerAllOrders;