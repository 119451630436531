import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const NavigationHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pendingProductId, setPendingProductId] = useState(null);

  // Save history to local storage
  useEffect(() => {
    const saveHistory = () => {
      const history = JSON.parse(localStorage.getItem('browserHistory') || '[]');

      // Add current path to history if it's different from the last entry
      if (history[history.length - 1] !== location.pathname) {
        history.push(location.pathname);

        // Keep only last 20 entries
        if (history.length > 20) {
          history.shift();
        }

        localStorage.setItem('browserHistory', JSON.stringify(history));
      }
    };

    saveHistory();
  }, [location]);

  // Handle post-profile update navigation
  useEffect(() => {
    const handlePostProfileUpdate = () => {
      // Check if we have a pending product purchase
      const storedProductId = localStorage.getItem('pendingProductPurchase');

      if (storedProductId) {
        // Clear the stored product ID
        localStorage.removeItem('pendingProductPurchase');

        // Navigate to the buy page
        navigate(`/buy/${storedProductId}`);
      }
    };

    handlePostProfileUpdate();
  }, [navigate]);

  // Intercept buy now clicks
  const handleBuyNowClick = (productId) => {
    // Store the product ID
    localStorage.setItem('pendingProductPurchase', productId);

    // Check if profile needs update
    const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');

    if (!userProfile.isComplete) {
      // Navigate to profile update
      navigate('/update-profile');
      return;
    }

    // If profile is complete, navigate directly to buy page
    navigate(`/buy/${productId}`);
  };

  return null; // This is a utility component, no UI needed
};

export default NavigationHandler;