// import React from 'react';

// const PaginationGlobal = ({ 
//   currentPage, 
//   totalPages, 
//   onPageChange 
// }) => {
//   const getPageNumbers = () => {
//     const pageNumbers = [];
//     const maxPagesToShow = 5;
//     let startPage = Math.max(1, currentPage - 2);
//     let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    
//     if (endPage - startPage < maxPagesToShow - 1) {
//       startPage = Math.max(1, endPage - maxPagesToShow + 1);
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       pageNumbers.push(i);
//     }
//     return pageNumbers;
//   };

//   return (
//     <div className="row justify-content-center mt-5">
//       <div className="col-auto">
//         <nav aria-label="Page navigation">
//           <ul className="custom-pagination pagination">
//             <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//               <button
//                 className="custom-page-link page-link"
//                 onClick={() => onPageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//               >
//                 Previous
//               </button>
//             </li>
            
//             {getPageNumbers().map(number => (
//               <li 
//                 key={number}
//                 className={`custom-page-item page-item ${currentPage === number ? 'active' : ''}`}
//               >
//                 <button
//                   className="custom-page-link page-link"
//                   onClick={() => onPageChange(number)}
//                 >
//                   {number}
//                 </button>
//               </li>
//             ))}

//             <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//               <button
//                 className="custom-page-link page-link"
//                 onClick={() => onPageChange(currentPage + 1)}
//                 disabled={currentPage === totalPages}
//               >
//                 Next
//               </button>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </div>
//   );
// };

// export default PaginationGlobal;
import React from 'react';

const PaginationGlobal = ({ 
  currentPage, 
  totalPages, 
  onPageChange 
}) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 4;
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    
    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  // Add handlePageChange function
  const handlePageChange = (page) => {
    onPageChange(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="row justify-content-center mt-5">
      <div className="col-auto">
        <nav aria-label="Page navigation">
          <ul className="custom-pagination pagination">
            <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="custom-page-link page-link"
                onClick={() => handlePageChange(currentPage - 1)}  
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            
            {getPageNumbers().map(number => (
              <li 
                key={number}
                className={`custom-page-item page-item ${currentPage === number ? 'active' : ''}`}
              >
                <button
                  className="custom-page-link page-link"
                  onClick={() => handlePageChange(number)} 
                >
                  {number}
                </button>
              </li>
            ))}

            <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button
                className="custom-page-link page-link"
                onClick={() => handlePageChange(currentPage + 1)}  
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default PaginationGlobal;